import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface UserState {
  user: any;
  username: string;
  displayName: string;
  role: string;
  token: string;
  menu_mapping: Array<object>;
  isAuthenticated: boolean;
  setUser: (user: UserState["user"]) => void;
  setUsername: (username: UserState["username"]) => void;
  setDisplayName: (displayName: UserState["displayName"]) => void;
  setRole: (role: UserState["role"]) => void;
  setToken: (token: UserState["token"]) => void;
  setMenuMapping: (menu_mapping: UserState["menu_mapping"]) => void;
  setIsAuthenticated: (isAuthenticated: UserState["isAuthenticated"]) => void;
  reset: () => void; // Add reset function to interface
}

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      user: {},
      username: "",
      displayName: "",
      role: "",
      token: "",
      menu_mapping: [],
      isAuthenticated: false,
      setUser: (user: UserState["user"]) => set(() => ({ user })),
      setUsername: (username: UserState["username"]) =>
        set(() => ({ username })),
      setDisplayName: (displayName: UserState["displayName"]) =>
        set(() => ({ displayName })),
      setRole: (role: UserState["role"]) => set(() => ({ role })),
      setToken: (token: UserState["token"]) => set(() => ({ token })),
      setMenuMapping: (menu_mapping: UserState["menu_mapping"]) =>
        set(() => ({ menu_mapping })),
      setIsAuthenticated: (isAuthenticated: UserState["isAuthenticated"]) =>
        set(() => ({ isAuthenticated })),
      reset: () =>
        set(() => ({
          user: {},
          username: "",
          displayName: "",
          role: "",
          token: "",
          menu_mapping: [],
          isAuthenticated: false,
        })),
    }),
    {
      name: "user-state", // Name for the persisted storage key
      storage: createJSONStorage(() => localStorage),
    }
  )
);
