import { Box, Stack } from "@mui/material";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CustomButton from "../CustomButton";
import { useEffect, useState } from "react";
import { useLoadingStore } from "src/store/loadingStore";
import { formatPhoneNumber } from "src/utils/utils";
import useThemeStore from "src/store/themeStore";




  
export default function WaCLientOrder({ information }: any) {
    const { showNotification } = useLoadingStore();

const { theme } = useThemeStore();

const [message, setMessage] = useState<any>({});
const handleCopyClipboard = () => {
    navigator.clipboard
      .writeText(decodeURIComponent(message?.wa_message?.replace(/\+/g, " ")))
      .then(() => {
        showNotification("Message copied to clipboard", "success");
      });
  };


  useEffect(() => {
    setMessage("")
  }, []);

  return (
    <Box
      component={"form"}
      sx={{ width: { xs: "70%", md: "60%", lg: "50%" } }}
    >
    <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
    <CustomText variant="body1">Send a Message</CustomText>
          <Stack
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
            }}
            mt={2}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <CustomButton
              variant="contained"
              onClick={() =>
                window.open(
                  `https://wa.me/${formatPhoneNumber(
                    message?.no_telp || ""
                  )}?text=${message?.wa_message}`,
                  "_blank"
                )
              }
            >
              WA.me Link
            </CustomButton>
            <CustomText variant="body1">or</CustomText>
            <CustomButton
              variant="contained"
              onClick={() => handleCopyClipboard()}
            >
              Copy to clipboard
            </CustomButton>
          </Stack>
        </CustomPaper>
        </Box>

  );
}
