import { Box, Stack } from "@mui/material";
import AtomicSpinner from "atomic-spinner";
import CustomText from "../../CustomText";

interface Props {
  message?: string;
}

export default function LoadingAtom({ message = "Loading ..." }: Props) {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
      // sx={{
      //   position: "absolute",
      //   top: 0,
      //   left: 0,
      //   right: 0,
      //   bottom: 0,
      //   display: "flex",
      //   m: 2,
      //   mr: 0,
      //   justifyContent: "center",
      //   alignItems: "center",
      //   backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   zIndex: 9999,
      // }}
    >
      <Stack>
        <AtomicSpinner />
        {message && (
          <CustomText variant="body1" sx={{ mt: 1 }} textAlign={"center"}>
            {message}
          </CustomText>
        )}
      </Stack>
    </Box>
  );
}
