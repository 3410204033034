import { Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { DecimalFormatter } from "src/utils/formatter";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomButton from "src/components/main/CustomButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import IosShareIcon from "@mui/icons-material/IosShare";
import useThemeStore from "src/store/themeStore";
import { FINANCE_REPORT_COLUMNS } from "src/constants/finance";
import { useEffect, useState } from "react";
import { downloadReportFinance, generateReportFinance, getFinanceList } from "src/api/finance";
import { useFilterStore } from "src/store/filterStore";
import useMount from "src/hooks/useMount";

export default function FinanceReport() {
  const { theme } = useThemeStore();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const [data, setData] = useState<any[]>([]);
  const onClickDetail = (id: string) => {
    navigate(`/finance/monthly-recap/${id}`);
  };

  const handleFilterChange = (name: string, value: any) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const downloadReportFinanceFunc = async () => {
    await downloadReportFinance();
  };

  const fetchData = async () => {
    const res = await getFinanceList();
    setData(processedData(res?.data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const processedData = (data: FinanceMonthlyReportList[]) => {
    return data?.map((item) => {
      return {
        ...item,
        id: item?.year_month,
        amountIdr: `Rp ${DecimalFormatter(item?.amount_idr?.toString())}`,
        amountUsd: `$ ${DecimalFormatter(item?.amount_usd?.toString())}`,
        totalPayment: `Rp ${DecimalFormatter(item?.total_payment?.toString())}`,
        incentiveExpense: `Rp ${DecimalFormatter(item?.incentive_expense?.toString())}`,

        revenueCrypto: `$ ${DecimalFormatter(
          item?.revenue_crypto?.toString()
        )}`,
        totalItemExpense: `Rp ${DecimalFormatter(
          item?.total_item_expense?.toString()
        )}`,
        expenseSalary: `Rp ${DecimalFormatter(
          item?.expense_salary?.toString()
        )}`,
        totalRevenue: `Rp ${DecimalFormatter(item?.revenue?.toString())}`,
      };
    });
  };

  return (
    <Box>
      <PageTitle>Monthly Finance Report</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Finance Report List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <CustomButton onClick={downloadReportFinanceFunc} variant="contained" startIcon={<IosShareIcon />}>
              Export to Excel
            </CustomButton>
          </Box>
        </Box>
        <CustomTable
          columns={FINANCE_REPORT_COLUMNS}
          pagination={false}
          data={data}
          stickyFirst={true}
          onClickDetail={onClickDetail}
          filter={filter}
          onChangeFilter={handleFilterChange}
        />
      </Card>
    </Box>
  );
}
