import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Customer from "./components/Customer";
import ShippingAddress from "./components/ShippingAddress";
import Order from "./components/Order";
import Payment from "./components/Payment";
import ItemList from "./components/ItemList";
import CustomButton from "src/components/main/CustomButton";
import { useCallback, useState } from "react";
import {
  designType,
  getCurrencySymbol,
  serviceType,
} from "src/utils/paramData";
import { useOrderStore } from "src/store/orderStore";
import { useDebouncedCallback } from "use-debounce";
import { DecimalFormatter, parseAmount } from "src/utils/formatter";
import { CreateOrder } from "src/api/order";
import NotificationAlert from "src/components/main/Alert";
import PageTitle from "src/components/main/CustomText/PageTitle";
import ModalConfirm from "src/components/main/ModalConfirm";
import { useLoadingStore } from "src/store/loadingStore";
import { isBefore, isPast, startOfDay } from "date-fns";

function OrderCreate() {
  const navigate = useNavigate();
  const { showNotification } = useLoadingStore();
  const { customer, order, setOrder, itemList, setItemList, shipment } =
    useOrderStore();

  const [paymentData, setPaymentData] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleItemData = (e: any, index: number) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "from_client" || name === "leather_from_client") {
      newValue = newValue === "client" ? true : false;
    }

    if (name === "starting_price") {
      newValue = parseAmount(value);
    }

    let dp_cost = 0;
    if (name === "cost") {
      newValue = parseAmount(value);
      itemList[index]["design_type"] === designType[0].value
        ? (dp_cost = newValue * 0.7)
        : (dp_cost = 500000);
    }

    if (name === "design_type") {
      newValue === designType[0].value
        ? (dp_cost = itemList[index]["cost"] * 0.7)
        : (dp_cost = 500000);
    }

    let is_leather = false;
    if (name === "service_type") {
      newValue !== serviceType[0].value ? (is_leather = true) : null;
    }

    const newData = [...itemList];
    newData[index] = {
      ...newData[index],
      [name]: newValue,
      ...(name === "cost" || name === "design_type"
        ? { item_dp_cost: dp_cost }
        : {}),
      ...(name === "service_type" ? { is_leather } : {}),
    };

    setItemList(newData);

    (name === "cost" || name === "design_type") && paymentDpDebounced();
  };

  const handlePaymentDP = () => {
    const dp = itemList
      ?.map((item: { item_dp_cost: number }) => item?.item_dp_cost || 0)
      .reduce((a: number, b: number) => a + b, 0);

    const orderAmount = itemList
      ?.map((item: { cost: number }) => item?.cost || 0)
      .reduce((a: number, b: number) => a + b, 0);

    setOrder({
      ...order,
      ["payment_nominal"]: dp.toString(),
      ["amount"]: orderAmount.toString(),
    });
  };

  const paymentDpDebounced = useDebouncedCallback(handlePaymentDP, 1000);

  const handleOrderData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setOrder({
      ...order,
      [name]: value,
    });
  };

  const fileReader = useCallback((acceptedFiles: any[]) => {
    const fileDataArray: any[] = [];

    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr: any = reader.result;
        // Extract MIME type and base64 data
        const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
        if (matches) {
          const mimeType = matches[1];
          const base64Data = matches[2];

          fileDataArray.push({
            bukti_payment_base64: base64Data,
            tipe_file: mimeType.split("/")[1],
          });

          if (fileDataArray.length === acceptedFiles.length) {
            setPaymentData((prevData: any) => ({
              ...prevData,
              attachments: [...(prevData.attachments || []), ...fileDataArray],
            }));
          }
        } else {
          console.error("Invalid file data");
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handlePaymentFileUpload = (acceptedFiles: any[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setPaymentData((prevData: any) => {
        const updatedAttachments = [...prevData.attachments];
        updatedAttachments.splice(index, 1);
        return {
          ...prevData,
          attachments: updatedAttachments,
        };
      });
    },
    [setPaymentData]
  );

  const handleAddItem = () => {
    setItemList([
      ...itemList,
      {
        design_type: designType.length > 0 ? designType[0].value : "",
        service_type: serviceType.length > 0 ? serviceType[0].value : "",
        from_client: false,
        leather_from_client: false,
        supplier_deadline: new Date().toISOString().slice(0, 10),
      },
    ]);
  };

  const handleRemoveItem = (index: number) => {
    const updatedData = [...itemList];
    updatedData.splice(index, 1);
    setItemList(updatedData);
  };

  const handleSubmit = async () => {
    const data = {
      order: order,
      payment: paymentData,
      item: itemList,
      customer: customer,
      shipment: shipment,
    };
    console.log("SUBMIT ORDER", data);


    const itemDpCost = data?.item
      ?.map((item: { item_dp_cost: number }) => item?.item_dp_cost || 0)
      .reduce((a: number, b: number) => a + b, 0);

    try {
      if (data?.order?.payment_nominal !== itemDpCost?.toString()) {
        showNotification(
          `Payment nominal is not equal to item dp cost (${getCurrencySymbol(
            data?.order?.payment_curr
          )} ${DecimalFormatter(itemDpCost?.toString())})`,
          "error"
        );
        return;
      }

      const response = await CreateOrder(data);
      if (response?.status === 200) {
        navigate("/order");
      }
    } catch (error: any) {
      console.error("Error create order:", error);
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

     // Check if order deadline valid
     if (startOfDay(new Date(order?.deadline || 0)) < startOfDay(new Date())) {
      showNotification("Invalid Date Order Deadline", "error");
      return;
    }

    // Check if payment attachments exist
    if (!paymentData?.attachments) {
      showNotification("Please upload proof of payment", "error");
      return;
    }

    setOpenModal(true);
  };

  return (
    <Box>
      <PageTitle>Create Order</PageTitle>
      <Box component={"form"} onSubmit={handleSubmitForm}>
        <Customer />
        <ShippingAddress />
        {/* <Order data={order} onChange={handleOrderData} /> */}
        <ItemList
          data={itemList}
          addItem={handleAddItem}
          onChange={(e, index) => handleItemData(e, index)}
          removeItem={(index) => handleRemoveItem(index)}
        />
        <Payment
          data={order}
          payment={paymentData}
          onChange={handleOrderData}
          onUpload={(file) => handlePaymentFileUpload(file)}
          onDelete={(index) => handlePaymentFileDelete(index)}
        />
        <Box
          mt={4}
          display={"flex"}
          width={"100%"}
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "flex-end",
            alignItems: {
              xs: "flex-start",
              md: "flex-end",
            },
            gap: {
              xs: 1,
              md: 2,
              lg: 3,
            },
          }}
        >
          <CustomButton
            onClick={() => navigate("/order")}
            variant="outlined"
            customType="cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton variant="contained" type="submit"
          >
            Create Order
          </CustomButton>
        </Box>
      </Box>
      <ModalConfirm
        text="Are you sure to create this order?"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}

export default OrderCreate;
