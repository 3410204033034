import { Box, Stack, Grid, InputAdornment } from "@mui/material";
import InputDate from "src/components/main/Datepicker";
import CustomText from "../CustomText";
import CustomInput from "../CustomInput";

interface Props {
  disabled?: boolean;
  value?: customer;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface customer {
  [key: string]: any;
}

function trimZeros(input: string): string {
  // Trim leading zeros
  let trimmedInput = input.replace(/^0+/, "");

  // If the number is all zeros (e.g., "0000"), return "0"
  if (trimmedInput === "") {
    return "0";
  }

  // If there's a decimal point, trim trailing zeros after the decimal point
  if (trimmedInput.includes(".")) {
    trimmedInput = trimmedInput.replace(/(\.\d*?[1-9])0+$/, "$1");
    // Remove the decimal point if it is the last character (e.g., "123." becomes "123")
    trimmedInput = trimmedInput.replace(/\.$/, "");
  }

  return trimmedInput;
}

export default function CustomerRow({ disabled, value, onChange }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleChangeTrim = (event: any) => {
    const trimmedValue = event.target.value.trim();
    event.target.value = trimmedValue; // Update value with trimmed input
    if (onChange) {
      onChange(event);
    }
  };

  function validateEmail(email: string): boolean {
    if (email?.length < 1) {
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const out = emailRegex.test(email);
    console.log("Emaiil", out);

    return !out;
  }

  const handleChangeTrimNumeric = (event: any) => {
    const trimmedValue = trimZeros(event.target.value);
    event.target.value = trimmedValue; // Update value with trimmed input
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <Box width={"100%"}>
      <Grid container columnSpacing={4}>
        <Grid item sm={4}>
          <Stack mb={2}>
            <CustomText variant="body1">Name</CustomText>
            <CustomInput
              onChange={handleChange}
              onBlur={handleChangeTrim}
              disabled={disabled}
              size="small"
              id="name"
              name="name"
              value={value?.name || ""}
              required
              error={value?.name?.toString() !== "" && value?.name?.length < 3}
              helperText={
                value?.name?.toString() !== "" && value?.name?.length < 3
                  ? "Name Must Be At Least 3 Characters"
                  : ""
              }
            />
          </Stack>
          <Stack mb={2}>
            <CustomText variant="body1">Birthdate</CustomText>
            <InputDate
              onChange={handleChange}
              disabled={disabled}
              id="birth_date"
              name="birth_date"
              value={value?.birth_date || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack mb={2}>
            <CustomText variant="body1">Phone / Whatsapp</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              required
              size="small"
              id="phone_number"
              name="phone_number"
              onBlur={handleChangeTrimNumeric}
              error={
                value?.phone_number?.toString() !== "" &&
                value?.phone_number?.length < 9
              }
              helperText={
                value?.phone_number?.toString() !== "" &&
                value?.phone_number?.length < 9
                  ? "Phone Number Must Be At Least 9 Characters"
                  : ""
              }
              value={value?.phone_number || ""}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">+</InputAdornment>
              //   ),
              // }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Stack>
          <Stack mb={2}>
            <CustomText variant="body1">Tiktok (Optional)</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="tiktok"
              name="tiktok"
              value={value?.tiktok || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack mb={2}>
            <CustomText variant="body1">Email</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              required
              size="small"
              id="email"
              name="email"
              // error={validateEmail(value?.email)}
              // helperText={
              //   validateEmail(value?.email) ? "Invalid Email Format" : ""
              // }
              value={value?.email || ""}
            />
          </Stack>
          <Stack mb={2}>
            <CustomText variant="body1">Instagram (Optional)</CustomText>
            <CustomInput
              onChange={handleChange}
              disabled={disabled}
              size="small"
              id="instagram"
              name="instagram"
              value={value?.instagram || ""}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
