import { AccordionDetails, AccordionSummary, Box, Divider, Stack, Tooltip } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import CustomButton from "src/components/main/CustomButton";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import PageTitle from "src/components/main/CustomText/PageTitle";
import Detail from "./Detail";
import colors from "src/themes/colors";
import NotificationAlert from "src/components/main/Alert";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { GetTaskDetail, UpdateTaskDataGan } from "src/api/task";
import ItemForm from "src/components/main/Item/Detail";
import CustomAccordion from "src/components/main/CustomAccordion";
import useThemeStore from "src/store/themeStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";

interface Props {
    valuesTask: any;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isGrouping?:boolean;
  }

  export default function TaskGroupingDetail() {

  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const fetchTaskDetail = async () => {
    try {
      ShowLoading({});
      const response = await GetTaskDetail(id!);
      setFormData(response?.data);
      setInitialData(response?.data);
    } catch (error: any) {
      console.error("Error fetching task detail:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      HideLoading();
    }
  };

//   useEffect(() => {
//     fetchTaskDetail();
//   }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      console.log("TASK DETAIL GASSSSS", formData);
      const response = await UpdateTaskDataGan(formData);
      await fetchTaskDetail();
      handleDiscard()
    } catch (error: any) {
      console.error("Error create order:", error);
    }


  };
  const { theme } = useThemeStore();


  const handleChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
    console.log("Dara",initialData)
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    fetchTaskDetail();
  }, []);

  return (
    <Box>
      <PageTitle>Task Data</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Detail" />
        <CustomTab label="Group Task" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
      <Box component="form" width={"100%"} onSubmit={handleSubmit}>
      <PageTitle>Task Detail</PageTitle>
      {/* {id?.split("-")[0] === "P" ? <Detail /> : <GroupTaskDetail />} */}
      <Detail value={formData} disabled={!isEdit} onChange={handleChangeForm} isGrouping={true} />
      <Box
        mt={4}
        display={"flex"}
        width={"100%"}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "flex-end",
          alignItems: {
            xs: "flex-start",
            md: "flex-end",
          },
          gap: {
            xs: 1,
            md: 2,
            lg: 3,
          },
        }}
      >
        {isEdit ? (
          <>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={handleDiscard}
            >
              Discard Changes
            </CustomButton>
            <CustomButton
              type="submit"
              variant="contained"
              style={{ backgroundColor: colors?.primary200 }}
            >
              Save Changes
            </CustomButton>
          </>
        ) : (
          <CustomButton
            variant="contained"
            style={{ backgroundColor: colors?.primary200 }}
            onClick={handleEdit}
          >
            Edit
          </CustomButton>
        )}
      </Box>
    </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Box
      display={"flex"}
      sx={{
        flexDirection: "column",
        justifyContent: "justify-normal",
        alignItems: "flex-start",
        gap: {
          xs: 0,
          md: 1,
          lg: 2,
        },
      }}
    >
      <Box width={"100%"} component={"form"} onSubmit={handleSubmit}>
        <Box component="form" width={"100%"}>
          {formData?.task_list?.map((item: any, index: number) => (
            <CustomAccordion
              key={index}
              defaultExpanded={index === 0 ? true : false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="status"
                sx={{
                  borderRadius: "8px",
                }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"}>
                    {`Task ID : ${item?.id}`}
                    &nbsp;&nbsp;&nbsp;
                    {/* <Link
                      href={`/item/${item?.id}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Tooltip title="View Item">
                        <LaunchIcon />
                      </Tooltip>
                    </Link> */}
                  </Stack>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ background: theme?.bgPaper, borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px" }}>
                <Detail
                  value={item}
                  disabled={true}
                  showCost={true}
                  groupCost={formData?.cost}
                />
              </AccordionDetails>
            </CustomAccordion>
          ))}
        </Box>
      </Box>
    </Box>
      </CustomTabPanel>
    </Box>
  );
}
