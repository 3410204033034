import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { currencies } from "src/utils/paramData";
import PurchasingForm from "../components/PurchasingForm";
import PageTitle from "src/components/main/CustomText/PageTitle";
import ModalConfirm from "src/components/main/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { useFilterStore } from "src/store/filterStore";
import { createPurchaseItem } from "src/api/purchase";
import NotificationAlert from "src/components/main/Alert";

export default function PurchasingCreate() {
  const [data, setData] = useState<RequestAddPurchaseItem>({
    type: "RequestAddPurchaseItem",
    currency: currencies.length > 0 ? currencies[0].value : "",
  } as RequestAddPurchaseItem);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const { user_id, resetFilterStore } = useFilterStore();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData: RequestAddPurchaseItem) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fileReader = useCallback((acceptedFiles: File[]) => {
    const fileDataArray: BuktiPaymentBase64[] = [];

    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr: any = reader.result;
        // Extract MIME type and base64 data
        const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
        if (matches) {
          const mimeType = matches[1];
          const base64Data = matches[2];

          fileDataArray.push({
            bukti_payment_base64: base64Data,
            tipe_file: mimeType.split("/")[1],
          });

          if (fileDataArray.length === acceptedFiles.length) {
            setData((prevData: RequestAddPurchaseItem) => ({
              ...prevData,
              attach: [...(prevData.attach || []), ...fileDataArray],
            }));
          }
        } else {
          console.error("Invalid file data");
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handlePaymentFileUpload = (acceptedFiles: File[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setData((prevData: RequestAddPurchaseItem) => {
        const updatedAttachments = [...prevData.attach];
        updatedAttachments.splice(index, 1);
        return {
          ...prevData,
          attach: updatedAttachments,
        };
      });
    },
    [setData]
  );

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModal(true);
  };
  const handleSubmit = async () => {
    if (
      !data.item_id ||
      !data.currency ||
      !data.cost ||
      !data.attach||
      !user_id[0]
    ) {
      return NotificationAlert({
        message: "All required fields must be filled.",
        status: "error",
      });
    }

    

    const requestData: RequestAddPurchaseItem = {
      ...data,
      cost: Number(data?.cost),
      user_id: user_id[0],
    };

    try {
      const response = await createPurchaseItem(requestData);
      setOpenModal(false);
      if (response?.status === 200) {
        navigate("/purchase/purchasing");
      }
    } catch (error) {
      console.error("Failed to submit purchase item:", error);
    }
  };

  // Reset filter store
  useEffect(() => {
    return () => {
      resetFilterStore();
    };
  }, []);

  return (
    <Box>
      <PageTitle>Create Purchase</PageTitle>
      <PurchasingForm
        data={data}
        onChange={handleChange}
        onSubmit={handleFormSubmit}
        onUpload={(file) => handlePaymentFileUpload(file)}
        onDelete={(index) => handlePaymentFileDelete(index)}
      />
      <ModalConfirm
        text="Are you sure you want to submit?"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
