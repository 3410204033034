import { TextField, MenuItem } from "@mui/material";
import { serviceType, designType } from "src/utils/paramData";
import CustomInput from "src/components/main/CustomInput";

interface ItemTypeProps {
  name: string;
  id?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export function TypeDesign({
  name,
  id,
  value,
  disabled,
  onChange,
}: ItemTypeProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <CustomInput
      size="small"
      id={id}
      name={name}
      select
      disabled={disabled}
      onChange={handleChange}
      value={value || designType[0].value}
    >
      {designType.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomInput>
  );
}

export function TypeService({
  name,
  id,
  value,
  disabled,
  onChange,
}: ItemTypeProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <CustomInput
      size="small"
      id={id}
      name={name}
      select
      disabled={disabled}
      onChange={handleChange}
      value={value || serviceType[0].value}
    >
      {serviceType.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomInput>
  );
}
