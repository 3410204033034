import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import { sendRequestGET, sendRequestPOST, sendRequestPUT } from "../sendRequest";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification, hideNotification } =
  useLoadingStore.getState();


interface TaskList {
  status?: Array<string>;
  start_date?: string;
  end_date?: string;
  id?: string;
  page: number;
  limit: number;
  order_key?: string;
  sort_type?: string;
}

export const InquiryTaskList = async (
  {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
  }: TaskList = { page: 1, limit: 10 }
) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_TASK || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION TASK LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR TASK LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetTaskDetail = async (id: string) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_TASK_DETAIL || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION TASK DETAIL", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR TASK DETAIL", error);
    throw new Error(error);
  }
};

export const GetTaskType = async () => {
  
  const url = process.env.REACT_APP_ENDPOINT_TASK_TYPE || "";
    const userStore = useUserStore.getState();
  const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    const res = await sendRequestGET(url, header);
    console.info("ACTION TASK TYPE", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR TASK TYPE", error);
    throw new Error(error);
  }
};

export const GetTaskCalendar = async (data: TaskFilter) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_TASK_CALENDAR || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    start_date: data?.start_date,
    end_date: data?.end_date,
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION TASK CALENDAR", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR TASK CALENDAR", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetUnassignedTask = async () => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_GET_TASK_UNASSIGNED || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(url, header);
    console.info("ACTION UNASSIGNED TASK", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR UNASSIGNED TASK", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const assignTask = async (data: AssignTask) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_ASSIGN_TASK || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION ASSIGN TASK", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR ASSIGN TASK", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const updateTask = async (data: UpdateTaskDate) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_UPDATE_TASK_DATE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION UPDATE TASK DATE", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE TASK DATE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const reviseTask = async (data: TaskRevision) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_TASK_REVISION || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION TASK REVISION", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR TASK REVISION", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const approveTask = async (data: TaskApproval) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_ADMIN_APPROVE_TASK || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION APPROVE TASK", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR APPROVE TASK", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const groupTask = async (data: GroupTask) => {
    const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
  const url = process.env.REACT_APP_ENDPOINT_GROUP_TASK || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    console.log("DATA COST",data?.cost_string)
    const value = parseFloat(data?.cost_string);
    const numericValue = isNaN(value) ? 0 : value;
    data.cost = numericValue
    
    const res = await sendRequestPOST(url, data, header);
 
  
    console.info("ACTION GROUP TASK", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR GROUP TASK", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};


export const UpdateTaskDataGan = async (data: any) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/task");
const url = process.env.REACT_APP_ENDPOINT_UPDATE_TASK_DATA || "";
const header = {
  ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
  ...(menu_id && { "app-menu-id": menu_id }),
};

const reqUpdate = {
  id :data?.id,
  priority:data?.priority,
  start_date:formatDateToYYYYMMDD(data?.start_date),
  end_date:formatDateToYYYYMMDD(data?.end_date),
  user_id:data?.user_id,
}

try {
  showLoading();
  const res = await sendRequestPUT(url, reqUpdate, header);
  console.info("ACTION UPDATE TASK DATE", res);
  showNotification(res?.data?.message, "success");
  return res.data;
} catch (error: any) {
  console.error("ACTION ERROR UPDATE TASK DATE", error);
  showNotification(error.message, "error");
} finally {
  hideLoading();
}
};

function formatDateToYYYYMMDD(dateInput:string) {
  const date = new Date(dateInput);

  // If date is invalid, use the current date
  if (isNaN(date.getTime())) {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}