import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import {
  sendRequestGET,
  sendRequestPOST,
  sendRequestPUT,
} from "../sendRequest";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();

export const InquiryUserList = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_USER_LIST || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(url, header);
    console.info("ACTION USER LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR USER LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetUserDetail = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_USER_LIST || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    ShowLoading({});
    const res = await sendRequestGET(`${url}/${id}`, header);
    HideLoading();

    console.info("ACTION USER DETAIL", res);

    return res.data;
  } catch (error: any) {
    HideLoading();

    console.error("ACTION ERROR USER DETAIL", error);

    throw new Error(error);
  }
};

export const InquiryLeaveList = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_LEAVE_LIST || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(url, header);

    console.info("ACTION LEAVE LIST", res);

    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR LEAVE LIST", error);

    throw new Error(error);
  } finally {
    hideLoading();
  }
};

export const GetLeaveDetailByUser = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_LEAVE_DETAIL_USER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    ShowLoading({});
    const res = await sendRequestGET(`${url}/${id}`, header);
    HideLoading();
    console.info("ACTION LEAVE DETAIL", res);
    return res.data;
  } catch (error: any) {
    HideLoading();
    console.error("ACTION ERROR LEAVE DETAIL", error);
    throw new Error(error);
  }
};

export const createNewUser = async (data: User) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_CREATE_USER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  data.salary = Number(data.salary);

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION CREATE USER", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR CREATE USER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetLeaveDetail = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_LEAVE_DETAIL || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    ShowLoading({});
    const res = await sendRequestGET(`${url}/${id}`, header);
    HideLoading();
    console.info("ACTION LEAVE DETAIL", res);
    return res.data;
  } catch (error: any) {
    HideLoading();
    console.error("ACTION ERROR LEAVE DETAIL", error);
    throw new Error(error);
  }
};

export const updateUserDetail = async (data: User) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_USER_LIST || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    id: data?.id,
    username: data?.username,
    email: data?.email,
    display_name: data?.display_name,
    phone_number: data?.phone_number,
    role: data?.role,
    level: data?.level,
    salary: Number(data?.salary),
    status: data?.status,
  };

  try {
    showLoading();
    const res = await sendRequestPUT(url, req, header);
    console.info("ACTION UPDATE USER", res);
    showNotification(res?.data?.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR UPDATE USER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

interface SearchPainter {
  key: string;
  page_number?: number;
  number_of_record?: number;
}

export const SearchPainter = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_PAINTER_SEARCH || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(url, header);
    console.info("ACTION SEARCH PAINTER", res);

    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR SEARCH PAINTER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const SearchMarketing = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_MARKETING_SEARCH || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    const res = await sendRequestGET(url, header);
    console.info("ACTION SEARCH MARKETING", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR SEARCH MARKETING", error);
  }
};

export const SearchSupplier = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_SUPPLIER_SEARCH || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    const res = await sendRequestGET(url, header);
    console.info("ACTION SEARCH SUPPLIER", res);
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR SEARCH SUPPLIER", error);
  }
};

export const approveLeave = async (data: ApproveRejectLeave) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_APPROVE_LEAVE || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION APPROVE LEAVE", res);
    showNotification(res.data.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR APPROVE LEAVE", error);
    // showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const rejectLeave = async (data: ApproveRejectLeave) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_REJECT_LEAVE || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION APPROVE LEAVE", res);
    showNotification(res.data.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR APPROVE LEAVE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const changeUserPassword = async (data: ChangePassword) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_USER_CHANGE_PASSWORD || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    user_id: data?.user_id,
    old_password: data?.old_password,
    new_password: data?.confirm_new_password
  }

  try {
    showLoading();
    const res = await sendRequestPUT(url, req, header);
    console.info("ACTION CHANGE PASSWORD", res);
    showNotification(res.data.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR CHANGE PASSWORD", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}

export const getRoleUser = async () => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_USER_ROLE_LIST || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const header = {
    ...(useUserStore.getState().token && { Authorization: `Bearer ${useUserStore.getState().token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    const res = await sendRequestGET(url, header);
    console.info("ACTION GET ROLE USER", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR GET ROLE USER", error);
  }
}