import { Box, Stack, MenuItem } from "@mui/material";
import { getCurrentTime } from "src/utils/formatter";
import { Ekspedisi } from "src/utils/paramData";
import CustomButton from "../CustomButton";
import CustomDropbox from "../CustomDropbox";
import CustomInput from "../CustomInput";
import CustomPaper from "../CustomPaper";
import CustomText from "../CustomText";
import ModalConfirm from "../ModalConfirm";
import { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { itemQCAdminFromCustomer, UploadResiAdmin } from "src/api/item";

interface Props {
  detailItem: DetailItem;
  refetch: () => void;
}
export default function LeatherFromSupplier({ detailItem, refetch }: Props) {
  const [files, setFiles] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>("");

  const id = useLocation().pathname.split("/").pop();

  const handleFileUpload = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDelete = (fileName: string) => {
    setFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  const handleSubmit = async () => {
    const req: CompleteStep = {
      item_id: id!,
      status: "COMPLETED",
      status_desc: "Leather From Supplier Ready. " + notes,
      attachment: [],
      attachLeather: files,
      notes: notes,
      enum_status: "COMPLETED",
      base: 0,
      leather: 1,
    };

    await itemQCAdminFromCustomer(req);
    await refetch();

    setOpenModal(false);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModal(true);
  };

  return (
    <Box component={"form"} onSubmit={handleFormSubmit} sx={{ width: "50%" }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            Leather From Supplier
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Stack mt={2}>
          <CustomText variant="body1">Notes</CustomText>
          <CustomInput
            size="small"
            id="notes"
            name="notes"
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          />
        </Stack>
        <Stack mt={2}>
          <CustomText variant="body1">Proof of Supplier Leather</CustomText>
          <Box
            mt={1}
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={"row"}
          >
            <CustomDropbox
              size={150}
              files={files}
              onUpload={handleFileUpload}
              onDelete={handleDelete}
            />
          </Box>
        </Stack>

        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure you want to submit this data?"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
