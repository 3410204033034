import { sendRequestGET, sendRequestPOST } from "src/api/sendRequest";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import { DecimalFormatter, parseAmount } from "src/utils/formatter";
import { getCurrencySymbol } from "src/utils/paramData";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();

interface FilterDate {
  start_date: string;
  end_date: string;
}

interface OrderList {
  status?: Array<string>;
  start_date?: string;
  end_date?: string;
  id?: string;
  page: number;
  limit: number;
  order_key?: string;
  sort_type?: string;
  created_at?: FilterDate;
}

export const InquiryOrderList = async (
  {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
    created_at,
  }: OrderList = { page: 1, limit: 10 }
) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_LIST || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    status,
    start_date,
    end_date,
    id,
    page,
    limit,
    order_key,
    sort_type,
    created_at,
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);

    console.info("ACTION ORDER LIST", res);

    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR ORDER LIST", error);
  } finally {
    hideLoading();
  }
};

export const GetOrderDetail = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_DETAIL || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION ORDER DETAIL", res);

    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR ORDER DETAIL", error);
  } finally {
    hideLoading();
  }
};

export const CreateOrder = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const today = new Date().toISOString().split("T")[0];

  const req = {
    customer_id: data.customer.id ? data.customer.id : "",
    shipment_id: data.shipment.id ? data.shipment.id : "",
    order_from: data.order.order_from,
    deadline: data.order.deadline,
    start_date: today,
    end_date: data.order.deadline,
    amount: parseAmount(data.order.amount),
    notes: data.order.notes,
    referal_code: data.order.referal_code,
    currency: data.order.currency,
    payment_method: data.order.payment_method,
    payment_curr: data.order.payment_curr,
    payment_nominal: parseAmount(data.order.payment_nominal),
    list_item: data.item,
    customer: {
      nama: data.customer.name,
      phone: data.customer.phone_number,
      email: data.customer.email,
      tiktok: data.customer.tiktok,
      instagram: data.customer.instagram,
      tanggal_lahir: data.customer.birth_date,
    },
    shipment: {
      province: data.shipment.province,
      city: data.shipment.city,
      country: data.shipment.country,
      village: data.shipment.village,
      district: data.shipment.district,
      address: data.shipment.address,
      postal_code: data.shipment.postal_code,
      recipient_name: data.shipment.recipient_name,
      recipient_contact: data.shipment.recipient_contact,
      type_shipment: data.shipment.type_shipment,
      is_international:
        (data?.shipment?.country ?? "").toLowerCase() !== "indonesia",
    },
    bukti_payment: data.payment.attachments,
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION CREATE ORDER", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR CREATE ORDER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const GetOrderLog = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_LOG || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    ShowLoading({});
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET ORDER LOG", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET ORDER LOG", error);
    throw new Error(error);
  } finally {
    HideLoading();
  }
};

export const getDataPelunasan = async (id: string) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_DATA_PELUNASAN || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET DATA PELUNASAN", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET DATA PELUNASAN", error);
  } finally {
    hideLoading();
  }
};

export const orderRepayment = async (data: any) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_REPAYMENT || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION ORDER REPAYMENT", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR ORDER REPAYMENT", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const cancelOrder = async (req: CancelOrder) => {
  const userStore = useUserStore.getState();
  const url = process.env.REACT_APP_ENDPOINT_ORDER_CANCEL || "";
  const menu_id = FindMenuID(userStore.menu_mapping, "/order");
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION CANCEL ORDER", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR CANCEL ORDER", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};
