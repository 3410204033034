import { sendRequestPOST } from "src/api/sendRequest";
import { useUserStore } from "src/store/userStore";

const url = process.env.REACT_APP_ENDPOINT_LOGIN || "";

export const DoLogin = async (data: any) => {
  try {
    const res = await sendRequestPOST(url, data);
    console.info("ACTION LOGIN", res);
    return res;
  } catch (error: any) {
    console.error("ACTION LOGIN ERROR", error);
    throw new Error(error);
  }
};
