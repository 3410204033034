import { Box, Alert, Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import { createRoot } from "react-dom/client";

interface Props {
  message: string;
  status: string;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export default function NotificationAlert({ message, status }: Props) {
  const root = document.createElement("div");
  document.body.appendChild(root);

  createRoot(root).render(
    <Box sx={{ width: 500 }}>
      <Snackbar
        open={true}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // TransitionComponent={SlideTransition}
        sx={{ position: "fixed", zIndex: 1500 }}
      >
        <Alert
          severity={status === "success" ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );

  setTimeout(() => {
    document.body.removeChild(root);
  }, 5000);
}
