import { Box } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useCallback, useEffect, useState } from "react";
import UploadDesign from "../Process/UploadDesign";
import { GetOrderLog } from "src/api/order";
import NotificationAlert from "../Alert";
import { useLocation } from "react-router-dom";
import Information from "../Process/Information";
import { useOrderStore } from "src/store/orderStore";
import PendingIcon from "@mui/icons-material/Pending";
import CompletePayment from "../Process/CompletePayment";
import WaCLientOrder from "../Process/wa_order";

export default function TimelineProcessOrder() {
  const [orderLog, setOrderLog] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stages, setStages] = useState<any[]>([]);

  const id = useLocation().pathname.split("/").pop();
  const { isOrderComplete, order } = useOrderStore();

  const fetchOrderLog = async () => {
    try {
      setIsLoading(true);
      const response = await GetOrderLog(id!);
      setOrderLog(response?.data);
    } catch (error: any) {
      console.error("Error fetching order log:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  function checkColor(index:number,log:string) {
    console.log("Order Status",order)
    if (order.status_order === "Done"){
      return "success"
    }
    if (index === orderLog.length - 1 && log !== "COMPLETED" ){
      return "warning"
    }else {
        return "success"
    }
  }



  
  function checkColorLine(index:number,log:string) {
    console.log("Order Status",order)
    if (order.status_order === "Done"){
      return "green"
    }
    if (index === orderLog.length - 1 && log !== "COMPLETED" ){
      return "warning.main"
    }else {
        return "green"
    }
  }

  function checkColorIcon(index:number,log:string) {
    console.log("Order Status",order)
    if (order.status_order === "Done"){
      return <CheckCircleIcon color="success" fontSize="medium" />

    }
    if (index === orderLog.length - 1 && log !== "COMPLETED" ){
      return <PendingIcon color="warning" fontSize="medium" />
    }else {
        return <CheckCircleIcon color="success" fontSize="medium" />

    }
  }

  useEffect(() => {
    fetchOrderLog();
  }, []);

  const renderStep = useCallback((data: any) => {
    const step = data?.step_code;
    const status = data?.status;

    if (step === "SUP-SEL" && status !== "COMPLETED") {
      return <UploadDesign />;
    } else {
      return <Information information="Check Next Stage" />;
    }
  }, []);



  
  return (
    <Box>
      <Timeline>
        {/* <TimelineItem>
          <TimelineContent>5 Day(s) Left</TimelineContent>
          <TimelineOppositeContent />
        </TimelineItem> */}
        {orderLog.map((log, index) => (
          <TimelineItem key={log.stage_code}>
            <TimelineSeparator>
              <TimelineDot
                variant="outlined"
                sx={{ margin: 0 }}
                color={
                  checkColor(index,log.status)
                }
              >
                {checkColorIcon(index,log.status)}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  bgcolor:
                  checkColorLine(index,log.status)
                }}
              />
            </TimelineSeparator>
            <TimelineContent>{log?.description}</TimelineContent>
            <TimelineOppositeContent
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {log.created_at.split("T")[0]}
            </TimelineOppositeContent>
          </TimelineItem>
        ))}
        {order?.status_order?.toLowerCase() !== "canceled" ? (
          isOrderComplete ? (
            order?.tanggal_pelunasan ? (
              order?.status_order === "Done" ?       
              <WaCLientOrder information="ASSSS"></WaCLientOrder>
              :
              <Information information="Waiting Shipping by Logistic" />
            ) : (
              <CompletePayment refetch={fetchOrderLog} />
            )
          ) : (
            <Information information="Item Still On Process" />
          )
        ) : (
          <Information information="Order Canceled" />
        )}
      </Timeline>

    </Box>
  );
}
