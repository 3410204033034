import { Modal, Stack } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface Props {
  text?: string;
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}
export default function ModalConfirm({ text, open, onSubmit, onClose }: Props) {
  return (
    <Modal open={open} onClose={onClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          boxShadow: 24,
          px: 4,
          py: 2,
        }}
      >
        <Stack alignItems="start" direction="row" gap={2}>
          <CheckCircleIcon color="success" fontSize="large" />
          <CustomText variant="h6" gutterBottom>
            {text}
          </CustomText>
        </Stack>

        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} mt={2}>
          <CustomButton
            variant="outlined"
            customType="cancel"
            onClick={onClose}
          >
            Close
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            Submit
          </CustomButton>
        </Stack>
      </CustomPaper>
    </Modal>
  );
}
