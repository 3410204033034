import {
  Box,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
} from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomDivider from "src/components/main/CustomDivider";
import CustomInput from "src/components/main/CustomInput";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import InputDate from "src/components/main/Datepicker";
import NewInputNominal from "src/components/main/NewInputNominal";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import useThemeStore from "src/store/themeStore";
import CloseIcon from "@mui/icons-material/Close";
import CustomDropbox from "src/components/main/CustomDropbox";
import { currencies, paymentMethod } from "src/utils/paramData";
import { useLocation } from "react-router-dom";
import ModalConfirm from "src/components/main/ModalConfirm";
import { useLoadingStore } from "src/store/loadingStore";
import { rushItem } from "src/api/item";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: RushData;
  refetch: () => void;
}

export default function ModalRush(props: Props) {
  const { theme } = useThemeStore();
  const { isOpen, onClose, data, refetch } = props;
  const { showNotification } = useLoadingStore();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [files, setFiles] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    currency: currencies[0].value,
    payment_method: paymentMethod[0].value,
  });

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data, isOpen]);

  const id = useLocation().pathname.split("/").pop();

  const fileReader = useCallback((acceptedFiles: any[]) => {
    const fileDataArray: any[] = [];

    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr: any = reader.result;
        // Extract MIME type and base64 data
        const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
        if (matches) {
          const mimeType = matches[1];
          const base64Data = matches[2];

          fileDataArray.push({
            bukti_payment_base64: base64Data,
            tipe_file: mimeType.split("/")[1],
          });

          if (fileDataArray.length === acceptedFiles.length) {
            setFiles((prevData: any) => ({
              ...prevData,
              attachments: [...(prevData.attachments || []), ...fileDataArray],
            }));
          }
        } else {
          console.error("Invalid file data");
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handlePaymentFileUpload = (acceptedFiles: any[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setFiles((prevData: any) => {
        const updatedAttachments = [...prevData.attachments];
        updatedAttachments.splice(index, 1);
        return {
          ...prevData,
          attachments: updatedAttachments,
        };
      });
    },
    [setFiles]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!files?.attachments) {
      showNotification("Please upload proof of payment", "error");
      return;
    }

    if (!formData?.deadline) {
      showNotification("Please input deadline", "error");
      return;
    }
    const req: ReqItemRush = {
      item_id: id!,
      payment_cost: formData?.amount,
      payment_currency: formData?.currency,
      payment_method: formData?.payment_method,
      deadline: formData?.deadline,
      bukti_payment: files?.attachments,
    };

    const response = await rushItem(req);
    if (response?.status === 200) {
      await refetch();
      setOpenModal(false);
    }
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.deadline) {
      showNotification("Invalid Deadline", "error");
      return;
    }
    if (!files?.attachments?.length) {
      showNotification("Please upload proof of payment", "error");
      return;
    }

    setOpenModal(true);
  };

  const handleClose = () => {
    setFiles({});
    setFormData({
      currency: currencies[0].value,
      payment_method: paymentMethod[0].value,
    });
    setOpenModal(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          height: "auto",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{ width: "100%" }}
          component={"form"}
          onSubmit={handleSubmitForm}
        >
          <Box display={"flex"} justifyContent={"space-between"}>
            <Stack direction={"row"} gap={2}>
              <SpeedOutlinedIcon
                style={{ color: theme?.icon }}
                fontSize="large"
              />
              <CustomText variant="h6" gutterBottom>
                Rush Item
              </CustomText>
            </Stack>
            <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
              <CloseIcon style={{ color: theme?.icon }} fontSize="large" />
            </Box>
          </Box>
          <CustomDivider sx={{ mt: 1 }} />
          <Box
            sx={{ py: 2 }}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            gap={2}
          >
            <Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Charge Fee</CustomText>
                <NewInputNominal
                  required
                  size="small"
                  id="fee"
                  name="fee"
                  value={formData?.biaya_percepatan?.toString() || "0"}
                  onChange={handleChange}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Currency</CustomText>
                <CustomInput
                  required
                  onChange={handleChange}
                  size="small"
                  id="currency"
                  name="currency"
                  select
                  value={formData?.currency}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </CustomInput>
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Payment Method</CustomText>
                <CustomInput
                  required
                  size="small"
                  id="payment_method"
                  name="payment_method"
                  select
                  onChange={handleChange}
                  value={formData?.payment_method}
                >
                  {paymentMethod
                    .filter((item) => item.currency === formData?.currency)
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </CustomInput>
              </Stack>
            </Stack>
            <Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Total Amount</CustomText>
                <NewInputNominal
                  required
                  size="small"
                  id="cost"
                  name="cost"
                  value={formData?.amount?.toString() || "0"}
                  onChange={handleChange}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Deadline</CustomText>
                <InputDate
                  id="deadline"
                  name="deadline"
                  value={formData?.deadline}
                  onChange={handleChange}
                />
              </Stack>
            </Stack>
          </Box>
          <CustomText variant="subtitle1" gutterBottom>
            Proof of Payment
          </CustomText>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={"row"}
          >
            <CustomDropbox
              files={files?.attachments}
              filesType="base64"
              onUpload={handlePaymentFileUpload}
              deleteByIndex={true}
              onDeleteByIndex={handlePaymentFileDelete}
              size={150}
            />
          </Box>

          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <CustomButton variant="contained" type="submit">
              Submit
            </CustomButton>
          </Box>
        </Box>
        <ModalConfirm
          text="Are you sure to do this action?"
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSubmit={handleSubmit}
        />
      </CustomPaper>
    </Modal>
  );
}
