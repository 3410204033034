const darkTheme: ITheme = {
  mode: "dark",
  text: "#FFFFFF",
  bgPrimary: "#313030",
  bgSecondary: "#242424",
  bgPaper: "#101316",
  bgDropzone: "#26282B",
  bgDropzoneActive: "#5e5c5e",
  bgAccordion: "#101316",
  buttonText: "#FFFFFF",
  button100: "#ff3333",
  button200: "#910808",
  button300: "#FFFFFF",
  buttonDisable: "#D9D9D9",
  tableBg: "#101316",
  tableHeader: "#090a0c",
  tableBorder: "#3B3B3B",
  tableDivider: "#8E8E8E",
  tableText: "#FFFFFF",
  checkbox: "#FFFFFF",
  checkboxChecked: "#DD1D1D",
  checkboxDisabled: "#ab9f9f",
  inputPrimary: "#26282B",
  inputSecondary: "#26282B",
  inputBorder: "#3B3B3B",
  inputText: "#FFFFFF",
  inputTextDisabled: "#ab9f9f",
  inputLabel: "#FFFFFF",
  inputLabelHover: "#3864f5",
  borderCard: "#FFFFFF",
  navbarPrimary: "#DD1D1D",
  navbarSecondary: "#910808",
  icon: "#FFFFFF",
  divider: "#3B3B3B",
  textLink: "#FFFFFF",
  secondary100: "#337CCF",
  secondary200: "#1450A3",
  secondary300: "#4D2DB7",
  taskDesign: "#6b1375",
  taskPaint: "#16319c",
  taskRecon: "#bdaa20",
  taskLeave: "#bd2020",
  border: "#3B3B3B",
};

const lightTheme: ITheme = {
  mode: "light",
  text: "#000000",
  bgPrimary: "#FFFFFF",
  bgSecondary: "#FFFFFF",
  bgPaper: "#FFFFFF",
  bgDropzone: "#FFFFFF",
  bgDropzoneActive: "#FFFFFF",
  bgAccordion: "#201F21",
  buttonText: "#FFFFFF",
  button100: "#DD1D1D",
  button200: "#3B3B3B",
  button300: "#000000",
  buttonDisable: "#D9D9D9",
  tableBg: "#FFFFFF",
  tableHeader: "#FFFFFF",
  tableBorder: "#FFFFFF",
  tableDivider: "#8E8E8E",
  tableText: "#000000",
  checkbox: "#FFFFFF",
  checkboxChecked: "#DD1D1D",
  checkboxDisabled: "#5c5858",
  inputPrimary: "#252525",
  inputSecondary: "#3B3B3B",
  inputBorder: "#3B3B3B",
  inputText: "#000000",
  inputTextDisabled: "#ab9f9f",
  inputLabel: "#000000",
  inputLabelHover: "#3864f5",
  borderCard: "#D9D9D9",
  navbarPrimary: "#DD1D1D",
  navbarSecondary: "#3B3B3B",
  icon: "#000000",
  divider: "#3B3B3B",
  textLink: "#03ab30",
  secondary100: "#337CCF",
  secondary200: "#1450A3",
  secondary300: "#191D88",
  taskDesign: "#FFFFFF",
  taskPaint: "#FFFFFF",
  taskRecon: "#FFFFFF",
  taskLeave: "#bd2020",
  border: "#636360",
};

export { darkTheme, lightTheme };
