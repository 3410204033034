import { Box, Card, Stack } from "@mui/material";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { GetMonthName, hexToRgba } from "src/utils/utils";
import useThemeStore from "src/store/themeStore";
import CustomCard from "src/components/main/CustomCard";
import { FINANCE_DETAIL_INCOME_COLUMNS } from "src/constants/finance";
import { DecimalFormatter } from "src/utils/formatter";

interface Props {
  data: FinanceDetailIncome;
}
export default function ReportIncome({ data }: Props) {
  const { theme } = useThemeStore();
  return (
    <Box>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Income Data List
            </CustomText>
          </Box>
        </Box>
        <CustomTable
          columns={FINANCE_DETAIL_INCOME_COLUMNS}
          data={data?.incom_list || []}
          pagination={false}
        />
      </Card>
      <Box
        flexDirection={"row"}
        display={"flex"}
        justifyContent={"flex-end"}
        gap={2}
      >
        <Stack width={"40%"}>
          <CustomCard text="Monthly Debt Summary">
            <Stack mt={2}>
              {data?.debt_summary?.data_report?.map((item) => (
                <>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <CustomText variant="subtitle1">
                      {`Debt Repayment ${GetMonthName(
                        Number(item?.payment_month?.split("-")[1])
                      )} (IDR)`}
                    </CustomText>
                    <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                      item?.total_amount_in_idr?.toString()
                    )}`}</CustomText>
                  </Stack>
                </>
              ))}
            </Stack>
            <Stack mt={2}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ backgroundColor: hexToRgba("#fcba03", 0.1) }}
              >
                <CustomText variant="h6" fontWeight={900} gutterBottom={false}>
                  Total Debt Income in IDR
                </CustomText>
                <CustomText variant="h6" fontWeight={900} gutterBottom={false}>
                  {`Rp ${DecimalFormatter(
                    data?.debt_summary?.total_in_idr.toString()
                  )}`}
                </CustomText>
              </Stack>
            </Stack>
          </CustomCard>
        </Stack>
        <Stack width={"40%"}>
          <CustomCard text="Monthly Income Summary">
            <Stack mt={2}>
              {data?.summary?.data_report?.map((item) => (
                <>
                  <Stack
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <CustomText variant="subtitle1">
                      {`Income ${item?.method_summary} (IDR)`}
                    </CustomText>
                    <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                      item?.total_amount_idr?.toString()
                    )}`}</CustomText>
                  </Stack>

                  {item?.total_amount_usd && (
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <CustomText variant="subtitle1">
                        {`Income ${item?.method_summary} (USD)`}
                      </CustomText>
                      <CustomText variant="subtitle1">{`$ ${DecimalFormatter(
                        item?.total_amount_usd?.toString()
                      )}`}</CustomText>
                    </Stack>
                  )}
                </>
              ))}
            </Stack>
            <Stack mt={2}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1" fontWeight={700}>
                  Total Income (IDR)
                </CustomText>
                <CustomText variant="subtitle1" fontWeight={700}>
                  {`Rp ${DecimalFormatter(
                    data?.summary?.total_in_idr?.toString()
                  )}`}
                </CustomText>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1" fontWeight={700}>
                  Total Income (USD)
                </CustomText>
                <CustomText variant="subtitle1" fontWeight={700}>
                  {`$ ${DecimalFormatter(
                    data?.summary?.total_usd?.toString()
                  )}`}
                </CustomText>
              </Stack>
            </Stack>
            <Stack mt={2}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1">* USD to IDR Rate</CustomText>
                <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                  data?.summary?.usd_idr_rate?.toString()
                )}`}</CustomText>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ backgroundColor: hexToRgba("#fcba03", 0.1) }}
              >
                <CustomText variant="h6" fontWeight={900} gutterBottom={false}>
                  Total Income in IDR
                </CustomText>
                <CustomText variant="h6" fontWeight={900} gutterBottom={false}>
                  {`Rp ${DecimalFormatter(
                    data?.summary?.total_in_idr?.toString()
                  )}`}
                </CustomText>
              </Stack>
            </Stack>
          </CustomCard>
        </Stack>
      </Box>
    </Box>
  );
}
