import React from "react";
import BreadCrumbs from "../../../components/navigators/BreadCrumb";
import Chart from "./components/Chart";
import CardAnalytic from "./components/CardAnalytic";
import CardNeedAction from "./components/CardNeedAction";
import { Box, Grid, Link, Stack } from "@mui/material";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useThemeStore from "src/store/themeStore";
import RecentUpdates from "./components/RecentUpdates";
import PageTitle from "src/components/main/CustomText/PageTitle";

function Dashboard() {
  return (
    <Box>
    <PageTitle>Dashboard</PageTitle>
      <Box>
        <Grid container columnSpacing={2}>
          <Grid item lg={7} xl={8}>
            <Chart />
          </Grid>
          <Grid item lg={5} xl={4}>
            <RecentUpdates />
          </Grid>
        </Grid>
      </Box>

      <CardNeedAction />
    </Box>
  );
}

export default Dashboard;
