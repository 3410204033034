import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import { Grid, ImageListItem, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import useThemeStore from "src/store/themeStore";
import CustomButton from "src/components/main/CustomButton";
import { DecimalFormatter } from "src/utils/formatter";
import { hexToRgba } from "src/utils/utils";
import CustomPaper from "src/components/main/CustomPaper";
import CustomInput from "src/components/main/CustomInput";
import { useCallback, useEffect, useState } from "react";
import CustomDropbox from "src/components/main/CustomDropbox";
import { approveRejectReimbursement } from "src/api/reimburse";
import ModalConfirm from "src/components/main/ModalConfirm";
import { getItemLogAttachmentGroup } from "src/api/item";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: Reimbursement[];
  doneAction: string[];
  onDoneAction: (num: string[]) => void;
  refetch: () => void;
}

export default function ActionApproval(props: Props) {
  const { isOpen, onClose, data, doneAction, onDoneAction, refetch } = props;
  const theme = useTheme();
  const { theme: myTheme } = useThemeStore();

  const [files, setFiles] = useState<Attachment[]>([{}] as Attachment[]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [approveDialog, setApproveDialog] = useState<boolean>(false);
  const [rejectDialog, setRejectDialog] = useState<boolean>(false);
  const maxSteps = data?.length;

  const fetchAttachment = async () => {
    const attachment = data[activeStep]?.attachment; // Get the attachment directly from data
    if (attachment) {
      const response = await getItemLogAttachmentGroup(attachment);
      setFiles(response?.data);
    }
  };
  useEffect(() => {
    fetchAttachment(); // No argument passed
  }, [data, activeStep]); // Add activeStep as a dependency if it's being used inside the effect

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (status: string) => {
    const req: ApproveRejectReimbursement = {
      id_reimburse: data[activeStep]?.reimburse_id,
      status: status,
    };
    const response = await approveRejectReimbursement(req);
    if (response?.status === 200) {
      maxSteps > activeStep + 1 &&
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      onDoneAction([...doneAction, data[activeStep]?.reimburse_id]);
      await refetch();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: "auto",
          boxShadow: 24,
          pt: 2,
        }}>
        <Box sx={{ width: "100%", flexGrow: 1 }}>
          <Box sx={{ px: 4 }}>
            <Stack direction={"row"} gap={2}>
              <DifferenceIcon
                style={{ color: myTheme?.icon }}
                fontSize="large"
              />
              <CustomText variant="h6" gutterBottom>
                Approval
              </CustomText>
            </Stack>
            <CustomDivider sx={{ mt: 1 }} />
            <Box sx={{ py: 2 }}>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                alignContent={"center"}
                justifyContent={"flex-start"}>
                <CustomText
                  variant="subtitle1"
                  fontWeight={"bold"}
                  gutterBottom>
                  {data[activeStep]?.reimburse_id}
                </CustomText>
              </Stack>
              <Grid container columnSpacing={2} alignItems={"flex-start"}>
                <Grid item sm={5}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      User Name
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.user_name || "-"}
                    </CustomText>
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Item Name
                    </CustomText>
                    <CustomText
                      variant="subtitle1"
                      sx={{ height: 50 }}
                      fontWeight={700}>
                      {data[activeStep]?.reimburse_name || "-"}
                    </CustomText>
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Type
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.ship_id || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Expedition
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.expedtition || "-"}
                    </CustomText>
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Date
                    </CustomText>
                    <CustomText
                      variant="subtitle1"
                      sx={{ height: 50 }}
                      fontWeight={700}>
                      {data[activeStep]?.claim_date?.split("T")[0] || "-"}
                    </CustomText>
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Receipt Number
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.no_resi || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={3}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Status
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.status || "-"}
                    </CustomText>
                  </Stack>
                  <Stack mb={2} sx={{ visibility: "hidden" }}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Price
                    </CustomText>
                    <CustomText
                      variant="subtitle1"
                      sx={{ height: 50 }}
                      fontWeight={"bold"}>
                      {`Rp ${DecimalFormatter(
                        data[activeStep]?.cost?.toString()
                      )}` || "-"}
                    </CustomText>
                  </Stack>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Price
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={"bold"}>
                      {`Rp ${DecimalFormatter(
                        data[activeStep]?.cost?.toString()
                      )}` || "-"}
                    </CustomText>
                  </Stack>
                </Grid>
              </Grid>
              <Box
                display={"flex"}
                flexDirection={files.length > 1 ? "column" : "row"}
                justifyContent={"flex-start"}
                alignContent={"center"}
                gap={2}>
                <Stack mb={2}>
                  <CustomText variant="body2" gutterBottom>
                    Receipt Photo
                  </CustomText>
                  <CustomDropbox existingFiles={files} viewOnly={true} />
                </Stack>
                <Stack mb={2}>
                  <CustomText variant="body2" gutterBottom>
                    Notes
                  </CustomText>
                  <CustomInput size="small" id="notes" name="notes" />
                </Stack>
              </Box>
              <Box mt={2}>
                {!doneAction?.includes(data[activeStep]?.reimburse_id) && (
                  <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                    <CustomButton
                      variant="outlined"
                      customType="cancel"
                      onClick={() => setRejectDialog(true)}>
                      Reject
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      onClick={() => setApproveDialog(true)}>
                      Approve
                    </CustomButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              background: myTheme?.bgPrimary,
              color: myTheme?.inputLabel,
            }}
            nextButton={
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                gap={8}
                mr={2}>
                <Button
                  sx={{
                    color: myTheme?.inputLabel,
                    "&.Mui-disabled": {
                      color: hexToRgba(myTheme?.inputLabel, 0.5),
                    },
                  }}
                  size="medium"
                  onClick={handleBack}
                  disabled={activeStep === 0}>
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
                <Button
                  sx={{
                    color: myTheme?.inputLabel,
                    "&.Mui-disabled": {
                      color: hexToRgba(myTheme?.inputLabel, 0.5),
                    },
                  }}
                  size="medium"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}>
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              </Box>
            }
            backButton={<Box />}
          />

          <ModalConfirm
            text="Are you sure you want to approve this reimbursement?"
            open={approveDialog}
            onClose={() => setApproveDialog(false)}
            onSubmit={() => handleSubmit("Approved")}
          />
          <ModalConfirm
            text="Are you sure you want to reject this reimbursement?"
            open={rejectDialog}
            onClose={() => setRejectDialog(false)}
            onSubmit={() => handleSubmit("Rejected")}
          />
        </Box>
      </CustomPaper>
    </Modal>
  );
}
