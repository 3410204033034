import React from "react";
import BreadCrumbs from "../../../components/navigators/BreadCrumb";
import { Box, Divider } from "@mui/material";
import Detail from "./components/Detail";
import ItemList from "./components/ItemList";
import Process from "./components/Process";
import CustomText from "src/components/main/CustomText";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import PageTitle from "src/components/main/CustomText/PageTitle";

function OrderDetail() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <PageTitle>Order Detail</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Detail" />
        <CustomTab label="Item List" />
        <CustomTab label="Process" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <Detail />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ItemList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Process />
      </CustomTabPanel>
    </Box>
  );
}

export default OrderDetail;
