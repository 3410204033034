import { Button, IconButton, Stack } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";

interface Props {
  data?: string;
  action: string;
  handleClick?: (event: any, data: string, action: string) => void;
}

export default function ApproveReject({ data, action, handleClick }: Props) {
  return (
    <Stack direction={"row"} spacing={1} justifyContent={"center"}>
      {action === "approve" ? (
        <IconButton
          sx={{
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.2)",
            },
          }}
          onClick={(e) => handleClick?.(e, data!, action)}
        >
          <CheckBoxIcon color="success" />
        </IconButton>
      ) : (
        <IconButton
          sx={{
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.2)",
            },
          }}
          onClick={(e) => handleClick?.(e, data!, action)}
        >
          <CancelIcon color="error" />
        </IconButton>
      )}
    </Stack>
  );
}
