import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { getCurrentTime } from "src/utils/formatter";
import { formatPhoneNumber } from "src/utils/utils";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";
import CustomPaper from "../CustomPaper";
import CustomText from "../CustomText";
import InputDate from "../Datepicker";
import { useState, useEffect, Fragment } from "react";
import { getWAMessageMarketing } from "src/api/item";
import { useLoadingStore } from "src/store/loadingStore";
import ModalConfirm from "../ModalConfirm";
import { approveTask, reviseTask } from "src/api/task";
import TaskPoint from "../TaskPoint";
import { SearchPainter } from "src/api/user";
import { useDebouncedCallback } from "use-debounce";
import useThemeStore from "src/store/themeStore";

interface Props {
  refetch: () => void;
  taskId?: string;
}
export default function ClientApproveItem({ taskId, refetch }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [message, setMessage] = useState<any>({});
  const [openModalRevision, setOpenModalRevision] = useState<boolean>(false);
  const [openModalApprove, setOpenModalApprove] = useState<boolean>(false);

  const { theme } = useThemeStore();
  const { showNotification } = useLoadingStore();

  const fetchWAMessage = async () => {
    const res = await getWAMessageMarketing(taskId!);
    console.log(res?.data);
    setMessage(res?.data || {});
  };

  useEffect(() => {
    fetchWAMessage();
  }, []);

  const fetchSearchAssignee = async (key: string) => {
    if (key === "" || key === null || key === undefined) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await SearchPainter();
      response.data ? setAssigneeList(response.data) : setAssigneeList([]);
    } catch (error: any) {
      console.error("Error fetching assignee:", error);
    } finally {
      setIsLoading(false); // Reset loading state after API call
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleCopyClipboard = () => {
    navigator.clipboard
      .writeText(decodeURIComponent(message?.wa_message?.replace(/\+/g, " ")))
      .then(() => {
        showNotification("Message copied to clipboard", "success");
      });
  };

  useEffect(() => {
    const calculateTotalDays = () => {
      if (data?.end_date && data?.start_date) {
        const totalDays =
          Math.floor(
            (new Date(data.end_date).getTime() -
              new Date(data.start_date).getTime()) /
              (1000 * 60 * 60 * 24)
          ) + 1;
        setData((prevData: any) => ({
          ...prevData,
          main_day: totalDays,
        }));
      }
    };

    calculateTotalDays();
  }, [data?.end_date, data?.start_date]);

  const handleApprove = async () => {
    const req: TaskApproval = {
      task_id: taskId!,
      is_completed: taskId!.startsWith("M") ? true : false,
    };
    await approveTask(req);
    await refetch();
    setOpenModalApprove(false);
  };

  const handleRevision = async () => {
    const req: TaskRevision = {
      task_id: taskId,
      user_id: selectedUser?.id,
      main_day: parseInt(data?.main_day),
      ...data,
    };
    await reviseTask(req);
    await refetch();
    setData({});
    setIsRevision(false);
  };

  const filterOptions = (options: any[], { inputValue }: any) => {
    return options.filter((option: { username: string }) =>
      option.username.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchAssignee, 1000);

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setSelectedUser(newValue);
    setData((prevData: any) => ({ ...prevData, ["assignee"]: newValue?.id }));
  };

  return (
    <Box component={"form"}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        {taskId?.startsWith("M") ? (
          <CustomText variant="body1" fontWeight={"bold"}>
            Item Result Approval From Client
          </CustomText>
        ) : (
          <CustomText variant="body1" fontWeight={"bold"}>
            Task Approval By Admin {taskId ? `(${taskId})` : ""}
          </CustomText>
        )}

        <CustomText variant="body2">{getCurrentTime()}</CustomText>
      </Stack>
      {taskId?.startsWith("M") && (
        <Box mt={2}>
          <CustomText variant="body1">Send a Message</CustomText>
          <Stack
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
            }}
            mt={2}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <CustomButton
              variant="contained"
              onClick={() =>
                window.open(
                  `https://wa.me/${formatPhoneNumber(
                    message?.no_telp || ""
                  )}?text=${message?.wa_message}`,
                  "_blank"
                )
              }
            >
              WA.me Link
            </CustomButton>
            <CustomText variant="body1">or</CustomText>
            <CustomButton
              variant="contained"
              onClick={() => handleCopyClipboard()}
            >
              Copy to clipboard
            </CustomButton>
          </Stack>
        </Box>
      )}

      {isRevision && (
        <Stack width={"100%"} mt={2}>
          <Stack direction={"row"} gap={2}>
            <Stack width={"30%"}>
              <CustomText variant="body1">Task Point</CustomText>
              <TaskPoint
                required
                size="small"
                select
                id="type_task"
                name="type_task"
                value={data?.type_task}
                onChange={handleChange}
              />
            </Stack>
            <Stack width={"70%"}>
              <CustomText variant="body1">Assigned To</CustomText>
              <Autocomplete
                options={assigneeList}
                noOptionsText="Type Worker Name..."
                fullWidth
                autoComplete
                getOptionLabel={(option: { username: string; email: string }) =>
                  option?.username
                }
                isOptionEqualToValue={(option, value) =>
                  option.username === value.username
                }
                filterOptions={filterOptions}
                loading={isLoading}
                open={open}
                value={selectedUser}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={handleSelect}
                onInputChange={(_, newInputValue) =>
                  handleInputChange(newInputValue)
                }
                id="assignee"
                componentName="assignee"
                renderInput={(params) => (
                  <CustomInput
                    {...params}
                    id="assignee"
                    name="assignee"
                    placeholder="Search Assignee"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <SearchIcon sx={{ color: theme?.inputText }} />
                      //   </InputAdornment>
                      // ),
                      endAdornment: (
                        <Fragment>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component={"li"}
                    {...props}
                    sx={{
                      backgroundColor: theme?.bgSecondary,
                      color: theme?.inputText,
                      "&.MuiAutocomplete-option.Mui-focused": {
                        backgroundColor: theme?.bgPrimary,
                      },
                    }}
                  >
                    <List sx={{ width: "100%" }}>
                      <ListItem>
                        <ListItemText
                          primary={<CustomText>{option?.username}</CustomText>}
                          secondary={
                            <Fragment>
                              <CustomText>{option?.email}</CustomText>
                            </Fragment>
                          }
                        />
                      </ListItem>
                      <Divider sx={{ borderBottomWidth: 4 }} />
                    </List>
                  </Box>
                )}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} mt={2} gap={2}>
            <Stack>
              <CustomText variant="body1">Start Date</CustomText>
              <InputDate
                id="start_date"
                name="start_date"
                onChange={handleChange}
                value={data?.start_date}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">End Date</CustomText>
              <InputDate
                id="end_date"
                name="end_date"
                onChange={handleChange}
                value={data?.end_date}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">Man Days</CustomText>
              <CustomInput
                required
                disabled
                size="small"
                id="main_day"
                name="main_day"
                onChange={handleChange}
                value={data?.main_day}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      <Stack mt={2}>
        <CustomText variant="body1">Notes</CustomText>
        <CustomInput
          size="small"
          id="notes"
          name="notes"
          onChange={handleChange}
          value={data?.notes}
        />
      </Stack>
      <Box mt={2} display={"flex"} justifyContent={"flex-end"} gap={2}>
        {isRevision ? (
          <>
            <CustomButton
              variant="outlined"
              customType="cancel"
              onClick={() => setIsRevision(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              disabled={data?.main_day < 1}
              onClick={() => setOpenModalRevision(true)}
            >
              Submit Revision
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              variant="contained"
              onClick={() => setIsRevision(true)}
            >
              Ask For Revision
            </CustomButton>

            <CustomButton
              variant="contained"
              onClick={() => setOpenModalApprove(true)}
            >
              Approve
            </CustomButton>
          </>
        )}
      </Box>
      <ModalConfirm
        text="Are you sure want to ask for revision for this item?"
        open={openModalRevision}
        onClose={() => setOpenModalRevision(false)}
        onSubmit={handleRevision}
      />
      <ModalConfirm
        text="Are you sure want to approve this task?"
        open={openModalApprove}
        onClose={() => setOpenModalApprove(false)}
        onSubmit={handleApprove}
      />
    </Box>
  );
}
