export const dataYearly = [
  { label: "Jan", idr: 100, usd: 100, total_order: 10 },
  { label: "Feb", idr: 200, usd: 200, total_order: 20 },
  { label: "Mar", idr: 250, usd: 250, total_order: 25 },
  { label: "Apr", idr: 400, usd: 400, total_order: 40 },
  { label: "May", idr: 500, usd: 500, total_order: 50 },
  { label: "Jun", idr: 550, usd: 550, total_order: 55 },
  { label: "Jul", idr: 700, usd: 700, total_order: 70 },
  { label: "Aug", idr: 650, usd: 650, total_order: 650 },
  { label: "Sep", idr: 900, usd: 900, total_order: 90 },
  { label: "Okt", idr: 1000, usd: 1000, total_order: 100 },
  { label: "Nov", idr: 1100, usd: 1100, total_order: 110 },
  { label: "Dec", idr: 1000, usd: 1000, total_order: 100 },
];

export const dataMonthly = [
  { label: "1", idr: 100, usd: 100, total_order: 10 },
  { label: "2", idr: 200, usd: 200, total_order: 20 },
  { label: "3", idr: 250, usd: 250, total_order: 25 },
  { label: "4", idr: 400, usd: 400, total_order: 40 },
  { label: "5", idr: 500, usd: 500, total_order: 50 },
  { label: "6", idr: 550, usd: 550, total_order: 55 },
  { label: "7", idr: 700, usd: 700, total_order: 70 },
  { label: "8", idr: 650, usd: 650, total_order: 65 },
  { label: "9", idr: 900, usd: 900, total_order: 90 },
  { label: "10", idr: 1000, usd: 1000, total_order: 100 },
  { label: "11", idr: 1100, usd: 1100, total_order: 110 },
  { label: "12", idr: 1000, usd: 1000, total_order: 100 },
  { label: "13", idr: 100, usd: 100, total_order: 100 },
  { label: "14", idr: 200, usd: 200, total_order: 20 },
  { label: "15", idr: 250, usd: 250, total_order: 25 },
  { label: "16", idr: 400, usd: 400, total_order: 40 },
  { label: "17", idr: 500, usd: 500, total_order: 50 },
  { label: "18", idr: 550, usd: 550, total_order: 55 },
  { label: "19", idr: 700, usd: 700, total_order: 70 },
  { label: "20", idr: 650, usd: 650, total_order: 65 },
  { label: "21", idr: 900, usd: 900, total_order: 90 },
  { label: "22", idr: 1000, usd: 1000, total_order: 100 },
  { label: "23", idr: 1100, usd: 1100, total_order: 110 },
  { label: "24", idr: 1000, usd: 1000, total_order: 100 },
  { label: "25", idr: 500, usd: 500, total_order: 50 },
  { label: "26", idr: 550, usd: 550, total_order: 55 },
  { label: "27", idr: 700, usd: 700, total_order: 70 },
  { label: "28", idr: 650, usd: 650, total_order: 65 },
  { label: "29", idr: 900, usd: 900, total_order: 90 },
  { label: "30", idr: 1000, usd: 1000, total_order: 100 },
];

export const dataWeekly = [
  { label: "Sun", idr: 100, usd: 100, total_order: 10 },
  { label: "Mon", idr: 100, usd: 100, total_order: 10 },
  { label: "Tue", idr: 200, usd: 200, total_order: 20 },
  { label: "Wed", idr: 250, usd: 250, total_order: 25 },
  { label: "Thu", idr: 400, usd: 400, total_order: 40 },
  { label: "Fri", idr: 500, usd: 500, total_order: 50 },
  { label: "Sat", idr: 550, usd: 550, total_order: 55 },
];

export const dataDaily = [
  { label: "01", idr: 100, usd: 200, total_order: 100 },
  { label: "02", idr: 200, usd: 100, total_order: 20 },
  { label: "03", idr: 250, usd: 250, total_order: 25 },
  { label: "04", idr: 400, usd: 400, total_order: 40 },
  { label: "05", idr: 500, usd: 500, total_order: 50 },
  { label: "06", idr: 550, usd: 550, total_order: 55 },
  { label: "07", idr: 700, usd: 700, total_order: 70 },
  { label: "08", idr: 650, usd: 650, total_order: 65 },
  { label: "09", idr: 900, usd: 900, total_order: 90 },
  { label: "10", idr: 1000, usd: 1000, total_order: 100 },
  { label: "11", idr: 1100, usd: 1100, total_order: 110 },
  { label: "12", idr: 1000, usd: 1000, total_order: 100 },
  { label: "13", idr: 100, usd: 100, total_order: 100 },
  { label: "14", idr: 200, usd: 200, total_order: 20 },
  { label: "15", idr: 250, usd: 250, total_order: 25 },
  { label: "16", idr: 400, usd: 400, total_order: 40 },
  { label: "17", idr: 500, usd: 500, total_order: 50 },
  { label: "18", idr: 550, usd: 550, total_order: 55 },
  { label: "19", idr: 700, usd: 700, total_order: 70 },
  { label: "20", idr: 650, usd: 650, total_order: 65 },
  { label: "21", idr: 900, usd: 900, total_order: 90 },
  { label: "22", idr: 1000, usd: 1000, total_order: 100 },
  { label: "23", idr: 1100, usd: 1100, total_order: 110 },
  { label: "24", idr: 1000, usd: 1000, total_order: 100 },
];
