import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLeaveDetail } from "src/api/user";
import CustomCard from "src/components/main/CustomCard";
import PageTitle from "src/components/main/CustomText/PageTitle";
import Leave from "src/components/main/LeaveDetail";

export default function Detail() {
  const navigate = useNavigate();
  const id = useLocation().pathname.split("/").pop();

  const [data, setData] = useState<any>({});

  useEffect(() => {
    const fetchLeaveDetail = async () => {
      try {
        const response = await GetLeaveDetail(id!);
        response?.data ? setData(response?.data) : setData([]);
      } catch (error: any) {
        console.error("Error fetching Leave detail:", error);
        navigate("/leave");
      }
    };

    fetchLeaveDetail();
  }, []);

  return (
    <Box>
      <PageTitle>Leave Detail</PageTitle>
      <CustomCard text="Leave Data">
        <Leave data={data} />
      </CustomCard>
    </Box>
  );
}
