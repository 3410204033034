import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Box, Card, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTable from "../../../components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { InquiryUserList } from "src/api/user";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import { userRole } from "src/utils/paramData";
import { USER_COLUMNS } from "src/constants/user";
import CustomInput from "src/components/main/CustomInput";
import { useDebouncedCallback } from "use-debounce";

function UserList() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const [userData, setUserData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const onClickDetail = (id: string) => {
    id === undefined ? navigate("/user") : navigate(`/user/${id}`);
  };

  const handleFilterChange = (name: string, value: any) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const fetchUser = async () => {
    const response = await InquiryUserList();
    response?.data?.length === 0 ? setUserData([]) : setUserData(response?.data);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const filterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handleFilterChange("key", value);
    const filtered = userData?.filter((item: any) => {
      return item?.username?.toLowerCase().includes(value.toLowerCase()) || item?.email?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const debouncedFilter = useDebouncedCallback(filterSearch, 1000);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e);
  };

  return (
    <Box>
      <PageTitle>User</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              User List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <CustomInput
              placeholder="Search"
              size="small"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme?.icon }} />
                  </InputAdornment>
                ),
              }}
            />
            <CustomButton
              variant="contained"
              onClick={() => navigate("/user/create")}
              startIcon={<AddIcon />}
            >
              Create User
            </CustomButton>
          </Box>
        </Box>
        <CustomTable
          columns={USER_COLUMNS}
          data={filter.key ? filteredData : userData}
          pagination={false}
          onClickDetail={onClickDetail}
          identifier={"id"}
          filter={filter}
          onChangeFilter={handleFilterChange}
        />
      </Card>
    </Box>
  );
}

export default UserList;
