export const PURCHASE_SHIPMENT_COLUMNS = [
  { title: "Shipment ID", field: "id", sortable: true },
  { title: "Order ID", field: "order_id", sortable: true },
  { title: "Item ID", field: "item_id", sortable: true },
  { title: "Shipment Price", field: "cost", sortable: true, align: "right" },
  { title: "Expedition", field: "expedition", sortable: true },
  { title: "Date", field: "created_at", sortable: true },
  { title: "Actions", field: "actions", sortable: false, align: "center" },
];

export const PURCHASE_ITEM_COLUMNS = [
  { title: "Purchase ID", field: "id", sortable: true },
  { title: "Item Name", field: "item_id", sortable: true },
  { title: "Price", field: "cost", sortable: true, align: "right" },
  { title: "Notes", field: "notes", sortable: false },
  { title: "Date", field: "created_at", sortable: true },
  { title: "Actions", field: "actions", sortable: false, align: "center" },
];
