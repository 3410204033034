import { Box, Card, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { GetCompanyData, updateInsentive } from "src/api/company";
import NotificationAlert from "src/components/main/Alert";
import CustomButton from "src/components/main/CustomButton";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import PageTitle from "src/components/main/CustomText/PageTitle";
import NewInputNominal from "src/components/main/NewInputNominal";
import { useLoadingStore } from "src/store/loadingStore";
import useThemeStore from "src/store/themeStore";

export default function InsentiveSettings() {
  const { isLoading } = useLoadingStore();
  const { theme } = useThemeStore();
  const [initialData, setInitialData] = useState<any>([]);
  const [levelList, setLevelList] = useState<any>([]);
  const [processedList, setProcessedList] = useState<any>([]);

  const fetchData = async () => {
    try {
      const response = await GetCompanyData();
      setInitialData(response?.data?.settinng);
      setLevelList(response?.data?.settinng);
    } catch (error: any) {
      console.error("Error fetching incentive setting:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    formatList();
  }, [levelList]);

  const handleChangeLevel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => {
    const { name, value } = e.target;
    setLevelList((prevData: any) =>
      prevData.map((item: any) =>
        item.id === id
          ? {
              ...item,
              [name]: value,
            }
          : item
      )
    );
  };

  const formatList = () => {
    const updatedList = levelList?.map((item: any, index: number) => {
      return {
        ...item,
        junior: (
          <NewInputNominal
            value={item?.junior?.toString()}
            onChange={(e) => handleChangeLevel(e, item?.id)}
            size="small"
            id={item?.id}
            isChanged={
              item?.junior?.toString() !==
              initialData[index]?.junior?.toString()
            }
            name="junior"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
          />
        ),
        middle: (
          <NewInputNominal
            value={item?.middle?.toString()}
            onChange={(e) => handleChangeLevel(e, item?.id)}
            size="small"
            id={item?.id}
            name="middle"
            isChanged={
              item?.middle?.toString() !==
              initialData[index]?.middle?.toString()
            }
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
          />
        ),
        senior: (
          <NewInputNominal
            value={item?.senior?.toString()}
            onChange={(e) => handleChangeLevel(e, item?.id)}
            size="small"
            id={item?.id}
            name="senior"
            isChanged={
              item?.senior?.toString() !==
              initialData[index]?.senior?.toString()
            }
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
          />
        ),
      };
    });

    setProcessedList(updatedList);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const req = {
      setting: levelList,
    };

    await updateInsentive(req);
    fetchData();
  };

  return (
    <Box>
      <PageTitle>Incentive Settings</PageTitle>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Card>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            borderBottom={`1px solid ${theme?.border}`}
            alignContent={"center"}
            p={2}
          >
            <Box sx={{ display: "inline-block", alignContent: "center" }}>
              <CustomText variant="subtitle1" fontWeight={700} align="center">
                Incentive Level List
              </CustomText>
            </Box>
          </Box>

          <CustomTable
            columns={columns}
            data={processedList}
            pagination={false}
            sortable={false}
          />
        </Card>
        <Box mt={4} display={"flex"} width={"100%"} justifyContent="center">
          <CustomButton variant="contained" type="submit">
            Save Incentive Settings
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}

const columns = [
  {
    title: "Level",
    field: "level_worker",
    sortable: false,
  },
  {
    title: "Junior",
    field: "junior",
    sortable: false,
    align: "left",
  },
  {
    title: "Middle",
    field: "middle",
    sortable: false,
    align: "left",
  },
  {
    title: "Senior",
    field: "senior",
    sortable: false,
    align: "left",
  },
];
