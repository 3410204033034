import { Box, Card, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "src/components/main/CustomButton";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import AddIcon from "@mui/icons-material/Add";
import { DecimalFormatter } from "src/utils/formatter";
import { getCurrencySymbol } from "src/utils/paramData";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PageTitle from "src/components/main/CustomText/PageTitle";
import useThemeStore from "src/store/themeStore";
import { PURCHASE_ITEM_COLUMNS } from "src/constants/purchase";
import { deletePurchaseItem, inquiryPurchaseItemList } from "src/api/purchase";
import { useFilterStore } from "src/store/filterStore";
import useMount from "src/hooks/useMount";
import ModalConfirm from "src/components/main/ModalConfirm";

export default function PurchasingReimbursement() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const [data, setData] = useState<PurchaseItem[]>([]);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>("");

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
  });

  const handleFilterChange = (name: string, value: any) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const onClickDetail = (id: string) => {
    navigate(`/purchase/purchasing/${id}`);
  };

  const fetchData = async () => {
    const req: PurchaseFilter = {
      order_key: filter.order_key,
      order_type: filter.sort_type,
      page: filter.page,
      limit: filter.limit,
      start_date: "",
      end_date: "",
      user_id: [],
      order_id: [],
      item_id: [],
      id: "",
    };
    const response = await inquiryPurchaseItemList(req);
    setData(formatData(response?.data?.data));
    handleFilterChange("page", response?.data?.current_page);
    handleFilterChange("total_page", response?.data?.total_pages);
  };

  useEffect(() => {
    fetchData();
  }, [
    filter.key,
    filter.order_key,
    filter.sort_type,
    filter.start_date,
    filter.end_date,
    filter.page,
    filter.limit,
  ]);

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    setOpenModalDelete(true);
    setSelectedData(id);
  };

  const onCloseModal = () => {
    setOpenModalDelete(false);
    setSelectedData("");
  };

  const onDelete = async () => {
    await deletePurchaseItem(selectedData);
    onCloseModal();
    fetchData();
  };

  const formatData = (data: PurchaseItem[]) => {
    const processedData = data?.map((item: PurchaseItem) => {
      return {
        ...item,
        cost: `${getCurrencySymbol(item?.currency) || "Rp"} ${DecimalFormatter(
          item?.cost?.toString()
        )}`,
        created_at: item?.created_at?.split("T")[0],
        actions: (
          <IconButton
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255,255,255, 0.2)",
              },
            }}
            onClick={(e) => handleDelete(e, item?.id)}
          >
            <DeleteOutlineOutlinedIcon color="error" />
          </IconButton>
        ),
      };
    });

    return processedData;
  };

  return (
    <Box>
      <PageTitle>Purchasing</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Purchasing List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <CustomButton
              variant="contained"
              onClick={() => navigate("/purchase/purchasing/create")}
              startIcon={<AddIcon />}
            >
              Create Purchase
            </CustomButton>
          </Box>
        </Box>
        <CustomTable
          columns={PURCHASE_ITEM_COLUMNS}
          data={data}
          onClickDetail={onClickDetail}
          sortable={false}
          filter={filter}
          onChangeFilter={handleFilterChange}
        />
      </Card>
      <ModalConfirm
        text="Are you sure you want to delete this purchase?"
        open={openModalDelete}
        onSubmit={onDelete}
        onClose={onCloseModal}
      />
    </Box>
  );
}
