import {
  Box,
  Stack,
  MenuItem,
  FormControlLabel,
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import { getCurrentTime } from "src/utils/formatter";
import { Ekspedisi } from "src/utils/paramData";
import { Fragment, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { UploadResiAdmin } from "src/api/item";
import CustomDropbox from "../CustomDropbox";
import ModalConfirm from "../ModalConfirm";
import CustomFormGroup from "../CustomFormGroup";
import CustomCheckbox from "../CustomCheckbox";
import { useLoadingStore } from "src/store/loadingStore";

interface Props {
  detailItem: DetailItem;
  refetch: () => void;
}
export default function ShippingFromClient({ detailItem, refetch }: Props) {
  const [files, setFiles] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const id = useLocation().pathname.split("/").pop();
  const { showNotification } = useLoadingStore();
  const filter = createFilterOptions();

  const handleFileUpload = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDelete = (fileName: string) => {
    setFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  const handleSubmit = async () => {
    const form = document.querySelector("form"); // Get the form element
    if (!form) return;

    const data = new FormData(form);

    // Check if both base and leather from client, get the value base on user input. If only one of them is from the client, so it will automatically set value to 1
    let base = 0;
    let leather = 0;

    if (detailItem?.BaseFromClient) {
      if (detailItem?.LeatherFromClient) {
        base = data.get("base") === "on" ? 1 : 0;
        leather = data.get("leather") === "on" ? 1 : 0;
      } else {
        base = 1;
      }
    }

    if (!detailItem?.BaseFromClient && detailItem?.LeatherFromClient) {
      leather = 1;
    }
    
    const req = {
      item_id: id,
      nomor_resi: data.get("nomor_resi"),
      expedition: data.get("expedition"),
      base: base,
      leather: leather,
      files: files,
    };

    if (req.base === 0 && req.leather === 0) {
      showNotification("Please select at least one shipping material", "error");
      return;
    }

    if (files.length === 0) {
      showNotification("Please upload shipment proof", "error");
      return;
    }

    await UploadResiAdmin(req);

    await refetch();
    setOpenModal(false);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (files.length === 0) {
      showNotification("Please upload proof of client shipment", "error");
      return;
    }
    setOpenModal(true); // Open the modal on form submit
  };

  return (
    <Box component={"form"} onSubmit={handleFormSubmit} sx={{ width: "50%" }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            {detailItem?.BaseFromClient && detailItem?.LeatherFromClient
              ? "Base and Leather Shipment From Client"
              : detailItem?.LeatherFromClient
              ? "Leather Shipment From Client"
              : "Base Shipment From Client"}
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Stack mt={2} spacing={2}>
          <Stack>
            <CustomText variant="body1">Nomor Resi</CustomText>
            <CustomInput
              size="small"
              id="nomor_resi"
              name="nomor_resi"
              required
            />
          </Stack>
          <Stack>
            <CustomText variant="body1">Expedition</CustomText>
            <Autocomplete
              filterOptions={(
                options: any[],
                params: FilterOptionsState<any>
              ) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: inputValue,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              autoComplete
              id="expedition"
              options={Ekspedisi}
              getOptionLabel={(option: any) => {
                return option.value;
              }}
              renderOption={(props, option: any) => (
                <li {...props}>{option.value}</li>
              )}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  required
                  size="small"
                  id="expedition"
                  name="expedition"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>{params.InputProps.endAdornment}</Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
          {detailItem?.BaseFromClient && detailItem?.LeatherFromClient ? (
            <Stack>
              <CustomText variant="body1">Shipping Material</CustomText>
              <CustomFormGroup>
                <Stack direction={"row"}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        defaultChecked={Boolean(detailItem?.ItemResi)}
                      />
                    }
                    label="Base"
                    id="base"
                    name="base"
                    disabled={Boolean(detailItem?.ItemResi)}
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        defaultChecked={Boolean(detailItem?.LetaherResi)}
                      />
                    }
                    label="Leather"
                    id="leather"
                    name="leather"
                    disabled={Boolean(detailItem?.LetaherResi)}
                  />
                </Stack>
              </CustomFormGroup>
            </Stack>
          ) : (
            <Stack>
              <CustomText variant="body1">Shipping Material</CustomText>
              <CustomFormGroup>
                <Stack direction={"row"}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={detailItem?.BaseFromClient} />
                    }
                    label="Base"
                    id="base"
                    name="base"
                    disabled
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox checked={detailItem?.LeatherFromClient} />
                    }
                    label="Leather"
                    id="leather"
                    name="leather"
                    disabled
                  />
                </Stack>
              </CustomFormGroup>
            </Stack>
          )}
        </Stack>
        <Stack mt={2}>
          <CustomText variant="body1">Proof of Shipment</CustomText>
          <Box
            mt={1}
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={"row"}
          >
            <CustomDropbox
              size={150}
              files={files}
              onUpload={handleFileUpload}
              onDelete={handleDelete}
            />
          </Box>
        </Stack>

        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure you want to submit this data?"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
