import { SxProps, Divider, DividerProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomDividerProps extends Omit<DividerProps, "variant"> {
  sx?: SxProps;
}

const CustomDivider: React.FC<CustomDividerProps> = (props) => {
  const { sx, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <Divider
      color={theme?.border}
      sx={{
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CustomDivider;
