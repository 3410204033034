import { Box, Divider } from "@mui/material";
import React from "react";
import BreadCrumbs from "src/components/navigators/BreadCrumb";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import Detail from "./components/Details";
import ShippingAddress from "./components/ShippingAddress";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import CustomText from "src/components/main/CustomText";
import PageTitle from "src/components/main/CustomText/PageTitle";
import {  useState } from "react";
import ModalConfirm from "src/components/main/ModalConfirm";


function CustomerDetail() {
  const [value, setValue] = React.useState(0);
  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {

    if (isEdit){
      setOpenModal(true)
      setNumberPages(newValue)
    }else {
      setValue(newValue);
    }
  };

  const handleSubmit = () => {
    setValue(numberPages)
    setIsEdit(false)
  }
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openModal,setOpenModal] = useState<boolean>(false);
  const [numberPages,setNumberPages]  = useState<number>(0);

  return (
    <Box>
      <PageTitle>Customer Details</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Customer Data" />
        <CustomTab label="Shipping Address" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <Detail isEdit= {isEdit} setIsEdit={setIsEdit}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ShippingAddress />
      </CustomTabPanel>
      <ModalConfirm text="Change Data Will Be Lost, Are You Sure to Leave Edit Data ?" open={openModal} onClose={() => setOpenModal(false)} onSubmit={handleSubmit}/>
        
    </Box>
  );
}

export default CustomerDetail;
