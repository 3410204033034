import {
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import useThemeStore from "src/store/themeStore";
import { hexToRgba } from "src/utils/utils";
import CustomCheckbox from "../CustomCheckbox";

interface Props {
  tableData: any[];
  columns: any[];
  onClickDetail?: (id: string) => void;
  identifier?: string;
  sticky: boolean;
  checkbox?: boolean;
  onCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  selectedItem?: Array<string | number>;
}

const CustomTableBody = ({
  tableData,
  columns,
  onClickDetail,
  identifier = "id",
  sticky,
  checkbox = false,
  onCheckboxChange,
  selectedItem,
}: Props) => {
  const { theme } = useThemeStore();

  const calculateMinWidth = (column: any) => {
    const titleLength = column.title?.length || 0;
    const padding = 20;
    const iconWidth = 20;

    return Math.max(titleLength * 8 + padding * 2 + iconWidth, iconWidth * 2);
  };

  return (
    <TableBody>
      {tableData?.map((data, rowIndex) => (
        <TableRow
          key={rowIndex}
          onClick={() => onClickDetail?.(identifier && data[identifier])}
          sx={{
            cursor: "pointer",
            "&:hover": { backgroundColor: hexToRgba(theme?.tableText, 0.1) },
          }}
        >
          {checkbox && (
            <TableCell
              onClick={(event) => event.stopPropagation()}
              sx={{
                color: theme?.inputText,
                borderColor: hexToRgba(theme?.tableDivider, 0.5),
                textAlign: "center",
                p: 0,
              }}
            >
              <CustomCheckbox
                checked={selectedItem?.includes(identifier && data[identifier])}
                onChange={(event) => onCheckboxChange?.(event, (identifier && data[identifier]))}
              />
            </TableCell>
          )}
          {columns?.map(({ field, align, minWidth }, index) => (
            <TableCell
              key={field}
              sx={{
                position: index === 0 && sticky ? "sticky" : "",
                backgroundColor: index === 0 && sticky ? theme?.bgPaper : "",
                left: index === 0 && sticky ? 0 : "",
                color: theme?.inputText,
                borderColor: hexToRgba(theme?.tableDivider, 0.5),
                width: minWidth
                  ? minWidth
                  : calculateMinWidth(
                      columns.find((col) => col.field === field)
                    ),
                paddingRight:
                  align === "right" ? "16px" : align === "left" ? 0 : "16px",
                paddingLeft:
                  align === "right" ? 0 : align === "left" ? "16px" : "16px",
                textAlign: align ? align : "left",
                textWrap: "wrap",
                // wordWrap: "break-word",
              }}
            >
              {data[field]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default CustomTableBody;
