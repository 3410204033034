import {
  Box,
  Chip,
  Grid,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import colors from "src/themes/colors";
import CustomText from "src/components/main/CustomText";
import CustomAccordion from "src/components/main/CustomAccordion";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import NotificationAlert from "src/components/main/Alert";
import { GetTaskDetail } from "src/api/task";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import useThemeStore from "src/store/themeStore";
import DeleteIcon from "@mui/icons-material/Delete";
import { getDayLeft } from "src/utils/formatter";
import { DropBox } from "src/components/main/Dropbox";
import { getItemLogAttachment } from "src/api/item";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomCard from "src/components/main/CustomCard";

export function UploadDesignItem() {
  const id = useLocation().pathname.split("/").pop();
  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const [data, setData] = useState<any>({});
  const [files, setFiles] = useState<any>([]);
  const [designItem, setDesignItem] = useState<any>([]);
  const [itemAttachment, setItemAttachment] = useState<any>("");

  const fetchData = async () => {
    try {
      ShowLoading({});
      const response = await GetTaskDetail(id!);
      response?.data ? setData(response?.data) : setData([]);
      setDesignItem(response?.data?.design_photo);
    } catch (error: any) {
      console.error("Error fetching task detail:", error);
      NotificationAlert({ message: error.message, status: "error" });
      navigate("/task/task-management");
    } finally {
      HideLoading();
    }
  };

  const fetchItemAttachment = async (id: string) => {
    try {
      ShowLoading({});
      const response = await getItemLogAttachment(id);
      response?.data === null
        ? setDesignItem([])
        : setDesignItem(response?.data);
    } catch (error: any) {
      console.error("Error upload design:", error);
    } finally {
      HideLoading();
      console.log(designItem);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileUpload = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDelete = (fileName: string) => {
    setFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  return (
    <Box>
      <PageTitle>{["Task", id!].join(" ")}</PageTitle>
      <CustomCard text="Task Detail">
        <Box>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            spacing={4}
          >
            <Grid item sm={6} md={2} textAlign={"left"}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <CustomText variant="subtitle1" gutterBottom>
                  Created
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  Item ID
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  Assigned To
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  Deadline
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  Task Points
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  Priority Level
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  Label
                </CustomText>
              </Box>
            </Grid>
            <Grid item sm={6} md={10}>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <CustomText variant="subtitle1" gutterBottom>
                  {data?.created_at?.split("T")[0]}
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  {data?.item_id}
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  {data?.display_name}
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  {`${data?.due_date?.split("T")[0]} (${getDayLeft(
                    data?.due_date?.split("T")[0]
                  )} Day Left)`}
                </CustomText>
                <CustomText variant="subtitle1" gutterBottom>
                  {data?.task_type}
                </CustomText>
                <Box alignItems={"center"} marginBottom={"5px"}>
                  <Chip
                    label={data?.priority?.toUpperCase()}
                    sx={{
                      backgroundColor: colors?.primary200,
                      borderRadius: 50,
                    }}
                  />
                </Box>
                <Box alignItems={"center"}>
                  <Chip
                    label={
                      data?.type === "RECONER"
                        ? "RECON"
                        : data?.type === "DESIGN"
                        ? "DESIGN"
                        : "PAINTING"
                    }
                    sx={{
                      backgroundColor: colors?.primary200,
                      borderRadius: 50,
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box width={"80%"} marginLeft={"auto"} marginRight={"auto"} mt={4}>
          <CustomAccordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1-content"
            >
              <CustomText>Upload Design Item</CustomText>
            </AccordionSummary>
            <AccordionDetails sx={{ background: theme?.bgSecondary }}>
              <Box
                mt={4}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                {designItem?.length > 0 &&
                  designItem?.map((item: any, index: number) => (
                    <ImageListItem
                      key={index}
                      sx={{ marginRight: 2, width: 200, height: 200 }}
                    >
                      <img
                        src={
                          item?.url_foto ||
                          "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
                        }
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                {files?.length > 0 &&
                  files?.map((item: any, index: number) => (
                    <ImageListItem
                      key={index}
                      sx={{ marginRight: 2, width: 200, height: 200 }}
                    >
                      <img
                        src={
                          URL.createObjectURL(item) ||
                          "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
                        }
                        loading="lazy"
                      />
                      <ImageListItemBar
                        position="top"
                        actionIcon={
                          <IconButton
                            sx={{ color: theme?.inputLabel }}
                            aria-label={`info about ${item.title}`}
                          >
                            <DeleteIcon
                              onClick={() => handleDelete(item?.name)}
                            />
                          </IconButton>
                        }
                        actionPosition="left"
                      />
                    </ImageListItem>
                  ))}
                {!files || files?.length + designItem?.length < 5 ? (
                  <DropBox
                    sx={{ width: 150, height: 150 }}
                    onDrop={(file) => handleFileUpload(file)}
                  />
                ) : (
                  ""
                )}
              </Box>
            </AccordionDetails>
          </CustomAccordion>
        </Box>
      </CustomCard>
    </Box>
  );
}
