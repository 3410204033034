import { Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import HeaderFinanceReport from "./components/HeaderFinanceReport";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomButton from "src/components/main/CustomButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import useThemeStore from "src/store/themeStore";

export default function FinanceDebt() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const onClickDetail = () => {
    navigate("/finance/monthly-recap/detail");
  };

  const columns = [
    { title: "Period", field: "period", sortable: true, minWidth: 80 },
    {
      title: "Cash (IDR)",
      field: "idr",
      sortable: true,
      align: "right",
      minWidth: 80,
    },
    {
      title: "BCA (IDR)",
      field: "bca",
      sortable: true,
      align: "right",
      minWidth: 80,
    },
    {
      title: "Paypal (USD)",
      field: "usd",
      sortable: true,
      align: "right",
      minWidth: 80,
    },
    {
      title: "Crypto (USD)",
      field: "crypto",
      sortable: true,
      align: "right",
      minWidth: 80,
    },
    {
      title: "Total (IDR)",
      field: "total_idr",
      sortable: true,
      align: "right",
      minWidth: 80,
    },
    {
      title: "Total (USD)",
      field: "total_usd",
      sortable: true,
      align: "right",
      minWidth: 80,
    },
    { title: "Status Report", field: "status", sortable: true, minWidth: 80 },
  ];

  const data = [
    {
      period: "January 2024",
      idr: 1000000,
      bca: 1000000,
      usd: 4363,
      crypto: 53,
      total_idr: 2000000,
      total_usd: 30000,
      itemCost: 1000000,
      salaryCost: 1000000,
      profit_idr: 2500000,
      profit_usd: 250000,
      status: "Ongoing",
    },
    {
      period: "February 2024",
      idr: 1000000,
      bca: 1000000,
      usd: 4363,
      crypto: 53,
      total_idr: 2000000,
      total_usd: 30000,
      itemCost: 1000000,
      salaryCost: 1000000,
      profit_idr: 2500000,
      profit_usd: 250000,
      status: "Ongoing",
    },
  ];

  return (
    <Box>
      <PageTitle>Finance Debt</PageTitle>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Finance Debt List
            </CustomText>
          </Box>
          <Box
            display={"flex"}
            gap={2}
            justifyContent={"space-between"}
            alignContent={"center"}
          >
            <CustomButton variant="contained" startIcon={<IosShareIcon />}>
              Export to Excel
            </CustomButton>
          </Box>
        </Box>

        <CustomTable
          columns={columns}
          data={data}
          stickyFirst={true}
          onClickDetail={onClickDetail}
        />
      </Card>
    </Box>
  );
}
