import { Box, FormControlLabel, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import { useLocation } from "react-router-dom";
import { itemQCAdminFromCustomer, UpdateItemLog } from "src/api/item";
import ModalConfirm from "../ModalConfirm";
import CustomInput from "../CustomInput";
import CustomDropbox from "../CustomDropbox";
import { getCurrentTime } from "src/utils/formatter";
import CustomFormGroup from "../CustomFormGroup";
import CustomCheckbox from "../CustomCheckbox";

interface Props {
  detailItem: any;
  refetch: () => void;
}
export default function ArrivalConfirmation({ detailItem, refetch }: Props) {
  const id = useLocation().pathname.split("/").pop();

  const [baseFiles, setBaseFiles] = useState<any>([]);
  const [leatherFiles, setLeatherFiles] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [openModalSubmit, setOpenModalSubmit] = useState<boolean>(false);
  const [openModalComplete, setOpenModalComplete] = useState<boolean>(false);

  const handleFileUploadBase = useCallback((acceptedFiles: File[]) => {
    setBaseFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDeleteBase = (fileName: string) => {
    setBaseFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  const handleFileUploadLeather = useCallback((acceptedFiles: File[]) => {
    setLeatherFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDeleteLeather = (fileName: string) => {
    setLeatherFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "base" || name === "leather") {
      setData((prevData: any) => ({
        ...prevData,
        [name]: event.target.checked,
      }));
    } else {
      setData((prevData: any) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    const req: CompleteStep = {
      item_id: id!,
      status: "COMPLETED",
      status_desc:
        "Item From Customer Received at Workshop Tangerang, " + data?.notes,
      attachment: baseFiles,
      attachLeather: leatherFiles,
      notes: data?.notes,
      enum_status: "COMPLETED",
      base: data?.base ? 1 : 0,
      leather: data?.leather ? 1 : 0,
    };

    await itemQCAdminFromCustomer(req);
    await refetch();

    setOpenModalSubmit(false);
  };

  const handleComplete = async () => {
    const req: CompleteStep = {
      item_id: id!,
      status: "COMPLETED",
      status_desc: "Item From Customer Received at Workshop Tangerang",
      attachment: baseFiles,
      notes: data?.notes,
      enum_status: "COMPLETED",
    };

    await UpdateItemLog(req);
    await refetch();

    setOpenModalSubmit(false);
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalSubmit(true);
  };

  const getMaterial = () => {
    if (detailItem?.ItemImage && detailItem?.LeatherImage) {
      return "Base & Leather";
    } else if (detailItem?.ItemImage) {
      return "Base";
    } else if (detailItem?.LeatherImage) {
      return "Leather";
    }
  };
  
  return (
    <Box
      sx={{ width: { xs: "70%", md: "60%", lg: "50%" } }}
      component={"form"}
      onSubmit={handleSubmitForm}
    >
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            {`Confirmation ${getMaterial()} Arrival at Workshop Tangerang`}
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Box mt={2}>
          <CustomText variant="body1">
            Upload image taken by logistic team (optional)
          </CustomText>
          <Stack mt={2}>
            <CustomText variant="body1">Notes</CustomText>
            <CustomInput
              size="small"
              id="notes"
              name="notes"
              onChange={handleChange}
              value={data?.notes || ""}
            />
          </Stack>
          {detailItem?.BaseFromClient && detailItem?.LeatherFromClient && (
            <Stack mt={2}>
              <CustomText variant="body1">Shipping Material</CustomText>
              <CustomFormGroup>
                <Stack direction={"row"}>
                  <FormControlLabel
                    control={
                      <CustomCheckbox defaultChecked={detailItem?.ItemImage} />
                    }
                    label="Base"
                    id="base"
                    name="base"
                    onChange={handleChange}
                    disabled
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckbox
                        defaultChecked={detailItem?.LeatherImage}
                      />
                    }
                    label="Leather"
                    id="leather"
                    name="leather"
                    onChange={handleChange}
                    disabled
                  />
                </Stack>
              </CustomFormGroup>
            </Stack>
          )}
          {(data?.base || detailItem?.BaseFromClient) && (
            <Stack mt={2}>
              <CustomText variant="body1">Base Photo</CustomText>
              <Box
                mt={1}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <CustomDropbox
                  id="baseInput"
                  size={150}
                  files={baseFiles}
                  existingFiles={detailItem?.ItemImage || []}
                  viewOnly={detailItem?.ItemImage}
                  onDelete={handleDeleteBase}
                  onUpload={handleFileUploadBase}
                />
              </Box>
            </Stack>
          )}
          {(data?.leather || detailItem?.LeatherFromClient) && (
            <Stack mt={2}>
              <CustomText variant="body1">Leather Photo</CustomText>
              <Box
                mt={1}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <CustomDropbox
                  id="leatherInput"
                  size={150}
                  files={leatherFiles}
                  existingFiles={detailItem?.LeatherImage || []}
                  viewOnly={detailItem?.LeatherImage}
                  onDelete={handleDeleteLeather}
                  onUpload={handleFileUploadLeather}
                />
              </Box>
            </Stack>
          )}
        </Box>
        <Box mt={2} display={"flex"} justifyContent={"flex-end"} gap={2}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
          {/* <CustomButton
            variant="contained"
            onClick={() => setOpenModalComplete(true)}
          >
            Complete
          </CustomButton> */}
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure you want to submit?"
        open={openModalSubmit}
        onClose={() => setOpenModalSubmit(false)}
        onSubmit={handleSubmit}
      />
      <ModalConfirm
        text="Are you sure you want to complete this step?"
        open={openModalComplete}
        onClose={() => setOpenModalComplete(false)}
        onSubmit={handleComplete}
      />
    </Box>
  );
}
