export const roleData = [
  {
    id: 1,
    title: "Dashboard",
    menu: "/",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 2,
    title: "Order",
    menu: "/order",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 3,
    title: "Item",
    menu: "/item",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 4,
    title: "Task",
    menu: "/task",
    hasSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 6,
    title: "User",
    menu: "/user",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 7,
    title: "Customer",
    menu: "/customer",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 8,
    title: "Finance",
    menu: "/finance",
    hasSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 9,
    title: "Finance",
    subtitle: "Monthly Recap Finance",
    menu: "/finance/monthly-recap",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 10,
    title: "Finance",
    subtitle: "Salary",
    menu: "/finance/salary",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 11,
    title: "Finance",
    subtitle: "Incentive",
    menu: "/finance/incentive",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 12,
    title: "Company",
    menu: "/company",
    hasSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 13,
    title: "Company",
    subtitle: "Company Settings",
    menu: "/company/company-settings",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 14,
    title: "Company",
    subtitle: "Incentive Settings",
    menu: "/company/incentive-settings",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 15,
    title: "Task",
    subtitle: "Task List",
    menu: "/task/task-list",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 16,
    title: "Task",
    subtitle: "Task Management",
    menu: "/task/task-management",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 17,
    title: "Leave",
    menu: "/leave",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 18,
    title: "Reimbursement",
    menu: "/reimbursement",
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 19,
    title: "Purchase",
    menu: "/purchase",
    hasSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 20,
    title: "Purchase",
    subtitle: "Shipment",
    menu: "/purchase/shipment",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 21,
    title: "Purchase",
    subtitle: "Purchasing",
    menu: "/purchase/purchasing",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
  {
    id: 22,
    title: "Company",
    subtitle: "Collective Leave",
    menu: "/company/collective-leave",
    isSubmenu: true,
    permissions: ["crete", "read", "update", "delete"],
  },
];
