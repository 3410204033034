import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetLeaveDetailByUser } from "src/api/user";
import NotificationAlert from "src/components/main/Alert";
import CustomCard from "src/components/main/CustomCard";
import CustomText from "src/components/main/CustomText";
import LeaveDetail from "src/components/main/LeaveDetail";

export default function Leave() {
  const id = useLocation().pathname.split("/").pop();
  const [formData, setFormData] = useState<any>([]);

  useEffect(() => {
    const fetchLeaveDetail = async () => {
      try {
        const response = await GetLeaveDetailByUser(id!);
        response?.data ? setFormData(response?.data) : setFormData([]);
      } catch (error: any) {
        console.error("Error fetching Leave detail:", error);
        NotificationAlert({ message: error.message, status: "error" });
      }
    };

    fetchLeaveDetail();
  }, []);

  return (
    <Box>
      {formData &&
        formData.map((data: any, index: number) => (
          <CustomCard key={index} text={`Leave Request ${index + 1}`}>
            <LeaveDetail data={data} />
          </CustomCard>
        ))}
      {formData.length === 0 && (
        <CustomText variant="body1">No Leave Request</CustomText>
      )}
    </Box>
  );
}
