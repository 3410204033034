import { Box, Card, Grid, Stack } from "@mui/material";
import { add } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addCollectiveLeave } from "src/api/company";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import PageTitle from "src/components/main/CustomText/PageTitle";
import InputDate from "src/components/main/Datepicker";
import BreadCrumbs from "src/components/navigators/BreadCrumb";

export default function AddLeave() {
  const navigate = useNavigate();
  const [data, setData] = useState<CollectiveLeave>({} as CollectiveLeave);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await addCollectiveLeave(data);
    navigate("/company/collective-leave");
  };

  const totalDay = Math.floor((new Date(data?.end_date).getTime() - new Date(data?.start_date).getTime()) / (1000 * 60 * 60 * 24)) + 1;

  return (
    <Box>
      <PageTitle>Add Leave</PageTitle>
      <Box component={"form"} onSubmit={handleSubmit}>
        <CustomCard text="Leave Data">
          <Grid
            container
            spacing={4}
            direction={"row"}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item sm={12} md={6} lg={3}>
              <Stack>
                <CustomText variant="body1">Title Leave</CustomText>
                <CustomInput
                  size="small"
                  name="title_leave"
                  id="title_leave"
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Stack>
                <CustomText variant="body1">Start Date</CustomText>
                <InputDate
                  name="start_date"
                  id="start_date"
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Stack>
                <CustomText variant="body1">End Date</CustomText>
                <InputDate
                  name="end_date"
                  id="end_date"
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Stack>
                <CustomText variant="body1">Total Leave</CustomText>
                <CustomInput size="small" name="total" id="total" disabled value={totalDay || 0} />
              </Stack>
            </Grid>
          </Grid>
        </CustomCard>

        <Box mt={4} display={"flex"} gap={2} justifyContent={"flex-end"}>
          <CustomButton
            onClick={() => navigate("/company/collective-leave")}
            variant="outlined"
            customType="cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton variant="contained" type="submit" disabled={totalDay <= 0}>
            Save
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}
