import { Box, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import BreadCrumbs from "src/components/navigators/BreadCrumb";
import Detail from "./components/Detail";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import CustomText from "src/components/main/CustomText";
import CustomButton from "src/components/main/CustomButton";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import Leave from "./components/Leave";
import PageTitle from "src/components/main/CustomText/PageTitle";

export default function UserDetail() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <PageTitle>User Detail</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Detail" />
        <CustomTab label="Leave" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <Detail />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Leave />
      </CustomTabPanel>
    </Box>
  );
}
