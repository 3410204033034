import {
  Box,
  Grid,
  InputAdornment,
  Stack,
} from "@mui/material";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import React, { useEffect, useState } from "react";
import { GetCompanyData, UpdateCompanyData } from "src/api/company";
import NotificationAlert from "src/components/main/Alert";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomCard from "src/components/main/CustomCard";

function CompanyDetail() {
  const { theme } = useThemeStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [levelList, setLevelList] = useState<any>([]);
  const [address, setAddress] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await GetCompanyData();
        setData(response?.data?.company);
        setLevelList(response?.data?.settinng);
        setAddress(response?.data?.address);
      } catch (error: any) {
        console.error("Error fetching company data:", error);
        NotificationAlert({ message: error.message, status: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleChangeLevel = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string
  ) => {
    const { name, value } = e.target;
    setLevelList((prevData: any) =>
      prevData.map((item: any) =>
        item.id === id
          ? {
              ...item,
              [name]: value,
            }
          : item
      )
    );
  };

  const handleChangeAddress = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "no_telp") {
      newValue = value.replace(/[^0-9]/g, "");
    }
    const newData = [...address];
    newData[index] = {
      ...newData[index],
      [name]: newValue,
    };

    setAddress(newData);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const req = {
      company: data,
      setting: levelList,
      address: address,
    };

    try {
      const response = await UpdateCompanyData(req);
      NotificationAlert({
        message: response?.data?.message,
        status: "success",
      });
    } catch (error: any) {
      console.error("Error updating company data:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  return (
    <Box>
      <PageTitle>Company Settings</PageTitle>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item sm={6}>
            <CustomCard text="Company Setting" sx={{ minHeight: 300, mt: 0, mb: 2 }}>
              <Stack my={"10px"}>
                <CustomText variant="body1">Company Name</CustomText>
                <CustomInput
                  size="small"
                  name="company_name"
                  id="company_name"
                  value={data?.company_name}
                  onChange={handleChangeData}
                />
              </Stack>
              <Stack my={"10px"}>
                <CustomText variant="body1">Company Email</CustomText>
                <CustomInput
                  size="small"
                  name="company_email"
                  id="company_email"
                  value={data?.company_email}
                  onChange={handleChangeData}
                />
              </Stack>
            </CustomCard>
          </Grid>

          {address?.map((item: any, index: number) => (
            <Grid item sm={6} key={index}>
              <CustomCard text={item?.Name} sx={{ minHeight: 300, mt: 0, mb: 2 }}>
                <Stack mt={"15px"}>
                  <CustomText variant="body1">Address</CustomText>
                  <CustomInput
                    size="small"
                    name="address"
                    id="address"
                    value={item?.address}
                    multiline
                    rows={3}
                    onChange={(e) => handleChangeAddress(e, index)}
                  />
                </Stack>
                <Stack mt={"15px"}>
                  <CustomText variant="body1">Phone Number</CustomText>

                  <CustomInput
                    size="small"
                    id="no_telp"
                    name="no_telp"
                    value={item?.no_telp}
                    multiline
                    onChange={(e) => handleChangeAddress(e, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+</InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </CustomCard>
            </Grid>
          ))}
        </Grid>
        <Box mt={2} display={"flex"} width={"100%"} justifyContent="flex-end">
          <CustomButton variant="contained" type="submit">
            Save Company Setting
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CompanyDetail;
