import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import { sendRequestGET, sendRequestPOST } from "../sendRequest";
import NotificationAlert from "src/components/main/Alert";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import { useLoadingStore } from "src/store/loadingStore";

const { showLoading, hideLoading, showNotification } = useLoadingStore.getState();
interface ChartFilter {
  filter: string;
  week: number;
  month: number;
  year: number;
}

export const GetChartData = async (
  { filter, week, month, year }: ChartFilter = {
    filter: "weekly",
    week: 0,
    month: 1,
    year: 2024,
  }
) => {
  
  const url = process.env.REACT_APP_ENDPOINT_CHART_DATA || "";
  const userStore = useUserStore.getState();
  const menu_id = FindMenuID(userStore.menu_mapping, "/");

  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    filter: filter,
    week: week,
    month: month,
    year: year,
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);

    console.info("ACTION CHART DATA", res);

    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR CHART DATA", error);
    showNotification(error.message, "error");
    } finally {
    hideLoading();
  }
};

export const GetDashboarNeedAction = async (due_date: string) => {
  
  const url = process.env.REACT_APP_ENDPOINT_DASHBOARD_NEED_ACTION || "";
  const userStore = useUserStore.getState();
  const menu_id = FindMenuID(userStore.menu_mapping, "/");

  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    due_date: due_date,
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);

    console.info("ACTION DASHBOARD NEED ACTION", res);

    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR DASHBOARD NEED ACTION", error);
    NotificationAlert({ message: error.message, status: "error" });
    throw new Error(error);
  } finally {
    hideLoading();
  }
};
