import { useEffect, useState } from "react";

interface Column {
  title: string;
  field: string;
  sortable: boolean;
  sortbyOrder?: string; // Optional for default sorting
}

interface TableData {
  [key: string]: string | number | null;
}

interface Accessor {
  field: string;
  sortbyOrder: string;
}

function getDefaultSorting(defaultTableData: TableData[], columns: Column[]) {
  const filterColumn = columns.filter(
    (column) => column.sortable && column.sortbyOrder
  );

  const { field = "id", sortbyOrder = "ASC" }: Accessor = Object.assign(
    {},
    ...filterColumn
  );
  const sorted = [...defaultTableData].sort((a, b) => {
    let valueA = a[field];
    let valueB = b[field];

    // Handle null and undefined values
    if (
      valueA === null ||
      valueB === null ||
      typeof valueA === "undefined" ||
      typeof valueB === "undefined"
    ) {
      return valueA === null || typeof valueA === "undefined" ? 1 : -1;
    }

    // Handle data type inconsistencies
    if (typeof valueA !== typeof valueB) {
      // Convert to a common data type (e.g., string) if necessary
      valueA = String(valueA);
      valueB = String(valueB);
    }

    // Handle string comparisons
    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortbyOrder === "ASC"
        ? valueA.localeCompare(valueB, undefined, { sensitivity: "base" })
        : valueB.localeCompare(valueA, undefined, { sensitivity: "base" });
    }

    // Handle numeric comparisons
    return sortbyOrder === "ASC"
      ? (valueA as number) - (valueB as number)
      : (valueB as number) - (valueA as number);
  });

  return sorted;
}

export const useSortableTable = (data: TableData[], columns: Column[]) => {
  const [tableData, setTableData] = useState(getDefaultSorting(data, columns));

  useEffect(() => {
    data?.length > 0 && setTableData(getDefaultSorting(data, columns));
  }, [data]);
  
  const handleSorting = (sortField: string | number, sortOrder: string) => {
    const sorted = [...tableData].sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];

      if (valueA === null && valueB === null) return 0;
      if (valueA === null) return 1;
      if (valueB === null) return -1;

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder === "ASC"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return sortOrder === "ASC"
        ? (valueA as number) - (valueB as number)
        : (valueB as number) - (valueA as number);
    });

    setTableData(sorted);
  };

  return [tableData, handleSorting] as const;
};
