import { Box, Card, Stack } from "@mui/material";
import CustomTable from "src/components/main/CustomTable";
import { DecimalFormatter } from "src/utils/formatter";
import CustomText from "src/components/main/CustomText";
import { hexToRgba } from "src/utils/utils";
import useThemeStore from "src/store/themeStore";
import CustomCard from "src/components/main/CustomCard";
import { FINANCE_DETAIL_EXPENSE_COLUMNS } from "src/constants/finance";
import { Console } from "console";

interface Props {
  data: FinanceDetailExpense;
}
export default function ReportOutcome({ data }: Props) {
  const { theme } = useThemeStore();
  const total =
  (data?.expens_summary?.Incentive || 0) +
  (data?.expens_summary?.Salary || 0) +
  (data?.expens_summary?.["Item Cost"] || 0) +
  (data?.expens_summary?.Reimburse || 0) +
  (data?.expens_summary?.Purchasement || 0);


  return (
    <Box>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          borderBottom={`1px solid ${theme?.border}`}
          alignContent={"center"}
          p={2}
        >
          <Box sx={{ display: "inline-block", alignContent: "center" }}>
            <CustomText variant="subtitle1" fontWeight={700} align="center">
              Expense Data List
            </CustomText>
          </Box>
        </Box>
        <CustomTable
          columns={FINANCE_DETAIL_EXPENSE_COLUMNS}
          data={data?.expense_list}
          pagination={false}
        />
      </Card>
      <Stack display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
        <Stack width={"40%"}>
          <CustomCard text="Monthly Expense Summary">
            <Stack mt={2}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1">Total Salary</CustomText>
                <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                  data?.expens_summary?.Salary?.toString()
                )}`}</CustomText>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1">Total Incentive</CustomText>
                <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                  data?.expens_summary?.Incentive?.toString()
                )}`}</CustomText>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1">
                  Total Item Supplier Cost
                </CustomText>
                <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                  data?.expens_summary?.["Item Cost"]?.toString()
                )}`}</CustomText>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1">Total Purchasement</CustomText>
                <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                  data?.expens_summary?.Purchasement?.toString() || "0"
                )}`}</CustomText>
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <CustomText variant="subtitle1">Total Reimbursement</CustomText>
                <CustomText variant="subtitle1">{`Rp ${DecimalFormatter(
                  data?.expens_summary?.["Reimburse"]?.toString()
                )}`}</CustomText>
              </Stack>
            </Stack>
            <Stack mt={2}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ backgroundColor: hexToRgba("#fcba03", 0.1) }}
              >
                <CustomText variant="h6" fontWeight={900} gutterBottom={false}>
                  Total Income in IDR
                </CustomText>
                <CustomText variant="h6" fontWeight={900} gutterBottom={false}>
                  {`Rp ${DecimalFormatter(total.toString())}`}
                </CustomText>
              </Stack>
            </Stack>
          </CustomCard>
        </Stack>
      </Stack>
    </Box>
  );
}
