export const USER_COLUMNS = [
  { title: "Username", field: "username", sortable: true },
  { title: "Email", field: "email", sortable: true },
  { title: "Role", field: "role_name", sortable: true },
  { title: "Total Task", field: "total_tasks", sortable: true, align: "center" },
  { title: "Status", field: "status_leave", sortable: true },
];

export const LEAVE_COLUMNS = [
  { title: "Name", field: "nama_user", sortable: true },
  { title: "Title Leave", field: "title_leave", sortable: true },
  { title: "Type Leave", field: "type_leave", sortable: true },
  {
    title: "Request Date",
    field: "request_date",
    sortable: true,
    minWidth: 120,
  },
  { title: "Start Date", field: "start_date", sortable: true, minWidth: 100 },
  { title: "End Date", field: "end_date", sortable: true, minWidth: 100 },
  {
    title: "Total Leave",
    field: "day_leave",
    sortable: true,
    align: "right",
    minWidth: 100,
  },
  { title: "Actions", field: "actions", sortable: false, align: "center" },
];
