import styled from "@emotion/styled";
import { AccordionDetails, AccordionDetailsProps } from "@mui/material";
import { useMemo } from "react";
import useThemeStore from "src/store/themeStore";

export default function FilterAccordionDetail(props: AccordionDetailsProps) {
  const { ...otherProps } = props ?? {};

  const { theme } = useThemeStore();

  const CustomAccordionDetails = useMemo(
    () =>
      styled(AccordionDetails)(() => ({
        paddingTop: 0,
      })),
    [theme]
  );

  return <CustomAccordionDetails {...otherProps} />;
}
