import { SxProps, Typography, TypographyProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomTextProps extends Omit<TypographyProps, "variant"> {
  sx?: SxProps;
  variant?: TypographyProps["variant"];
  required?: boolean;
}

const CustomText: React.FC<CustomTextProps> = (props) => {
  const { sx, variant, children, required, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <Typography
      variant={variant}
      gutterBottom={false}
      sx={{
        "&&.MuiTypography-root": {
          color: theme?.inputLabel,
        },
        ...sx,
      }}
      {...otherProps}>
      {children} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};

export default CustomText;
