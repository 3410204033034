import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import CustomText from "src/components/main/CustomText";
import DifferenceIcon from "@mui/icons-material/Difference";
import { Grid, Modal, Stack } from "@mui/material";
import CustomDivider from "src/components/main/CustomDivider";
import useThemeStore from "src/store/themeStore";
import CustomButton from "src/components/main/CustomButton";
import { DecimalFormatter } from "src/utils/formatter";
import { hexToRgba } from "src/utils/utils";
import CustomPaper from "src/components/main/CustomPaper";
import { useCallback, useState } from "react";
import CustomDropbox from "src/components/main/CustomDropbox";
import ModalConfirm from "src/components/main/ModalConfirm";
import { disburseIncentive } from "src/api/finance";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: FinanceIncentiveList[];
  refetch: () => void;
}

export default function ActionDisbursement(props: Props) {
  const { isOpen, onClose, data, refetch } = props;
  const theme = useTheme();
  const { theme: myTheme } = useThemeStore();

  const [files, setFiles] = useState<BuktiPaymentBase64[][]>([[]]);
  const [activeStep, setActiveStep] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false);
  const [doneAction, setDoneAction] = useState<string[]>([]);

  const maxSteps = data?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fileReader = useCallback(
    (acceptedFiles: File[]) => {
      const fileDataArray: BuktiPaymentBase64[] = [];

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("File reading was aborted");
        reader.onerror = () => console.log("File reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result as string;
          const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
          if (matches) {
            const mimeType = matches[1];
            const base64Data = matches[2];

            fileDataArray.push({
              bukti_payment_base64: base64Data,
              tipe_file: mimeType.split("/")[1],
            });

            if (fileDataArray.length === acceptedFiles.length) {
              setFiles((prevFiles) => {
                const updatedFiles = [...prevFiles];
                if (updatedFiles[activeStep]) {
                  // Add new files to the existing sub-array at the specified index
                  updatedFiles[activeStep] = [
                    ...updatedFiles[activeStep],
                    ...fileDataArray,
                  ];
                } else {
                  // If there's no sub-array at the index, create a new one
                  updatedFiles[activeStep] = fileDataArray;
                }

                return updatedFiles;
              });
            }
          } else {
            console.error("Invalid file data");
          }
        };

        reader.readAsDataURL(file);
      });
    },
    [files, activeStep]
  );

  const handlePaymentFileUpload = (acceptedFiles: File[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];

        // Ensure the sub-array at `index` exists before attempting to modify it
        if (Array.isArray(updatedFiles[activeStep])) {
          const updatedSubArray = [...updatedFiles[activeStep]];

          // Remove the specific file from the sub-array
          updatedSubArray.splice(index, 1);

          // Replace the sub-array with the updated one
          updatedFiles[activeStep] = updatedSubArray;
        } else {
          console.error(`No sub-array exists at index ${activeStep}`);
        }

        return updatedFiles;
      });
    },
    [setFiles, activeStep]
  );

  const handleSubmit = async () => {
    const req: ReqDisburseIncentive = {
      id_grouping: data[activeStep]?.id_grouping,
      attach: files[activeStep],
    };

    const response = await disburseIncentive(req);
    if (response?.status === 200) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setDoneAction([
        ...doneAction,
        `${data[activeStep]?.year_month}-${data[activeStep]?.user_id}`,
      ]);

      if (activeStep === maxSteps - 1) {
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setFiles([]);
    setDoneAction([]);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <CustomPaper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: "auto",
          boxShadow: 24,
          pt: 2,
        }}
      >
        <Box sx={{ width: "100%", flexGrow: 1 }}>
          <Box sx={{ px: 4 }}>
            <Stack direction={"row"} gap={2}>
              <DifferenceIcon
                style={{ color: myTheme?.icon }}
                fontSize="large"
              />
              <CustomText variant="h6" gutterBottom>
                Incentive Disbursement
              </CustomText>
            </Stack>
            <CustomDivider sx={{ mt: 1 }} />
            <Box sx={{ py: 2 }}>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                alignContent={"center"}
                justifyContent={"flex-start"}
              >
                <CustomText
                  variant="subtitle1"
                  fontWeight={"bold"}
                  gutterBottom
                >
                  {data[activeStep]?.year_month}
                </CustomText>
              </Stack>
              <Grid container columnSpacing={2}>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      User Name
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.nama_worker}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Role
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {data[activeStep]?.role_name}
                    </CustomText>
                  </Stack>
                </Grid>
                <Grid item sm={4}>
                  <Stack mb={2}>
                    <CustomText variant="body2" gutterBottom={false}>
                      Amount
                    </CustomText>
                    <CustomText variant="subtitle1" fontWeight={700}>
                      {`Rp ${DecimalFormatter(
                        data[activeStep]?.total_cost?.toString()
                      )}`}
                    </CustomText>
                  </Stack>
                </Grid>
              </Grid>
              <Box
                display={"flex"}
                flexDirection={files.length > 1 ? "column" : "row"}
                justifyContent={"flex-start"}
                alignContent={"center"}
                gap={2}
              >
                <Stack mb={2}>
                  <CustomText variant="body2" gutterBottom>
                    Payment Receipt
                  </CustomText>
                  <CustomDropbox
                    filesType="base64"
                    files={files[activeStep]}
                    viewOnly={doneAction?.includes(
                      `${data[activeStep]?.year_month}-${data[activeStep]?.user_id}`
                    )}
                    onUpload={(file) => handlePaymentFileUpload(file)}
                    deleteByIndex={true}
                    onDeleteByIndex={(index) => handlePaymentFileDelete(index)}
                  />
                </Stack>
              </Box>

              <Box mt={2}>
                {!doneAction?.includes(
                  `${data[activeStep]?.year_month}-${data[activeStep]?.user_id}`
                ) && (
                  <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
                    <CustomButton
                      variant="contained"
                      onClick={() => setConfirmDialog(true)}
                    >
                      Disburse
                    </CustomButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              background: myTheme?.bgPrimary,
              color: myTheme?.inputLabel,
            }}
            nextButton={
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                gap={8}
                mr={2}
              >
                <Button
                  sx={{
                    color: myTheme?.inputLabel,
                    "&.Mui-disabled": {
                      color: hexToRgba(myTheme?.inputLabel, 0.5),
                    },
                  }}
                  size="medium"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
                <Button
                  sx={{
                    color: myTheme?.inputLabel,
                    "&.Mui-disabled": {
                      color: hexToRgba(myTheme?.inputLabel, 0.5),
                    },
                  }}
                  size="medium"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              </Box>
            }
            backButton={<Box />}
          />
          <ModalConfirm
            text="Are you sure you want to disburse this incentive?"
            open={confirmDialog}
            onClose={() => setConfirmDialog(false)}
            onSubmit={handleSubmit}
          />
        </Box>
      </CustomPaper>
    </Modal>
  );
}
