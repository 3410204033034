import {
  Box,
  Stack,
  Grid,
  FormControlLabel,
  InputAdornment,
  Autocomplete,
  createFilterOptions,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import { TypeService, TypeDesign } from "src/components/main/Item/Type";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import CustomFormGroup from "src/components/main/CustomFormGroup";
import useThemeStore from "src/store/themeStore";
import Radio from "@mui/material/Radio";
import {
  dompetSize,
  itemType,
  jaketSize,
  shoesSize,
  serviceType,
  getCurrencySymbol,
} from "src/utils/paramData";
import NewInputNominal from "../../NewInputNominal";
import { Fragment, useMemo } from "react";
import InputDate from "../../Datepicker";
import { useOrderStore } from "src/store/orderStore";

const filter = createFilterOptions();

interface Props {
  data: any;
  disabled?: boolean;
  index: number;
  onChange: (e: any, index: number) => void;
  removeItem?: (index: number) => void;
}
export default function ItemForm({
  data,
  disabled,
  index,
  onChange,
  removeItem,
}: Props) {
  const { theme } = useThemeStore();
  const { order } = useOrderStore();

  const numericGreaterThan = (numStr1 :string,numStr2:string) => {
    console.log("Numw 2 ",numStr2);
    
    const num1 = numStr1 ? BigInt(numStr1) : 0;
    const num2 = numStr2 ?BigInt(numStr2) : 0;

    console.log("Num 1",num1)
    console.log("Num 2",num2)


  return num1 > num2;
  }

  const renderSize = useMemo(() => {
    if (data?.name === itemType[0].value) {
      onChange({ target: { name: "size", value: shoesSize[0] } }, index);
      return shoesSize.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }

    

    if (data?.name === itemType[1].value) {
      onChange({ target: { name: "size", value: jaketSize[0] } }, index);
      return jaketSize.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }

    if (data?.name === itemType[2].value) {
      onChange({ target: { name: "size", value: dompetSize[0] } }, index);
      return dompetSize.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ));
    }

    if (!data?.name) {
      onChange({ target: { name: "size", value: "" } }, index);
    }
    // return null;
  }, [data?.name, itemType]);

  return (
    <Box>
      <Grid container columnSpacing={4}>
        <Grid item sm={6} width={"100%"}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Item Type</CustomText>
            <Autocomplete
              value={data?.name || ""}
              onChange={(event, newValue) => {
                let valueToSend;

                if (typeof newValue === "string") {
                  valueToSend = newValue;
                } else if (newValue && newValue.inputValue) {
                  valueToSend = {
                    label: newValue?.inputValue,
                    value: newValue?.inputValue,
                  };
                } else {
                  valueToSend = newValue ? newValue : "";
                }

                onChange(
                  { target: { name: "name", value: valueToSend.value } },
                  index
                );
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.value
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              autoComplete
              disabled={disabled}
              id="name"
              options={itemType}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.value;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.value}</li>
              )}
              renderInput={(params) => (
                <CustomInput
                  {...params}
                  required
                  disabled={disabled}
                  value={data?.name}
                  onChange={(event) => onChange(event, index)}
                  size="small"
                  id="name"
                  name="name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>{params.InputProps.endAdornment}</Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Service Type</CustomText>
            <TypeService
              disabled={disabled}
              value={data?.service_type}
              id="service_type"
              name="service_type"
              onChange={(event) => onChange(event, index)}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Size</CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.size}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="size"
              name="size"
              type="string"
              select={itemType.some((type) => type.value === data?.name)}
              InputProps={{
                endAdornment:
                  data?.name === itemType[0].value ? (
                    <CustomText
                      className="pr-[24px]"
                      variant="body1"
                      gutterBottom={false}
                    >
                      EUR
                    </CustomText>
                  ) : (
                    ""
                  ),
              }}
            >
              {renderSize}
            </CustomInput>
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Item Price</CustomText>
            <NewInputNominal
              required
              disabled={disabled}
              value={data?.cost?.toString()}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="cost"
              name="cost"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(order?.currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          {data?.from_client === false && (
            <Stack my={"10px"}>
              <CustomText variant="body1">Item Cost</CustomText>
              <NewInputNominal
                required
                error={numericGreaterThan(data?.starting_price?.toString(),data?.cost?.toString())}
                helperText={numericGreaterThan(data?.starting_price?.toString(),data?.cost?.toString()) ? "Item Cost Must Be lower than Item Price" : ""}
                disabled={disabled}
                value={data?.starting_price?.toString()}
                onChange={(event) => onChange(event, index)}
                size="small"
                id="starting_price"
                name="starting_price"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
              />
            </Stack>
          )}
          <Stack my={"10px"}>
            <CustomText variant="body1">Base From</CustomText>
            <CustomFormGroup>
              <RadioGroup
                row
                name="from_client"
                defaultValue={data?.from_client ? "client" : "supplier"}
                onChange={(event) => onChange(event, index)}
              >
                <FormControlLabel
                  value="supplier"
                  control={<Radio />}
                  label="Supplier"
                  disabled={disabled}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: theme?.inputLabel,
                      "&.Mui-disabled": {
                        color: theme?.inputLabel,
                      },
                    },
                  }}
                />
                <FormControlLabel
                  value="client"
                  control={<Radio />}
                  label="Client"
                  disabled={disabled}
                />
              </RadioGroup>
            </CustomFormGroup>
          </Stack>
        </Grid>
        <Grid item sm={6} width={"100%"}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Brand</CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.brand}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="brand"
              name="brand"
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Design Type</CustomText>
            <TypeDesign
              id="design_type"
              name="design_type"
              disabled={disabled}
              value={data?.design_type}
              onChange={(event) => onChange(event, index)}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Color / Type</CustomText>
            <CustomInput
              required
              disabled={disabled}
              value={data?.color}
              onChange={(event) => onChange(event, index)}
              size="small"
              id="color"
              name="color"
            />
          </Stack>
          {data?.from_client === false && (
            <Stack my={"10px"}>
              <CustomText variant="body1">Supplier Deadline</CustomText>
              <InputDate
                name="supplier_deadline"
                id="supplier_deadline"
                value={data?.supplier_deadline || data?.deadline_supplier}
                onChange={(event) => onChange(event, index)}
                disablePast
                disabled={disabled}
              />
            </Stack>
          )}
          {(data?.service_type === serviceType[1].value ||
            data?.service_type === serviceType[2].value) && (
            <>
              <Stack my={"10px"}>
                <CustomText variant="body1">Leather Type</CustomText>
                <CustomInput
                  required
                  disabled={disabled}
                  value={data?.leather_type}
                  onChange={(event) => onChange(event, index)}
                  size="small"
                  id="leather_type"
                  name="leather_type"
                />
              </Stack>

              <Stack my={"10px"}>
                <CustomText variant="body1">Leather From</CustomText>
                <CustomFormGroup>
                  <RadioGroup
                    row
                    name="leather_from_client"
                    defaultValue={
                      data?.leather_from_client ? "client" : "supplier"
                    }
                    onChange={(event) => onChange(event, index)}
                  >
                    <FormControlLabel
                      value="supplier"
                      control={<Radio />}
                      label="Supplier"
                      disabled={disabled}
                    />
                    <FormControlLabel
                      value="client"
                      control={<Radio />}
                      label="Client"
                      disabled={disabled}
                    />
                  </RadioGroup>
                </CustomFormGroup>
              </Stack>
            </>
          )}
        </Grid>
      </Grid>
      <Stack my={"10px"}>
        <CustomText variant="body1">Additional Notes</CustomText>
        <CustomInput
          disabled={disabled}
          value={data?.notes}
          onChange={(event) => onChange(event, index)}
          size="small"
          id="notes"
          name="notes"
          multiline
          rows={4}
        />
      </Stack>
    </Box>
  );
}
