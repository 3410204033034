import { SxProps, TextField, TextFieldProps } from "@mui/material";
import { NumericFormat } from "react-number-format";
import useThemeStore from "src/store/themeStore";

interface NewInputNominalProps extends Omit<TextFieldProps, "variant"> {
  sx?: SxProps;
  value: string;
  inputProps?: any;
  isChanged?: boolean;
}

const NewInputNominal: React.FC<NewInputNominalProps> = (props) => {
  const {
    value,
    inputProps,
    isChanged = false,
    onChange,
    ...otherProps
  } = props ?? {};
  const { theme } = useThemeStore();
  return (
    <TextField
      autoComplete="off"
      InputProps={{
        ...inputProps,
        inputComponent: NumberFormatCustom,
      }}
      value={value}
      onChange={onChange}
      sx={{
        "& .MuiInputBase-root": {
          color: theme?.inputText,
        },
        "& .MuiOutlinedInput-root": {
          background: `linear-gradient(22.5deg, ${theme?.inputPrimary}, ${theme?.inputSecondary})`,
          "& fieldset": {
            borderColor: isChanged ? "#1976d2" : theme?.inputBorder, // Warna border input
          },
          "&:hover fieldset": {
            borderColor: isChanged ? "#1976d2" : theme?.inputBorder, // Warna border input
          },
          "&.Mui-focused fieldset": {
            borderColor: isChanged ? "#1976d2" : theme?.inputBorder, // Warna border input saat dalam keadaan aktif (focus)
          },
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: theme?.inputTextDisabled, // Warna teks input disabled
        },
        "& .MuiInputAdornment-root.Mui-disabled": {
          WebkitTextFillColor: theme?.inputTextDisabled, // Warna teks input disabled
        },
        "&& .MuiInputLabel-root": {
          color: theme?.inputLabel, // Warna teks label
        },
        "&& .MuiTypography-root": {
          color: theme?.inputText, // Warna teks input
        },
      }}
      {...otherProps}
    />
  );
};

export default NewInputNominal;

function NumberFormatCustom(props: any) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      valueIsNumericString
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      allowNegative={false}
    />
  );
}
