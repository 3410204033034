import { useLoadingStore } from "src/store/loadingStore";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import {
  sendRequestGET,
  sendRequestPOST,
  sendRequestPUT,
} from "../sendRequest";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();


export const getFinanceList = async () => {
  const userStore = useUserStore.getState();
  

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, {}, header);
    console.info("ACTION GET FINANCE LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET FINANCE LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const generateReportFinance = async () => {
  
  const userStore = useUserStore.getState();

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_GENERATE_FINANCE_REPORT || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, {}, header);
    console.info("ACTION GENERATE REPORT FINANCE", res);
    showNotification(res.data.message, "success");
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GENERATE REPORT FINANCE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getFinanceDetail = async (id: string) => {
  const userStore = useUserStore.getState();

  

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_DETAIL || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION GET FINANCE DETAIL", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR GET FINANCE DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getFinanceIncentiveList = async () => {
  
  const userStore = useUserStore.getState();

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_INCENTIVE_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, {}, header);
    console.info("ACTION GET FINANCE INCENTIVE LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET FINANCE INCENTIVE LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const disburseIncentive = async (req: ReqDisburseIncentive) => {

  const userStore = useUserStore.getState();

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_INCENTIVE_DISBURSE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPUT(url, req, header);
    console.info("ACTION DISBURSE INCENTIVE", res);
    showNotification(res.data.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR DISBURSE INCENTIVE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getFinanceIncentiveDetail = async (id: string) => {
  
  const userStore = useUserStore.getState();

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_INCENTIVE_DETAIL || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  const req = {
    id_grouping: id,
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION GET FINANCE INCENTIVE DETAIL", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR GET FINANCE INCENTIVE DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getFinanceSalaryList = async () => {

  const userStore = useUserStore.getState();

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_SALARY_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(url, header);
    console.info("ACTION GET FINANCE SALARY LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR GET FINANCE SALARY LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}

export const disburseSalary = async (req: DisburseSalary) => {

  const userStore = useUserStore.getState();

const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_FINANCE_SALARY_DISBURSE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };
  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION DISBURSE SALARY", res);
    showNotification(res.data.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR DISBURSE SALARY", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
}



const generateFilename = (prefix: string, extension: string): string => {
  const now = new Date();
  const formattedDate = now.toISOString().replace(/[-:T]/g, "").split(".")[0]; // e.g., "20230920T123456"
  return `${prefix}-${formattedDate}${extension}`;
};

export const downloadReportFinance = async () => {
  const userStore = useUserStore.getState();

  const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_DOWNLOAD_REPORT_FINANCE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    // Fetch the file from the API endpoint
    const response = await fetch(url, {
      method: "GET",
      headers: header,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error: ${response}`);
    }

    // Convert the response to a Blob (handles any file type)
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const fileUrl = window.URL.createObjectURL(blob);

    // Determine the filename from the Content-Disposition header if available
    const fileName = generateFilename("Finance-Report", ".xlsx");

    

    // Create an anchor element and set its href to the Blob URL
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = fileName; // Use the dynamic filename

    // Append the anchor to the body
    document.body.appendChild(downloadLink);
    // Trigger the download by programmatically clicking the anchor
    downloadLink.click();

    // Clean up: remove the anchor element and revoke the Blob URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileUrl);

    console.info("ACTION DOWNLOAD REPORT SALARY SUCCESS");
    showNotification("Report downloaded successfully", "success");
  } catch (error: any) {
    console.error("ACTION ERROR DOWNLOAD REPORT SALARY", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const downloadReportSalary = async () => {
  const userStore = useUserStore.getState();

  const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_DOWNLOAD_REPORT_SALARY || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    // Fetch the file from the API endpoint
    const response = await fetch(url, {
      method: "GET",
      headers: header,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error: ${response}`);
    }

    // Convert the response to a Blob (handles any file type)
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const fileUrl = window.URL.createObjectURL(blob);

    // Determine the filename from the Content-Disposition header if available
    const fileName = generateFilename("Salary-Report", ".xlsx");

    

    // Create an anchor element and set its href to the Blob URL
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = fileName; // Use the dynamic filename

    // Append the anchor to the body
    document.body.appendChild(downloadLink);
    // Trigger the download by programmatically clicking the anchor
    downloadLink.click();

    // Clean up: remove the anchor element and revoke the Blob URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileUrl);

    console.info("ACTION DOWNLOAD REPORT SALARY SUCCESS");
    showNotification("Report downloaded successfully", "success");
  } catch (error: any) {
    console.error("ACTION ERROR DOWNLOAD REPORT SALARY", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};



export const downloadReportIncentive = async () => {
  const userStore = useUserStore.getState();

  const menu_id = FindMenuID(userStore.menu_mapping, "/finance");
  const url = process.env.REACT_APP_ENDPOINT_DOWNLOAD_REPORT_INCENTIVE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    // Fetch the file from the API endpoint
    const response = await fetch(url, {
      method: "GET",
      headers: header,
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`Error: ${response}`);
    }

    // Convert the response to a Blob (handles any file type)
    const blob = await response.blob();

    // Create a temporary URL for the Blob
    const fileUrl = window.URL.createObjectURL(blob);

    // Determine the filename from the Content-Disposition header if available
    const fileName = generateFilename("Incentive-Report", ".xlsx");

    

    // Create an anchor element and set its href to the Blob URL
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = fileName; // Use the dynamic filename

    // Append the anchor to the body
    document.body.appendChild(downloadLink);
    // Trigger the download by programmatically clicking the anchor
    downloadLink.click();

    // Clean up: remove the anchor element and revoke the Blob URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(fileUrl);

    console.info("ACTION DOWNLOAD REPORT SALARY SUCCESS");
    showNotification("Report downloaded successfully", "success");
  } catch (error: any) {
    console.error("ACTION ERROR DOWNLOAD REPORT SALARY", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};
