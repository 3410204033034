export const REIMBURSEMENT_STATUS = ["Pending", "Rejected", "Approved", "Paid"];

export const REIMBURSEMENT_COLUMNS = [
  { title: "Expense ID", field: "ship_id", sortable: true },
  { title: "User Name", field: "user_name", sortable: true },
  { title: "Price", field: "cost", sortable: true },
  { title: "Expedition", field: "expedtition", sortable: true },
  { title: "Status", field: "status", sortable: true },
  { title: "Date", field: "claim_date", sortable: true },
];
