import { Box } from "@mui/material";
import TimelineProcessOrder from "src/components/main/TimelineProcessOrder";

export default function Process() {
  return (
    <Box width={"100%"}>
      <TimelineProcessOrder />
    </Box>
  );
}
