import { useState, useEffect } from "react";
import { getItemLogAttachment } from "src/api/item";
import ImageHolder from "../../CustomDropbox/ImageHolder";
import { Box, Stack } from "@mui/material";
import TaskDetail from "./TaskDetail";
import { useProcessStore } from "src/store/processStore";
import ShipmentDetail from "./ShipmentDetail";
import Information from "../../Process/Information";

interface Props {
  dataTask: any;
  historyItem: any;
  refetch: () => void;
}

export default function Container({ dataTask, historyItem, refetch }: Props) {
  const [photo, setPhoto] = useState<any>([]);

  const fetchAttachment = async () => {
    const res = await getItemLogAttachment(historyItem?.attachment);
    setPhoto(res?.data);
  };

  useEffect(() => {
    if (historyItem?.attachment) {
      fetchAttachment();
    }
  }, [historyItem?.attachment]);

  return (
    <Box>
      {historyItem?.attachment && (
        <Box mt={1}>
          <ImageHolder image={photo} size={100} />
        </Box>
      )}
      {dataTask?.[historyItem?.extras?.task_id] && (
        <Stack direction={"row"} gap={2}>
          <TaskDetail
            data={dataTask?.[historyItem?.extras?.task_id]}
            refetch={refetch}
          />
          {dataTask?.[historyItem?.extras?.task_id]?.ship_id && (
            <ShipmentDetail data={dataTask?.[historyItem?.extras?.task_id]?.shipping_data?.WorkerShipData}/>
          )}
        </Stack>
      )}
    </Box>
  );
}
