import React from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Outlet } from "react-router-dom";
import { useLayoutStore } from "src/store/layoutStore";

const NavbarWrapper = () => {
  const layoutStore = useLayoutStore();

  const handleDrawerOpen = () => {
    layoutStore.openSidebar();
  };

  return (
    <>
      <Navbar open={layoutStore.isSidebarOpen} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={layoutStore.isSidebarOpen}>
        <Outlet />
      </Sidebar>
    </>
  );
}

export default NavbarWrapper;