import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { SxProps } from "@mui/material";
import useThemeStore from "src/store/themeStore";

interface CustomCheckboxProps extends CheckboxProps {
  sx?: SxProps;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props) => {
  const { sx, ...otherProps } = props ?? {};
  const { theme } = useThemeStore();

  return (
    <Checkbox
      sx={{
        "&.MuiCheckbox-root": {
          color: theme?.checkbox,
          "&.Mui-checked": {
            color: theme?.checkboxChecked,
          },
          "&.Mui-disabled": {
            color: theme?.checkboxDisabled,
          },
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export default CustomCheckbox;
