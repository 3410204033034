import { createBrowserRouter } from "react-router-dom";
import Login from "src/pages/auth/Login";
import OrderList from "src/pages/order/OrderList";
import OrderDetail from "src/pages/order/OrderDetail";
import { loader } from "./loader";
import Dashboard from "src/pages/dashboard/Dashboard";
import OrderCreate from "src/pages/order/OrderCreate";
import ShippingAddressCreate from "src/pages/order/OrderCreate/components/ShippingAddressCreate";
import ListShippingAddress from "src/pages/order/OrderDetail/components/ListShippingAddress";
import ItemList from "src/pages/item/ItemList";
import TaskList from "src/pages/task/TaskList";
import UserList from "src/pages/user/UserList";
import ItemDetail from "src/pages/item/ItemDetail";
import TaskDetail from "src/pages/task/TaskDetail";
import UserDetail from "src/pages/user/UserDetail";
import CustomerList from "src/pages/customer/CustomerList";
import CustomerDetail from "src/pages/customer/CustomerDetail";
import CompanyDetail from "src/pages/company/CompanyDetail";
import UserCreate from "src/pages/user/UserCreate";
import FinanceReport from "src/pages/finance/FinanceReport";
import FinanceReportDetail from "src/pages/finance/FinanceReport/FinanceReportDetail";
import FinanceReportCreate from "src/pages/finance/FinanceReport/FinanceReportCreate";
import FinanceInsentive from "src/pages/finance/FinanceInsentive";
import FinanceInsentiveDetail from "src/pages/finance/FinanceInsentive/FinanceInsentiveDetail";
import FinanceSalary from "src/pages/finance/FinanceSalary";
import TaskManagement from "src/pages/task/TaskManagement";
import { UploadDesignItem } from "src/pages/task/TaskManagement/components/UploadDesignItem";
import LeaveList from "src/pages/leave";
import CollectiveLeave from "src/pages/company/CollectiveLeave";
import AddLeave from "src/pages/company/CollectiveLeave/components/AddLeave";
import FinanceDebt from "src/pages/finance/FinanceDebt";
import Detail from "src/pages/leave/components/Detail";
import NavbarWrapper from "src/components/navigators/NavbarWrapper";
import PurchasingCreate from "src/pages/purchase/PurchasingCreate";
import PurchasingDetail from "src/pages/purchase/PurchasingDetail";
import PurchasingReimbursement from "src/pages/purchase/PurchasingList";
import ShipmentCreate from "src/pages/purchase/ShipmentCreate";
import ShipmentDetail from "src/pages/purchase/ShipmentDetail";
import ShipmentPurchase from "src/pages/purchase/ShipmentList";
import Reimbursement from "src/pages/reimbursement";
import InsentiveSettings from "src/pages/company/InsentiveSettings";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <NavbarWrapper />,
    loader: loader,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/order",
        element: <OrderList />,
      },
      {
        path: "/order/:id",
        element: <OrderDetail />,
      },
      {
        path: "/order/:id/shipping",
        element: <ListShippingAddress />,
      },
      {
        path: "/order/create",
        element: <OrderCreate />,
      },
      {
        path: "/order/create/shipping",
        element: <ShippingAddressCreate />,
      },
      {
        path: "/item",
        element: <ItemList />,
      },
      {
        path: "/item/:id",
        element: <ItemDetail />,
      },
      {
        path: "/task/task-list",
        element: <TaskList />,
      },
      {
        path: "/task/task-list/:id",
        element: <TaskDetail />,
      },
      {
        path: "/task/task-management",
        element: <TaskManagement />,
      },
      {
        path: "/task/task-management/:id",
        element: <UploadDesignItem />,
      },
      {
        path: "/user",
        element: <UserList />,
      },
      {
        path: "/user/create",
        element: <UserCreate />,
      },
      {
        path: "/user/:id",
        element: <UserDetail />,
      },
      {
        path: "/customer",
        element: <CustomerList />,
      },
      {
        path: "/customer/:id",
        element: <CustomerDetail />,
      },
      {
        path: "/finance/monthly-recap",
        element: <FinanceReport />,
      },
      {
        path: "/finance/monthly-recap/:id",
        element: <FinanceReportDetail />,
      },
      {
        path: "/finance/monthly-recap/create",
        element: <FinanceReportCreate />,
      },
      {
        path: "/finance/incentive",
        element: <FinanceInsentive />,
      },
      {
        path: "/finance/incentive/:id",
        element: <FinanceInsentiveDetail />,
      },
      {
        path: "/finance/salary",
        element: <FinanceSalary />,
      },
      {
        path: "/finance/debt",
        element: <FinanceDebt />,
      },
      {
        path: "/company/company-settings",
        element: <CompanyDetail />,
      },
      {
        path: "/company/incentive-settings",
        element: <InsentiveSettings />,
      },
      {
        path: "/company/collective-leave",
        element: <CollectiveLeave />,
      },
      {
        path: "/company/collective-leave/add",
        element: <AddLeave />,
      },
      {
        path: "/leave",
        element: <LeaveList />,
      },
      {
        path: "/leave/:id",
        element: <Detail />,
      },
      {
        path: "/reimbursement",
        element: <Reimbursement />,
      },
      {
        path: "/purchase/shipment",
        element: <ShipmentPurchase />,
      },
      {
        path: "/purchase/shipment/create",
        element: <ShipmentCreate />,
      },
      {
        path: "/purchase/shipment/:id",
        element: <ShipmentDetail />,
      },
      {
        path: "/purchase/purchasing",
        element: <PurchasingReimbursement />,
      },
      {
        path: "/purchase/purchasing/create",
        element: <PurchasingCreate />,
      },
      {
        path: "/purchase/purchasing/:id",
        element: <PurchasingDetail />,
      },
    ],
  },
]);

export default router;
