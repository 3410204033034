import { create } from "zustand";

interface FilterState {
  statusList: string[];
  status: string[];
  user_id: string[];
  key: string;
  order_key: string;
  sort_type: string;
  page: number;
  limit: number;
  start_date: string;
  end_date: string;
  total_page: number;
  order_date_start: string;
  order_date_end: string;
}

interface FilterAction {
  setStatusList: (statusList: FilterState["statusList"]) => void;
  setStatus: (status: FilterState["status"]) => void;
  setUserId: (user_id: FilterState["user_id"]) => void;
  setKey: (key: FilterState["key"]) => void;
  setOrderKey: (order_key: FilterState["order_key"]) => void;
  setSortType: (sort_type: FilterState["sort_type"]) => void;
  setPage: (page: FilterState["page"]) => void;
  setLimit: (limit: FilterState["limit"]) => void;
  setStartDate: (startDate: FilterState["start_date"]) => void;
  setEndDate: (endDate: FilterState["end_date"]) => void;
  setTotalPage: (total_page: FilterState["total_page"]) => void;
  setOrderDateStart: (order_date_start: FilterState["order_date_start"]) => void;
  setOrderDateEnd: (order_date_end: FilterState["order_date_end"]) => void;
  resetFilterStore: () => void;
}

const initialState: FilterState = {
  statusList: [],
  status: [],
  user_id: [],
  key: "",
  order_key: "",
  sort_type: "",
  page: 1,
  limit: 10,
  start_date: "",
  end_date: "",
  total_page: 1,
  order_date_start: "",
  order_date_end: "",
};

export const useFilterStore = create<FilterState & FilterAction>()((set) => ({
  ...initialState,
  setStatusList: (statusList: FilterState["statusList"]) =>
    set(() => ({ statusList: statusList })),
  setStatus: (status: FilterState["status"]) => set(() => ({ status: status })),
  setUserId: (user_id: FilterState["user_id"]) =>
    set(() => ({ user_id: user_id })),
  setKey: (key: FilterState["key"]) => set(() => ({ key: key })),
  setOrderKey: (order_key: FilterState["order_key"]) =>
    set(() => ({ order_key: order_key })),
  setSortType: (sort_type: FilterState["sort_type"]) =>
    set(() => ({ sort_type: sort_type })),
  setPage: (page: FilterState["page"]) => set(() => ({ page: page })),
  setLimit: (limit: FilterState["limit"]) => set(() => ({ limit: limit })),
  setStartDate: (startDate: FilterState["start_date"]) =>
    set(() => ({ start_date: startDate })),
  setEndDate: (endDate: FilterState["end_date"]) =>
    set(() => ({ end_date: endDate })),
  setTotalPage: (total_page: FilterState["total_page"]) =>
    set(() => ({ total_page: total_page })),
  setOrderDateStart: (order_date_start: FilterState["order_date_start"]) =>
    set(() => ({ order_date_start: order_date_start })),
  setOrderDateEnd: (order_date_end: FilterState["order_date_end"]) =>
    set(() => ({ order_date_end: order_date_end })),
  resetFilterStore: () =>
    set(() => ({
      ...initialState,
    })),
}));

interface CustomerFilter {
  key: string;
  setKey: (key: CustomerFilter["key"]) => void;
}

export const useCustomerFilter = create<CustomerFilter>()((set) => ({
  key: "",
  setKey: (key: CustomerFilter["key"]) => set(() => ({ key: key })),
}));

interface DashboardFilter {
  status: string;
  setStatus: (status: DashboardFilter["status"]) => void;
  resetDashboardFilter: () => void;
}
export const useDashboardFilter = create<DashboardFilter>()((set) => ({
  status: "",
  setStatus: (status: DashboardFilter["status"]) =>
    set(() => ({ status: status })),
  resetDashboardFilter: () => set(() => ({ status: "" })),
}));
