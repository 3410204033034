import { Stack, Grid, MenuItem, InputAdornment } from "@mui/material";
import { userLevel, userRole, userStatus } from "src/utils/paramData";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import NewInputNominal from "src/components/main/NewInputNominal";
import CustomCard from "src/components/main/CustomCard";
import Password from "./Password";
import { useParameterStore } from "src/store/parameterStore";
import useThemeStore from "src/store/themeStore";

type TAction = "create" | "update";
interface Props {
  title: string;
  isEdit: boolean;
  formData?: User;
  action: TAction;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function UserForm(props: Props) {
  const { title, isEdit, formData, action, error, onChange } = props;
  const { theme } = useThemeStore();

  const { role } = useParameterStore();
  return (
    <CustomCard text={title}>
      <Grid
        container
        spacing={4}
        direction={"row"}
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Grid item sm={12} md={6} lg={4}>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body1">Username</CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.username}
                onChange={onChange}
                required
                size="small"
                id="username"
                name="username"
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">Email</CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.email}
                onChange={onChange}
                required
                size="small"
                id="email"
                name="email"
              />
            </Stack>
            {action === "create" && (
              <Stack>
                <CustomText variant="body1">Password</CustomText>
                <Password
                  disabled={!isEdit}
                  value={formData?.password}
                  onChange={onChange}
                  required
                  size="small"
                  id="password"
                  name="password"
                  error={!!error}
                  helperText={error}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      background: theme?.inputPrimary,
                      "& fieldset": {
                        borderColor: error
                          ? theme?.button100
                          : theme?.inputBorder,
                      },
                      "&:hover fieldset": {
                        borderColor: error
                          ? theme?.button100
                          : theme?.inputBorder,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: error
                          ? theme?.button100
                          : theme?.inputBorder,
                      },
                    },
                    "& .MuiFormHelperText-root": { color: theme?.button100 },
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body1">Display Name</CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.display_name}
                onChange={onChange}
                required
                size="small"
                id="display_name"
                name="display_name"
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">Salary</CustomText>
              <NewInputNominal
                size="small"
                id="salary"
                name="salary"
                disabled={!isEdit}
                value={formData?.salary?.toString() || "0"}
                onChange={onChange}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack>
              <CustomText variant="body1">Phone Number</CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.phone_number}
                onChange={onChange}
                required
                size="small"
                id="phone_number"
                name="phone_number"
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={12} md={6} lg={4}>
          <Stack spacing={2}>
            <Stack>
              <CustomText variant="body1">Role</CustomText>
              <CustomInput
                disabled={!isEdit}
                onChange={onChange}
                required
                size="small"
                id="role"
                name="role"
                select
                value={formData?.role ? formData?.role : role[0].Id}
                defaultValue={formData?.role ? formData?.role : role[0].Id}
              >
                {role.map((option) => (
                  <MenuItem key={option.Id} value={option.Id}>
                    {option.Name}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            {formData?.role === "2e8675f7-8663-456b-af59-7fb1a1e5d872" && (
              <Stack>
                <CustomText variant="body1">Level</CustomText>
                <CustomInput
                  disabled={!isEdit}
                  onChange={onChange}
                  required
                  size="small"
                  id="level"
                  name="level"
                  select
                  defaultValue={formData?.level || userLevel[0].value}
                >
                  {userLevel.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomInput>
              </Stack>
            )}
            <Stack>
              <CustomText variant="body1">Status</CustomText>
              <CustomInput
                disabled={!isEdit}
                value={formData?.status}
                onChange={onChange}
                required
                // disabled={!isEdit}
                size="small"
                id="status"
                name="status"
                select
                defaultValue={userStatus[0].value}
              >
                {userStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </CustomCard>
  );
}
