import {
  Box,
  Stack,
  Grid,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import CustomButton from "src/components/main/CustomButton";
import { useOrderStore } from "src/store/orderStore";
import { useNavigate } from "react-router-dom";
import CustomCard from "src/components/main/CustomCard";
import PageTitle from "src/components/main/CustomText/PageTitle";
import countries from "src/assets/countries.json";
import provinces from "src/assets/provinces.json";
import regencies from "src/assets/regencies.json";
import { useState } from "react";
import { capitalizeEachWord } from "src/utils/formatter";

const filter = createFilterOptions();

export default function ShippingAddressCreate() {
  const navigate = useNavigate();
  const { newShipment, setNewShipment } = useOrderStore();

  const [data, setData] = useState<CustomerAddress>(
    newShipment.shipment as CustomerAddress
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    // If province is being changed, reset city
    if (name === "province") {
      setData((prevData: CustomerAddress) => ({
        ...prevData,
        [name]: value,
        city: "", // Reset city value when province changes
      }));
    } else {
      setData((prevData: CustomerAddress) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setNewShipment({
      isNewShipment: true,
      shipment: {
        province: data?.province,
        city: data?.city,
        country: data?.country,
        village: data?.village,
        district: data?.district,
        address: data?.address,
        postal_code: data?.postal_code,
        recipient_name: data?.recipient_name,
        recipient_contact: data?.recipient_contact,
        type_shipment: data?.type_shipment,
      },
    });

    navigate("/order/create");
  };

  return (
    <Box component="form" onSubmit={handleSave} alignItems={"center"}>
      <PageTitle>Create New Shipping Address</PageTitle>
      <CustomCard text="New Address">
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Stack mb={2}>
            <CustomText variant="body1">Recipient Name</CustomText>
            <CustomInput
              required
              size="small"
              id="recipient_name"
              name="recipient_name"
              value={data?.recipient_name}
              onChange={handleChange}
            />
          </Stack>
          <Stack mb={2}>
            <CustomText variant="body1">Phone / Whatsapp</CustomText>
            <CustomInput
              required
              size="small"
              id="recipient_contact"
              name="recipient_contact"
              value={data?.recipient_contact}
              onChange={handleChange}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Stack>
          <Stack mb={2}>
            <CustomText variant="body1">Address Label</CustomText>
            <CustomInput
              required
              size="small"
              id="type_shipment"
              name="type_shipment"
              value={data?.type_shipment}
              onChange={handleChange}
            />
          </Stack>
          <Grid container columnSpacing={4}>
            <Grid item sm={6}>
              <Stack mb={2}>
                <CustomText variant="body1">Country</CustomText>
                <Autocomplete
                  size="small"
                  id="country"
                  componentName="country"
                  value={data?.country ?? ""}
                  onChange={(event, newValue) =>
                    handleChange({
                      target: { name: "country", value: newValue ?? "" },
                    })
                  }
                  options={countries.map((option) => option.name)}
                  renderInput={(params) => <CustomInput {...params} required />}
                />
              </Stack>
              <Stack mb={2}>
                <CustomText variant="body1">City</CustomText>
                {data?.country?.toLowerCase() === "indonesia" ? (
                  <Autocomplete
                    size="small"
                    id="city"
                    componentName="city"
                    clearOnBlur
                    selectOnFocus
                    value={data?.city ?? ""}
                    onChange={(event, newValue: any) => {
                      let valueToSend;

                      if (typeof newValue === "string") {
                        valueToSend = newValue;
                      } else if (newValue && newValue.inputValue) {
                        valueToSend = {
                          label: newValue?.inputValue,
                          value: newValue?.inputValue,
                        };
                      } else {
                        valueToSend = newValue ? newValue : "";
                      }

                      handleChange({
                        target: {
                          name: "city",
                          value: valueToSend.value
                            ? valueToSend.value
                            : valueToSend,
                        },
                      });
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          value: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    options={regencies
                      .filter(
                        (option) =>
                          option.province_id ===
                          (provinces.find(
                            (province) => province.name === data?.province
                          )?.id ?? 0)
                      )
                      .map((option) => capitalizeEachWord(option.name))}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.value;
                      }
                      return option.value;
                    }}
                    renderInput={(params) => (
                      <CustomInput
                        {...params}
                        name="city"
                        id="city"
                        required
                        disabled={!data?.province} // Disable city input until province is selected
                      />
                    )}
                  />
                ) : (
                  <CustomInput
                    required
                    size="small"
                    id="city"
                    name="city"
                    value={data?.city}
                    onChange={handleChange}
                    disabled={!data?.country} // Disable if country is not selected
                  />
                )}
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <Stack mb={2}>
                <CustomText variant="body1">Province</CustomText>
                {data?.country?.toLowerCase() === "indonesia" ? (
                  <Autocomplete
                    size="small"
                    id="province"
                    componentName="province"
                    value={data?.province ?? ""}
                    clearOnBlur
                    selectOnFocus
                    onChange={(event, newValue: any) => {
                      let valueToSend;

                      if (typeof newValue === "string") {
                        valueToSend = newValue;
                      } else if (newValue && newValue.inputValue) {
                        valueToSend = {
                          label: newValue?.inputValue,
                          value: newValue?.inputValue,
                        };
                      } else {
                        valueToSend = newValue ? newValue : "";
                      }

                      handleChange({
                        target: {
                          name: "province",
                          value: valueToSend.value
                            ? valueToSend.value
                            : valueToSend,
                        },
                      });
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          value: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    options={provinces.map((option) => option.name)}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.value;
                      }
                      return option.value;
                    }}
                    renderInput={(params) => (
                      <CustomInput
                        {...params}
                        name="province"
                        id="province"
                        required
                        disabled={!data?.country} // Disable province input until country is selected
                      />
                    )}
                  />
                ) : (
                  <CustomInput
                    required
                    size="small"
                    id="province"
                    name="province"
                    value={data?.province}
                    onChange={handleChange}
                    disabled={!data?.country} // Disable if country is not selected
                  />
                )}
              </Stack>
              <Stack mb={2}>
                <CustomText variant="body1">Postal Code</CustomText>
                <CustomInput
                  required
                  size="small"
                  id="postal_code"
                  name="postal_code"
                  value={data?.postal_code}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack mb={2}>
            <CustomText variant="body1">Full Address</CustomText>
            <CustomInput
              required
              size="small"
              id="address"
              name="address"
              value={data?.address}
              onChange={handleChange}
            />
          </Stack>
        </Box>
      </CustomCard>
      <Box textAlign="right">
        <CustomButton variant="contained" type="submit">
          Save
        </CustomButton>
      </Box>
    </Box>
  );
}
