import { Box, Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import colors from "src/themes/colors";
import InsentiveForms from "../../components/InsentiveForms";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import CustomDropbox from "src/components/main/CustomDropbox";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import InputDate from "src/components/main/Datepicker";
import NewInputNominal from "src/components/main/NewInputNominal";
import { getItemLogAttachment, getItemLogAttachmentGroup } from "src/api/item";

interface Props {
  data: any;
}

export default function InsentiveDetail({ data }: Props) {
  const [attachment, setAttachment] = useState<Attachment[]>([]);

  const fetchAttachment = async () => {
    const response = await getItemLogAttachmentGroup(data?.attachment);
    setAttachment(response?.data);
  };

  useEffect(() => {
    if (data?.attachment) {
      fetchAttachment();
    }
  }, [data?.attachment]);

  return (
    <CustomCard text="Insentive Detail">
      <Grid container columnSpacing={4}>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Period</CustomText>
            <CustomInput
              disabled
              size="small"
              id="year_month"
              name="year_month"
              value={data?.year_month || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Status</CustomText>
            <CustomInput
              disabled
              size="small"
              id="status"
              name="status"
              value={data?.status || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Employee Name</CustomText>
            <InputDate
              disabled
              id="nama_worker"
              name="nama_worker"
              value={data?.nama_worker || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Disbursed Date</CustomText>
            <CustomInput
              disabled
              size="small"
              id="disburse_date"
              name="disburse_date"
              value={data?.disburse_date?.split("T")[0] || ""}
            />
          </Stack>
        </Grid>
        <Grid item sm={4}>
          <Stack my={"10px"}>
            <CustomText variant="body1">Role</CustomText>
            <CustomInput
              disabled
              size="small"
              id="role_name"
              name="role_name"
              value={data?.role_name || ""}
            />
          </Stack>
          <Stack my={"10px"}>
            <CustomText variant="body1">Amount</CustomText>
            <NewInputNominal
              disabled
              size="small"
              id="total_cost"
              name="total_cost"
              value={data?.total_cost?.toString()}
            />
          </Stack>
        </Grid>
      </Grid>
      <Box mt={2}>
        <CustomDropbox
          existingFiles={attachment}
          viewOnly={true}
          onDelete={() => {}}
          onUpload={() => {}}
        />
      </Box>
    </CustomCard>
  );
}
