import { create } from "zustand";

// Create a store that store the layout state
interface LayoutState {
  // Define the layout state
  isSidebarOpen: boolean;
  // Define the layout actions
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
}

// Create the layout store
export const useLayoutStore = create<LayoutState>((set) => ({
  isSidebarOpen: false,
  openSidebar: () => set(() => ({ isSidebarOpen: true })),
  closeSidebar: () => set(() => ({ isSidebarOpen: false })),
  toggleSidebar: () =>
    set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
}));
