import React from "react";
import BreadCrumbs from "src/components/navigators/BreadCrumb";
import { Box, Divider } from "@mui/material";
import CustomTabPanel from "src/components/main/CustomTabPanel";
import Detail from "./components/Detail";
import { useLoaderData } from "react-router-dom";
import CustomText from "src/components/main/CustomText";
import CustomTabs, { CustomTab } from "src/components/main/CustomTabs";
import Process from "src/pages/item/ItemDetail/components/Process";
import CustomDivider from "src/components/main/CustomDivider";
import PageTitle from "src/components/main/CustomText/PageTitle";

function ItemDetail() {
  // const data = useLoaderData();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <PageTitle>Item Detail</PageTitle>
      <Divider />
      <CustomTabs value={value} onChange={handleChange}>
        <CustomTab label="Detail" />
        <CustomTab label="Process" />
      </CustomTabs>
      <CustomTabPanel value={value} index={0}>
        <Detail />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Process />
      </CustomTabPanel>
    </Box>
  );
}

export default ItemDetail;
