import React, { useEffect } from "react";
import BreadCrumbs from "../../../components/navigators/BreadCrumb";
import { Box } from "@mui/material";
import { useState } from "react";
import Detail from "./components/Detail";
import colors from "src/themes/colors";
import { useLocation, useNavigate } from "react-router-dom";
import GroupTaskDetail from "./components/GroupTaskDetail";
import CustomButton from "src/components/main/CustomButton";
import { GetTaskDetail, UpdateTaskDataGan } from "src/api/task";
import NotificationAlert from "src/components/main/Alert";
import { HideLoading, ShowLoading } from "src/components/main/Loading";
import PageTitle from "src/components/main/CustomText/PageTitle";
import TaskGroupingDetail from "./components/detail_grouping";

function TaskDetail() {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});

  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const fetchTaskDetail = async () => {
    try {
      ShowLoading({});
      const response = await GetTaskDetail(id!);
      setFormData(response?.data);
      setInitialData(response?.data);
    } catch (error: any) {
      console.error("Error fetching task detail:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      HideLoading();
    }
  };

  useEffect(() => {
    fetchTaskDetail();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      console.log("TASK DETAIL GASSSSS", formData);
      const response = await UpdateTaskDataGan(formData);
      await fetchTaskDetail();
      handleDiscard();
    } catch (error: any) {
      console.error("Error create order:", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDiscard = () => {
    setIsEdit(false);
    setFormData(initialData);
    console.log("Dara", initialData);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };
  return (
    <>
      {formData?.task_list?.length > 0 ? (
        <TaskGroupingDetail />
      ) : (
        <Box component="form" width={"100%"} onSubmit={handleSubmit}>
          <PageTitle>Task Detail</PageTitle>
          <Detail value={formData} disabled={!isEdit} onChange={handleChange} />
          <Box
            mt={4}
            display={"flex"}
            width={"100%"}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "flex-end",
              alignItems: {
                xs: "flex-start",
                md: "flex-end",
              },
              gap: {
                xs: 1,
                md: 2,
                lg: 3,
              },
            }}>
            {isEdit ? (
              <>
                <CustomButton
                  variant="outlined"
                  customType="cancel"
                  onClick={handleDiscard}>
                  Discard Changes
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: colors?.primary200 }}>
                  Save Changes
                </CustomButton>
              </>
            ) : (
              <CustomButton
                variant="contained"
                style={{ backgroundColor: colors?.primary200 }}
                onClick={handleEdit}>
                Edit
              </CustomButton>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default TaskDetail;
