import { Badge, Box, MenuItem, Stack } from "@mui/material";
import InputDate from "src/components/main/Datepicker";
import Calendar from "./components/Calendar";
import CustomText from "src/components/main/CustomText";
import CustomButton from "src/components/main/CustomButton";
import CustomInput from "src/components/main/CustomInput";
import { taskType } from "src/utils/paramData";
import { useEffect, useState } from "react";
import PageTitle from "src/components/main/CustomText/PageTitle";
import CustomCard from "src/components/main/CustomCard";

export default function TaskManagement() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [badge, setBadge] = useState<string>("0");
  const [filter, setFilter] = useState<TaskFilter>({
    start_date: "",
    end_date: "",
  });
  const [tempStartDate, setTempStartDate] = useState<string>("");
  const [tempEndDate, setTempEndDate] = useState<string>("");

  useEffect(() => {
    if (tempStartDate && tempEndDate) {
      setFilter((prev: any) => {
        return {
          ...prev,
          ["start_date"]: tempStartDate,
          ["end_date"]: tempEndDate,
        };
      });
    }
    if (tempStartDate === "" && tempEndDate === "") {
      setFilter((prev: any) => {
        return {
          ...prev,
          ["start_date"]: "",
          ["end_date"]: "",
        };
      });
    }
  }, [tempStartDate, tempEndDate]);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "start_date") {
      setTempStartDate(value);
      value > tempEndDate && tempEndDate !== "" && setTempEndDate(value);
    } else if (name === "end_date") {
      setTempEndDate(value);
    } else {
      setFilter((prev: any) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const all = { value: "all", label: "All" };
  const allTaskType = [all, ...taskType];

  return (
    <Box>
      <PageTitle>Task Management</PageTitle>
      <CustomCard text="Task Management">
        <Box
          width={"100%"}
          display={"flex"}
          flex-direction={"row"}
          justifyContent={"space-between"}
        >
          <Box
            display={"flex"}
            flex-direction={"row"}
            justifyContent={"space-between"}
            gap={2}
          >
            <Stack width={200}>
              <CustomText variant="body1" gutterBottom>
                Start Date
              </CustomText>
              <InputDate
                name="start_date"
                id="start_date"
                value={tempStartDate}
                onChange={handleFilter}
              />
            </Stack>
            <Stack width={200}>
              <CustomText variant="body1" gutterBottom>
                End Date
              </CustomText>
              <InputDate
                name="end_date"
                id="end_date"
                minDate={tempStartDate}
                value={tempEndDate}
                onChange={handleFilter}
              />
            </Stack>
            {/* <Stack>
              <CustomText variant="body1" gutterBottom>
                Task Type
              </CustomText>
              <CustomInput
                sx={{ width: 150 }}
                size="small"
                id="task_type"
                name="task_type"
                select
                onChange={handleFilter}
                defaultValue={allTaskType[0].value}
              >
                {allTaskType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack> */}
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            spacing={2}
          >
            <Badge color="primary" badgeContent={badge || "0"}>
              <CustomButton
                variant="contained"
                onClick={() => setOpenDialog(!openDialog)}
                sx={{ width: 150 }}
              >
                {openDialog ? "Hide Task List" : "Show Task List"}
              </CustomButton>
            </Badge>
          </Stack>
        </Box>
        <Calendar
          openDialog={openDialog}
          setOpenDialog={() => setOpenDialog(!openDialog)}
          setBadge={setBadge}
          filter={filter}
        />
      </CustomCard>
    </Box>
  );
}
