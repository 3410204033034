import { Stack } from "@mui/material";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import { useEffect, useState } from "react";
import { GetItemDetail } from "src/api/item";
import { useLocation } from "react-router-dom";
import { getCurrentTime } from "src/utils/formatter";
import Offer from "src/pages/item/ItemDetail/components/Offer";

export default function SupplierOffer({ refetch }: any) {
  const [formData, setFormData] = useState<any>({});
  const [offer, setOffer] = useState<any>([]);

  const id = useLocation().pathname.split("/").pop();

  const fetchItemDetail = async () => {
    const response = await GetItemDetail(id!);

    setFormData(response?.data?.item);
    setOffer(response?.data?.offer);
  };

  useEffect(() => {
    fetchItemDetail();
  }, []);

  return (
    <CustomPaper style={{ padding: 20, marginBottom: 20, width: "70%", height: "fit-content" }}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
        mb={2}
      >
        <CustomText variant="body1">Supplier Offerings</CustomText>
        <CustomText variant="body2">{getCurrentTime()}</CustomText>
      </Stack>
      {offer?.length === 0 && (
        <CustomText variant="body1" fontWeight={"bold"}>
          No Offers from Supplier
        </CustomText>
      )}
      {offer?.map((item: any) => (
        <Offer
          key={item.id}
          data={item}
          starting_price={formData?.starting_price}
          refetch={refetch}
        />
      ))}
    </CustomPaper>
  );
}
