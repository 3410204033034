import { Box, InputAdornment, MenuItem, Stack } from "@mui/material";
import CustomInput from "src/components/main/CustomInput";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import {
  currencies,
  getCurrencySymbol,
  paymentMethod,
} from "src/utils/paramData";
import CustomButton from "src/components/main/CustomButton";
import CustomDropbox from "../CustomDropbox";
import { useState, useCallback, useEffect } from "react";
import { getDataPelunasan, orderRepayment } from "src/api/order";
import { useLocation } from "react-router-dom";
import NewInputNominal from "../NewInputNominal";
import { getCurrentTime, parseAmount } from "src/utils/formatter";
import ModalConfirm from "../ModalConfirm";

interface Props {
  refetch: () => void;
}
export default function CompletePayment({ refetch }: Props) {
  const [paymentData, setPaymentData] = useState<any>({});
  const [data, setData] = useState<OrderCompletePaymentData>(
    {} as OrderCompletePaymentData
  );
  const [openModalSubmit, setOpenModalSubmit] = useState<boolean>(false);

  const id = useLocation().pathname.split("/").pop();

  const fetchDataPelunasan = async () => {
    const res = await getDataPelunasan(id!);
    setData({
      ...res?.data,
      amount: res?.data?.amount?.toString(),
      payment_method: paymentMethod?.filter(
        (item: any) => item?.currency === res?.data?.currency
      )[0].value,
    });
  };

  useEffect(() => {
    fetchDataPelunasan();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(name, value);
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const fileReader = useCallback((acceptedFiles: any[]) => {
    const fileDataArray: any[] = [];

    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr: any = reader.result;
        const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
        if (matches) {
          const mimeType = matches[1];
          const base64Data = matches[2];

          fileDataArray.push({
            bukti_payment_base64: base64Data,
            tipe_file: mimeType.split("/")[1],
          });

          if (fileDataArray.length === acceptedFiles.length) {
            setPaymentData((prevData: any) => ({
              ...prevData,
              attachments: [...(prevData.attachments || []), ...fileDataArray],
            }));
          }
        } else {
          console.error("Invalid file data");
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handlePaymentFileUpload = (acceptedFiles: any[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setPaymentData((prevData: any) => {
        const updatedAttachments = [...prevData.attachments];
        updatedAttachments.splice(index, 1);
        return {
          ...prevData,
          attachments: updatedAttachments,
        };
      });
    },
    [setPaymentData]
  );

  const handleSubmit = async () => {
    const req: ReqOrderCompletePayment = {
      order_id: data?.order_id,
      payment_cost: parseAmount(data?.amount),
      payment_currency: data?.currency,
      payment_method: data?.payment_method,
      bukti_payment: paymentData?.attachments,
    };

    await orderRepayment(req);
    await refetch();
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalSubmit(true);
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleFormSubmit}
      sx={{ width: { xs: "70%", md: "60%", lg: "50%" } }}
    >
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            Upload Customer Complete Payment 30%
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Box width={"80%"}>
          <Stack
            direction={"row"}
            display={"flex"}
            mt={2}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Stack width={"50%"}>
              <CustomText variant="body1">Currency</CustomText>
              <CustomInput
                required
                disabled
                size="small"
                id="currency"
                name="currency"
                onChange={handleChange}
                select
                value={data?.currency || currencies[0].value}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack width={"50%"}>
              <CustomText variant="body1">Payment Method</CustomText>
              <CustomInput
                required
                size="small"
                id="payment_method"
                name="payment_method"
                select
                onChange={handleChange}
                value={data?.payment_method}
              >
                {paymentMethod
                  .filter((item) => item.currency === data?.currency)
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </CustomInput>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            display={"flex"}
            mt={2}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Stack width={"50%"}>
              <CustomText variant="body1">Down Payment</CustomText>
              <NewInputNominal
                required
                disabled
                value={data?.payment_dp?.toString()}
                onChange={handleChange}
                size="small"
                id="payment_dp"
                name="payment_dp"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={"50%"}>
              <CustomText variant="body1">Remaining Payment</CustomText>
              <NewInputNominal
                required
                disabled
                value={data?.sisa_payment?.toString()}
                onChange={handleChange}
                size="small"
                id="sisa_payment"
                name="sisa_payment"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
          <Stack mt={2}>
            <CustomText variant="body1">Final Amount</CustomText>
            <NewInputNominal
              required
              disabled
              value={data?.amount?.toString()}
              onChange={handleChange}
              size="small"
              id="amount"
              name="amount"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(data?.currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box mt={2}>
            <CustomText variant="body1" gutterBottom>
              Proof of Payment
            </CustomText>
            <CustomDropbox
              files={paymentData?.attachments}
              filesType="base64"
              onUpload={handlePaymentFileUpload}
              deleteByIndex={true}
              onDeleteByIndex={handlePaymentFileDelete}
            />
          </Box>
          <Box mt={4}>
            <CustomText variant="body1">Send a Message</CustomText>
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: 1, md: 2 },
              }}
              mt={2}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Stack>
                <CustomButton variant="contained">WA.me Link</CustomButton>
              </Stack>
              <Stack>
                <CustomText variant="body1">or</CustomText>
              </Stack>
              <Stack>
                <CustomButton variant="contained">
                  Copy to clipboard
                </CustomButton>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box textAlign={"right"} mt={2}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure to do this action?"
        open={openModalSubmit}
        onClose={() => setOpenModalSubmit(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
