import styled from "@emotion/styled";
import { Accordion, AccordionProps } from "@mui/material";
import { useMemo } from "react";
import useThemeStore from "src/store/themeStore";
import { hexToRgba } from "src/utils/utils";

export default function FilterAccordion(props: AccordionProps) {
  const {...otherProps } = props ?? {};

  const { theme } = useThemeStore();

  const CustomAccordion = useMemo(
    () =>
      styled(Accordion)(() => ({
        border: `1px solid ${hexToRgba(theme?.tableDivider, 0.5)}`,
        "&.MuiAccordionSummary-root": {
          padding: 0,
          margin: 0,
        },
        backgroundColor: theme?.bgAccordion,
        color: theme?.inputLabel,
      })),
    [theme]
  );

  return <CustomAccordion {...otherProps} />;
}
