import { Autocomplete, Box, Card, CircularProgress, Divider, Grid, InputAdornment, Link, List, ListItem, ListItemText, MenuItem, Stack } from "@mui/material";
import { Console } from "console";
import { Fragment, useEffect, useState } from "react";
import { InquiryUserList, SearchMarketing, SearchPainter } from "src/api/user";
import CustomCard from "src/components/main/CustomCard";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import InputDate from "src/components/main/Datepicker";
import NewInputNominal from "src/components/main/NewInputNominal";
import useThemeStore from "src/store/themeStore";
import { priorityLevel } from "src/utils/paramData";
import { StringLiteralType } from "typescript";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  value: any;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isGrouping?:boolean;
  showCost?:boolean;
  groupCost?:number;
}

export default function Detail({ value, disabled = false, onChange,isGrouping,showCost = false ,groupCost = 0}: Props) {
  const { theme } = useThemeStore();
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [data, setData] = useState<any>({ });

  function formatCurrencyIDR(value: string | number): string {
    // Convert the value to a float, fallback to 0 if conversion fails
    const numericValue: number = isNaN(parseFloat(value as string)) ? 0 : parseFloat(value as string);
  
    // Format the number as IDR currency
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,  // Adjust fraction digits if necessary
    }).format(numericValue);
  }
  
  

  const fetchSearchAssignee = async (key: string) => {
    if (key === "" || key === null || key === undefined) {
      return;
    }

    setIsLoading(true);
    const response = await SearchPainter();
    response?.data ? setAssigneeList(response?.data) : setAssigneeList([]);
    setIsLoading(false); // Reset loading state after API call
  };

  const debouncedFetch = useDebouncedCallback(fetchSearchAssignee, 1000);



  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchSearchAssignee("initialKey"); // Use a suitable key or pass an empty string if needed
    };

    fetchInitialData();
  }, []); 

  const handleInputChange = (newInputValue: string) => {
    debouncedFetch(newInputValue);
  };

  const handleSelect = (event: any, newValue: any | null) => {
    setSelectedUser(newValue);
    setData((prevData: any) => ({ ...prevData, ["user_id"]: newValue?.id }));
    console.log("Dataaaaa ===>",newValue?.id)
    if (newValue?.id) {
      const syntheticEvent = {
        target: {
          name: 'user_id',    // The input field name
          value: newValue.id, // The selected value
        },
      } as React.ChangeEvent<HTMLInputElement>;
      if (onChange) {
        onChange(syntheticEvent)
      }
    }

  };

  const filterOptions = (options: any[], { inputValue }: any) => {
    const lowercasedInput = inputValue.toLowerCase();

    return options.filter((option: { username: string; display_name:string;email:string;phone_number:string }) =>
    option.display_name.toLowerCase().includes(lowercasedInput) ||
    option.email.toLowerCase().includes(lowercasedInput) ||
    option.phone_number.toLowerCase().includes(lowercasedInput)    );
  };

  return (
    <Box>
      <Box>
        <Stack direction={"row"}>
          <CustomText variant="h6" fontWeight={"bold"} width={150}>
            Task ID
          </CustomText>
          <CustomText variant="h6" fontWeight={"bold"}>
            : &ensp;
          </CustomText>
          <CustomText variant="h6" fontWeight={"bold"}>
            {value?.id}
          </CustomText>
        </Stack>
        {!isGrouping && (
  <Stack direction={"row"}>
    <CustomText variant="h6" fontWeight={"bold"} width={150}>
      Item ID
    </CustomText>
    <CustomText variant="h6" fontWeight={"bold"}>
      : &ensp;
    </CustomText>
    <Link
      href={`/item/${value?.item_id}`}
      sx={{
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
          textDecorationColor: theme?.inputLabel,
        },
      }}
    > 
      <CustomText variant="h6" fontWeight={"bold"}>
        {value?.item_id}
      </CustomText>
    </Link>
  </Stack>
  
  
)}
{showCost && (
  <Stack direction={"row"}>
          <CustomText variant="h6" fontWeight={"bold"} width={150}>
            Group Cost
          </CustomText>
          <CustomText variant="h6" fontWeight={"bold"}>
            : &ensp;
          </CustomText>
          <CustomText variant="h6" fontWeight={"bold"}>
            {formatCurrencyIDR(groupCost)}
          </CustomText>
        </Stack>
)}    
      </Box>
      <CustomCard text="Task Detail">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            {/* <Stack my={"10px"}>
              <CustomText variant="body1">Name</CustomText>
              <CustomInput
                fullWidth
                onChange={onChange}
                disabled
                size="small"
                id="name"
                name="name"
                value={value?.name || ""}
              />
            </Stack> */}
            <Stack my={"10px"}>
              <CustomText variant="body1">Priority</CustomText>
              <CustomInput
                onChange={onChange}
                disabled={disabled}
                size="small"
                id="priority"
                name="priority"
                value={value?.priority || priorityLevel[0].value}
                select
              >
                {priorityLevel.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Start Date</CustomText>
              <InputDate
                onChange={onChange}
                disabled={disabled}
                id="start_date"
                name="start_date"
                value={value?.start_date?.split("T")[0] || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Assign To</CustomText>
              {/* <CustomInput
                onChange={onChange}
                disabled
                size="small"
                id="assignee"
                name="assignee"
                value={value?.display_name || ""}
              /> */}
              <Autocomplete
            disabled={disabled}
            options={assigneeList}
            noOptionsText="Worker Not Found"
            fullWidth
            autoComplete
            getOptionLabel={(option: { username: string; email: string;display_name:string }) =>
              option?.display_name
            }
            isOptionEqualToValue={(option, value) =>
              option.username === value.username ||
              option.display_name === value.username
            }
            filterOptions={filterOptions}
            loading={isLoading}
            open={open}
            value={selectedUser}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleSelect}
            // onInputChange={(_, newInputValue) =>
            //   handleInputChange(newInputValue)
            // }
            id="user_id"
            componentName="user_id"
            renderInput={(params) => (
              <CustomInput
                {...params}
                id="user_id"
                name="user_id"
                placeholder={value?.display_name}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component={"li"}
                {...props}
                sx={{
                  backgroundColor: theme?.bgSecondary,
                  color: theme?.inputText,
                  "&.MuiAutocomplete-option.Mui-focused": {
                    backgroundColor: theme?.bgPrimary,
                  },
                }}
              >
                <List sx={{ width: "100%" }}>
                  <ListItem>
                    <ListItemText
                      primary={<CustomText>{option?.display_name}</CustomText>}
                      secondary={
                        <Fragment>
                          <CustomText>{option?.email}</CustomText>
                          <CustomText>{option?.phone_number}</CustomText> {/* Third line added */}
                        </Fragment>
                      }
                    />
                  </ListItem>
                  <Divider sx={{ borderBottomWidth: 4 }} />
                </List>
              </Box>
            )}
          />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">End Date</CustomText>
              <InputDate
                onChange={onChange}
                disabled={disabled}
                id="end_date"
                name="end_date"
                value={value?.end_date?.split("T")[0] || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Role</CustomText>
              <CustomInput
                onChange={onChange}
                disabled
                size="small"
                id="role"
                name="role"
                value={value?.role_name || ""}
              />
            </Stack>
          </Grid>
        </Grid>
      </CustomCard>
      <CustomCard text="Calculated Painter Fee">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Painter Level</CustomText>
              <CustomInput
                onChange={onChange}
                disabled
                size="small"
                id="level"
                name="level"
                value={value?.level || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Man Days</CustomText>
              <CustomInput
                onChange={onChange}
                disabled
                size="small"
                id="mandays"
                name="mandays"
                value={value?.main_day || ""}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Task Points</CustomText>
              <CustomInput
                onChange={onChange}
                disabled
                size="small"
                id="taskPoints"
                name="taskPoints"
                value={value?.task_type || ""}
              />
            </Stack>
          </Grid>
        </Grid>
        {!showCost && (
          <Stack my={"10px"}>
          <CustomText variant="body1">Total Task Cost</CustomText>
          <NewInputNominal
            //   onChange={onChange}
            disabled
            size="small"
            id="cost"
            name="cost"
            value={value?.cost?.toString() || ""}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">Rp</InputAdornment>
              ),
            }}
          />
        </Stack>
        )}
      </CustomCard>
    </Box>
  );
}
