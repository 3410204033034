import {
  Box,
  Stack,
  Grid,
  MenuItem,
  InputAdornment,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import { DropBox } from "src/components/main/Dropbox";
import NewInputNominal from "src/components/main/NewInputNominal";
import { base64ToURL } from "src/utils/decoder";
import DeleteIcon from "@mui/icons-material/Delete";
import { DecimalFormatter } from "src/utils/formatter";
import {
  currencies,
  getCurrencySymbol,
  orderFrom,
  paymentMethod,
} from "src/utils/paramData";
import CustomCard from "src/components/main/CustomCard";
import InputDate from "src/components/main/Datepicker";

interface Props {
  data: any;
  payment: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpload: (files: any[]) => void;
  onDelete: (index: number) => void;
}

export default function Payment({
  data,
  payment,
  onChange,
  onUpload,
  onDelete,
}: Props) {
  return (
    <CustomCard text="Order & Payment Data">
      <Box>
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Order Deadline</CustomText>
              <InputDate
                name="deadline"
                id="deadline"
                value={data?.deadline}
                onChange={onChange}
                disablePast
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Currency</CustomText>
              <CustomInput
                required
                onChange={onChange}
                size="small"
                id="currency"
                name="currency"
                select
                defaultValue={
                  data?.currency ? data?.currency : currencies[0].value
                }
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Payment Method</CustomText>
              <CustomInput
                size="small"
                id="payment_method"
                name="payment_method"
                select
                onChange={onChange}
                value={data?.payment_method || paymentMethod[0].value}
              >
                {paymentMethod
                  .filter((item) => item.currency === data?.currency)
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </CustomInput>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Order From</CustomText>
              <CustomInput
                required
                onChange={onChange}
                size="small"
                id="order_from"
                name="order_from"
                select
                defaultValue={
                  data?.order_from ? data?.order_from : orderFrom[0].value
                }
              >
                {orderFrom.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Amounts</CustomText>
              <NewInputNominal
                required
                disabled
                size="small"
                id="amount"
                name="amount"
                value={DecimalFormatter(data?.amount.toString())}
                onChange={onChange}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1">Referal Code</CustomText>
              <CustomInput
                size="small"
                id="referal_code"
                name="referal_code"
                value={data?.referal_code}
                onChange={onChange}
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1">Down Payment</CustomText>
              <NewInputNominal
                disabled
                size="small"
                id="payment_nominal"
                name="payment_nominal"
                value={DecimalFormatter(data?.payment_nominal.toString())}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency) ||
                        getCurrencySymbol(currencies[0].value)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>

        <Stack mt={2}>
          <CustomText variant="body1">Proof of Payment</CustomText>
          <Box
            mt={1}
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={"row"}
          >
            {payment?.attachments?.length > 0 &&
              payment?.attachments?.map((item: any, index: number) => (
                <ImageListItem
                  key={index}
                  sx={{ marginRight: 2, width: 200, height: 200 }}
                >
                  <img
                    src={
                      base64ToURL(item?.bukti_payment_base64) ||
                      "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
                    }
                    loading="lazy"
                  />
                  <ImageListItemBar
                    position="top"
                    actionIcon={
                      <IconButton
                        sx={{ color: "white" }}
                        aria-label={`info about ${item.title}`}
                      >
                        <DeleteIcon onClick={() => onDelete(index)} />
                      </IconButton>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              ))}
            {!payment?.attachments || payment?.attachments?.length < 5 ? (
              <DropBox onDrop={(file) => onUpload(file)} />
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Box>
    </CustomCard>
  );
}
