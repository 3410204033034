import { Box, Collapse, Link, Stack, Tooltip } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useCallback, useEffect, useState } from "react";
import CustomText from "../CustomText";
import ClientApproveDesign from "../Process/ClientApproveDesign";
import UploadDesign from "../Process/UploadDesign";
import { GetItemLog } from "src/api/item";
import NotificationAlert from "../Alert";
import { useLocation } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import Information from "../Process/Information";
import ShippingFromClient from "../Process/ShippingFromClient";
import CreateTask from "../Process/CreateTask";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import MiddlePayment from "../Process/MiddlePayment";
import PendingIcon from "@mui/icons-material/Pending";
import CustomPaper from "../CustomPaper";
import Container from "./components/Container";
import PreMarketing from "../Process/PreMarketing";
import { useProcessStore } from "src/store/processStore";
import ArrivalConfirmation from "../Process/ArrivalConfirmation";
import ClientApproveItem from "../Process/ClientApproveItem";
import SupplierOffer from "../Process/SupplierOffer";
import AdminApproveTask from "../Process/AdminApproveTask";
import PostMarketing from "../Process/PostMarketing";
import MaterialCollection from "../Process/MaterialCollection";
import LeatherFromSupplier from "../Process/LeatherFromSupplier";

export default function TimelineProcess() {
  const [itemLog, setItemLog] = useState<any[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taskAction, setTaskAction] = useState<any>({});
  const [detailItem, setDetailItem] = useState<DetailItem>({} as DetailItem);
  const [dataTask, setDataTask] = useState<any>({});
  const [allTaskCompleted, setAllTaskCompleted] = useState<boolean>(false);
  const [postMaketingTask, setPostMaketingTask] = useState<boolean>(false);
  const [countTaskPainter, setCountTaskPainter] = useState<number>(0);
  const [countTaskReconer, setCountTaskReconer] = useState<number>(0);

  const id = useLocation().pathname.split("/").pop();
  const { marketingTaskPre, setMarketingTask, setMarketingTaskPre } =
    useProcessStore();

  const fetchItemLog = async () => {
    try {
      setIsLoading(true);
      const response = await GetItemLog(id!);
      setTaskAction(response?.data?.TaskNeedAction);
      setMarketingTask(response?.data?.marketing_task);
      setMarketingTaskPre(response?.data?.marketing_task_pre);
      setItemLog(response?.data?.LogItem);
      setDataTask(response?.data?.DataTask);
      setAllTaskCompleted(response?.data?.AllTaskCompleted);
      setPostMaketingTask(response?.data?.marketing_task);
      setDetailItem(response?.data?.detail_item);
      setCountTaskPainter(response?.data?.count_task_painter);
      setCountTaskReconer(response?.data?.count_task_reconer);
    } catch (error: any) {
      console.error("Error fetching itemLog detail:", error);
      NotificationAlert({ message: error.message, status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data
  useEffect(() => {
    fetchItemLog();
  }, []);

  // Logic for determine which step to render, related action component
  const renderStep = useCallback(
    (data: any) => {
      const step = data?.step_code;
      const status = data?.status;

      if (step === "DSN-UPL") {
        let history = [];
        if (data?.history?.length > 0) {
          history = data?.history;
        }
        return <UploadDesign data={history} refetch={fetchItemLog} />;
      }
      if (step === "TASK-DSN-QC" && status !== "COMPLETED") {
        return (
          <ClientApproveDesign designType="CUSTOM" refetch={fetchItemLog} />
        );
      }
      if (step === "DSN-QC-CL" && status !== "COMPLETED") {
        return (
          <ClientApproveDesign designType="READY" refetch={fetchItemLog} />
        );
      }
      if (step === "CS-IT-SP") {
        return (
          <ShippingFromClient detailItem={detailItem} refetch={fetchItemLog} />
        );
      }
      if (step === "CS-IT-QC" && status !== "COMPLETED") {
        return (
          <ArrivalConfirmation detailItem={detailItem} refetch={fetchItemLog} />
        );
      }
      if (step === "TASK-PAINT" && status !== "COMPLETED") {
        if (taskAction?.id) {
          if (taskAction?.id?.startsWith("M")) {
            return (
              <CustomPaper sx={{ width: "50%", p: 2 }}>
                <ClientApproveItem
                  taskId={taskAction?.id}
                  refetch={fetchItemLog}
                />
              </CustomPaper>
            );
          } else {
            return (
              <AdminApproveTask taskData={taskAction} refetch={fetchItemLog} />
            );
          }
        }
        if (allTaskCompleted && countTaskPainter > 0) {
          if (postMaketingTask) {
            return <PostMarketing step={step} refetch={fetchItemLog} />;
          } else {
            return (
              <Information information={"Waiting Marketing to Submit Task"} />
            );
          }
        } else {
          return (
            <CustomPaper sx={{ width: "50%", p: 2 }}>
              <CreateTask type="p" refetch={fetchItemLog} />;
            </CustomPaper>
          );
        }
      }
      if (step === "TASK-DSN" && status !== "COMPLETED") {
        if (dataTask) {
          if (taskAction?.id) {
            return (
              <ClientApproveDesign
                taskId={taskAction?.id}
                designType="CUSTOM"
                refetch={fetchItemLog}
              />
            );
          } else {
            return <Information information={"Waiting Task to be Completed"} />;
          }
        } else {
          return (
            <CustomPaper sx={{ width: "50%", p: 2 }}>
              <CreateTask type="d" refetch={fetchItemLog} />;
            </CustomPaper>
          );
        }
      }
      if (step === "SUP-BID" && status !== "COMPLETED") {
        return (
          <Box display={"flex"} flexDirection={"row"} gap={2} width={"100%"}>
            <SupplierOffer refetch={fetchItemLog} />

            {!detailItem?.LeatherImage?.length &&
              detailItem?.LeatherFromClient &&
              (!detailItem?.LetaherResi ? (
                <ShippingFromClient
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ) : (
                <ArrivalConfirmation
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ))}
            {!detailItem?.LeatherImage?.length &&
              !detailItem?.LeatherFromClient &&
              !detailItem?.LetaherResi && !detailItem?.ItemID?.startsWith("P") && (
                <LeatherFromSupplier
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              )}
          </Box>
        );
      }
      if (step === "SUP-SP") {
        return (
          <Box display={"flex"} flexDirection={"row"} gap={2} width={"100%"}>
            <Information information="Item is Being Shipped From Supplier" />

            {!detailItem?.LeatherImage?.length &&
              detailItem?.LeatherFromClient &&
              (!detailItem?.LetaherResi ? (
                <ShippingFromClient
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ) : (
                <ArrivalConfirmation
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ))}
            {!detailItem?.LeatherImage?.length &&
              !detailItem?.LeatherFromClient &&
              !detailItem?.LetaherResi && !detailItem?.ItemID?.startsWith("P") && (
                <LeatherFromSupplier
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              )}
          </Box>
        );
      }
      if (step === "SUP-QC" && status !== "COMPLETED")  {
        return (
          <Box display={"flex"} flexDirection={"row"} gap={2} width={"100%"}>
            <Information information={status} />
            {!detailItem?.LeatherImage?.length &&
              detailItem?.LeatherFromClient &&
              (!detailItem?.LetaherResi ? (
                <ShippingFromClient
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ) : (
                <ArrivalConfirmation
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ))}
            {!detailItem?.LeatherImage?.length &&
              !detailItem?.LeatherFromClient &&
              !detailItem?.LetaherResi && !detailItem?.ItemID?.startsWith("P") && (
                <LeatherFromSupplier
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              )}
          </Box>
        );
      }
      if (step === "MIDDLE-PAYMENT" && status !== "COMPLETED") {
        return <MiddlePayment refetch={fetchItemLog} />;
      }
      if (step === "MRT-PRE" && status !== "COMPLETED") {
        if (taskAction?.id) {
          return (
            <AdminApproveTask taskData={taskAction} refetch={fetchItemLog} />
          );
        }
        if (marketingTaskPre) {
          return (
            <CustomPaper sx={{ width: "50%", p: 2 }}>
              <PreMarketing refetch={fetchItemLog} />;
            </CustomPaper>
          );
        } else {
          return <Information information="Waiting Marketing to Submit Task" />;
        }
      }
      if (step === "MAT-COL") {
        return (
          <Box display={"flex"} flexDirection={"row"} gap={2} width={"100%"}>
            <MaterialCollection
              detailItem={detailItem}
              refetch={fetchItemLog}
            />
            {!detailItem?.LeatherImage?.length &&
              detailItem?.LeatherFromClient &&
              (!detailItem?.LetaherResi ? (
                <ShippingFromClient
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ) : (
                <ArrivalConfirmation
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              ))}
            {!detailItem?.LeatherImage?.length &&
              !detailItem?.LeatherFromClient &&
              !detailItem?.LetaherResi && (
                <LeatherFromSupplier
                  detailItem={detailItem}
                  refetch={fetchItemLog}
                />
              )}
          </Box>
        );
      }
      if (step === "TASK-DECON") {
        if (status === "Waiting Task Decon & Recon ") {
          return (
            <CustomPaper sx={{ width: "50%", p: 2 }}>
              <CreateTask type="r" refetch={fetchItemLog} />;
            </CustomPaper>
          );
        }
        if (taskAction?.id) {
          if (taskAction?.id?.startsWith("M")) {
            return (
              <CustomPaper sx={{ width: "50%", p: 2 }}>
                <ClientApproveItem
                  taskId={taskAction?.id}
                  refetch={fetchItemLog}
                />
              </CustomPaper>
            );
          }
          if (
            taskAction?.shipping_data?.WorkerShipData?.status ===
            "Received in Logistic"
          ) {
            return (
              <AdminApproveTask
                logisticQCAttachment={taskAction?.shipping_data?.attach}
                taskData={taskAction}
                refetch={fetchItemLog}
              />
            );
          }
        }
        if (allTaskCompleted && countTaskReconer > 0) {
          if (postMaketingTask) {
            return <PostMarketing step={step} refetch={fetchItemLog} />;
          } else {
            return (
              <Information information={"Waiting Marketing to Submit Task"} />
            );
          }
        } else {
          return (
            <Information
              information={"Waiting Decon Recon Process To Be Completed"}
            />
          );
        }
      }
      if (step === "COMPLETE") {
        return (
          <Information information="Item Process Completed. Please Check Order Process for Repayment" />
        );
      } else {
        return <Information information="An Error Occured. Please Try Again" />;
      }
    },
    [taskAction, dataTask, postMaketingTask, allTaskCompleted, countTaskReconer, countTaskPainter]
  );

  const handleExpand = (stageName: string, index: number) => {
    const key = `${stageName}${index}`;
    setExpandedIndex((prevIndices) =>
      prevIndices.includes(key)
        ? prevIndices.filter((i) => i !== key)
        : [...prevIndices, key]
    );
  };

  const generateLink = useCallback((data: any) => {
    let link = "";
    let id = "";

    // Generate link to view task detail
    if (data && data?.task_id && data?.task_worker) {
      id = data?.task_id;
      link = `/task/task-list/${id}`;
    }

    // Since its an unassigned task, generate link to calendar
    if (data && data?.task_id && !data?.task_worker) {
      id = data?.task_id;
      link = `/task/task-management`;
    }
    return id ? (
      <Link
        href={link}
        target="_blank"
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        &nbsp;-&ensp;{id}&nbsp;
        <Tooltip title="View Detail">
          <LaunchIcon />
        </Tooltip>
      </Link>
    ) : null;
  }, []);

  return (
    <Box>
      <Timeline>
        {itemLog.map((item: any, index: number) => (
          <Box key={item.step_code}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  sx={{ margin: 0 }}
                  color={
                    index === itemLog.length - 1 && item.status !== "COMPLETED"
                      ? "warning"
                      : "success"
                  }
                >
                  {index === itemLog.length - 1 &&
                  item.status !== "COMPLETED" ? (
                    <PendingIcon color="warning" fontSize="medium" />
                  ) : (
                    <CheckCircleIcon color="success" fontSize="medium" />
                  )}
                </TimelineDot>
                <TimelineConnector
                  sx={{
                    bgcolor:
                      index === itemLog.length - 1 &&
                      item.status !== "COMPLETED"
                        ? "warning.main"
                        : "green",
                  }}
                />
              </TimelineSeparator>
              <TimelineContent sx={{ pr: 0, flex: 4 }}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={2}
                  alignContent={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleExpand(item.step_code, index)}
                >
                  <CustomText variant="subtitle1" fontWeight={"bold"}>
                    {item.step_desc}
                  </CustomText>
                  {expandedIndex.includes(`${item.step_code}${index}`) ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  )}
                </Box>
              </TimelineContent>
              <TimelineOppositeContent sx={{ flex: 1 }}>
                {item.created_at.split("T")[0]}
              </TimelineOppositeContent>
            </TimelineItem>
            {item.history.map((historyItem: any, historyIndex: number) => (
              <Collapse
                in={
                  expandedIndex.includes(`${item.step_code}${index}`) ||
                  (index === itemLog.length - 1 &&
                    expandedIndex.includes(`${item.step_code}${index}`))
                }
                key={historyIndex}
                unmountOnExit={false}
              >
                <TimelineItem>
                  <TimelineSeparator sx={{ ml: "12px" }}>
                    <TimelineDot
                      variant={
                        historyIndex === item.history.length - 1 &&
                        item.status !== "COMPLETED"
                          ? "outlined"
                          : "filled"
                      }
                      color={
                        index === itemLog.length - 1 &&
                        item.status !== "COMPLETED"
                          ? "warning"
                          : "success"
                      }
                    />
                    <TimelineConnector
                      sx={{
                        bgcolor:
                          index === itemLog.length - 1 &&
                          item.status !== "COMPLETED"
                            ? "warning.main"
                            : "green",
                      }}
                    />
                  </TimelineSeparator>
                  <TimelineContent sx={{ pr: 0, mb: 2, flex: 4 }}>
                    <Stack direction="row" gap={1}>
                      <CustomText variant="body1">
                        {historyItem.status_desc}
                      </CustomText>
                      {generateLink(historyItem?.extras)}
                    </Stack>
                    <Container
                      dataTask={dataTask}
                      historyItem={historyItem}
                      refetch={fetchItemLog}
                    />
                  </TimelineContent>
                  <TimelineOppositeContent sx={{ flex: 1 }}>
                    {historyItem.created_at.split("T")[0]}
                  </TimelineOppositeContent>
                </TimelineItem>
              </Collapse>
            ))}
          </Box>
        ))}
        {!isLoading && renderStep(itemLog?.[itemLog?.length - 1])}
      </Timeline>
    </Box>
  );
}
