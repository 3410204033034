import {
  Box,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  Stack,
} from "@mui/material";
import CustomPaper from "../CustomPaper";
import CustomText from "../CustomText";
import { getCurrentTime } from "src/utils/formatter";
import CustomInput from "../CustomInput";
import {
  currencies,
  getCurrencySymbol,
  paymentMethod,
} from "src/utils/paramData";
import { useCallback, useEffect, useState } from "react";
import NewInputNominal from "src/components/main/NewInputNominal";

import { DropBox } from "src/components/main/Dropbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { base64ToURL } from "src/utils/decoder";
import { useLocation } from "react-router-dom";
import useThemeStore from "src/store/themeStore";
import CustomButton from "../CustomButton";
import { getMiddlePaymentData, middlePayment } from "src/api/item";
import ModalConfirm from "../ModalConfirm";
import CustomDropbox from "../CustomDropbox";
import { useLoadingStore } from "src/store/loadingStore";

export default function MiddlePayment({ refetch }: any) {
  const [files, setFiles] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<MiddlePaymentData>({
    item_id: "",
    order_id: "",
    currency: currencies[0].value,
    payment_dp: 0,
    sisa_payment: 0,
  });

  const [payment_method, setPaymentMethod] = useState<string>(
    paymentMethod[0].value
  );

  const id = useLocation().pathname.split("/").pop();
  const { showNotification } = useLoadingStore();

  const fetchData = async () => {
    const response = await getMiddlePaymentData(id!);
    response?.data ? setData(response?.data) : null;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fileReader = useCallback((acceptedFiles: any[]) => {
    const fileDataArray: any[] = [];

    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr: any = reader.result;
        // Extract MIME type and base64 data
        const matches = binaryStr.match(/^data:(.+);base64,(.+)$/);
        if (matches) {
          const mimeType = matches[1];
          const base64Data = matches[2];

          fileDataArray.push({
            bukti_payment_base64: base64Data,
            tipe_file: mimeType.split("/")[1],
          });

          if (fileDataArray.length === acceptedFiles.length) {
            setFiles((prevData: any) => ({
              ...prevData,
              attachments: [...(prevData.attachments || []), ...fileDataArray],
            }));
          }
        } else {
          console.error("Invalid file data");
        }
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handlePaymentFileUpload = (acceptedFiles: any[]) => {
    fileReader(acceptedFiles);
  };

  const handlePaymentFileDelete = useCallback(
    (index: number) => {
      setFiles((prevData: any) => {
        const updatedAttachments = [...prevData.attachments];
        updatedAttachments.splice(index, 1);
        return {
          ...prevData,
          attachments: updatedAttachments,
        };
      });
    },
    [setFiles]
  );

  const handleSubmit = async () => {
    const req: ReqMiddlePayment = {
      item_id: id!,
      currency: data?.currency,
      payment_method: payment_method,
      amount: data?.sisa_payment,
      bukti_payment: files?.attachments,
    };
    await middlePayment(req);
    await refetch();
  };

  const handleSubmitForm = () => {
    if (!files?.attachments) {
      showNotification("Please upload proof of payment", "error");
      return;
    }
    setOpenModal(true);
  };

  return (
    <Box sx={{ width: { xs: "65%", md: "60%", lg: "50%" } }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            Middle Payment
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Stack
          direction={"row"}
          display={"flex"}
          mt={2}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Stack width={"50%"}>
            <CustomText variant="body1">Currency</CustomText>
            <CustomInput
              size="small"
              id="currency"
              name="currency"
              select
              disabled
              value={data?.currency}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </CustomInput>
          </Stack>
          <Stack width={"50%"}>
            <CustomText variant="body1">Payment Method</CustomText>
            <CustomInput
              size="small"
              id="payment_method"
              name="payment_method"
              select
              value={payment_method || paymentMethod[0].value}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              {paymentMethod
                .filter((item) => item.currency === data?.currency)
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </CustomInput>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          display={"flex"}
          mt={2}
          justifyContent={"space-between"}
          spacing={2}
        >
          <Stack width={"100%"}>
            <CustomText variant="body1">Down Payment</CustomText>
            <NewInputNominal
              required
              disabled
              size="small"
              id="payment_dp"
              name="payment_dp"
              value={data?.payment_dp?.toString()}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(data?.currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={"100%"}>
            <CustomText variant="body1">Remaining Payment</CustomText>
            <NewInputNominal
              required
              disabled
              size="small"
              id="sisa_payment"
              name="sisa_payment"
              value={data?.sisa_payment?.toString()}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getCurrencySymbol(data?.currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
        <Stack mt={2}>
          <CustomText variant="body1">Proof of Payment</CustomText>
          <Box
            mt={1}
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={"row"}
          >
            <CustomDropbox
              files={files?.attachments}
              filesType="base64"
              onUpload={handlePaymentFileUpload}
              deleteByIndex={true}
              onDeleteByIndex={handlePaymentFileDelete}
              size={150}
            />
          </Box>
        </Stack>

        <Box
          mt={4}
          display={"flex"}
          justifyContent={"flex-end"}
          sx={{ gap: 2 }}
        >
          <CustomButton variant="contained" onClick={handleSubmitForm}>
            Submit
          </CustomButton>
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure you want to submit?"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
