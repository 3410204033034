import {
  Box,
  MenuItem,
  Pagination,
  Stack,
  SxProps,
  Table,
  TableContainer,
  TableProps,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSortableTable } from "src/hooks/useSortableTable";
import TableHeader from "./TableHeader";
import CustomTableBody from "./TableBody";
import useThemeStore from "src/store/themeStore";
import CustomText from "../CustomText";
import { hexToRgba } from "src/utils/utils";

interface Column {
  title: string;
  field: string;
  sortable: boolean;
  sortbyOrder?: string;
  align?: string;
  minWidth?: number;
}

interface RowData {
  [key: string]: any;
}

interface Props extends TableProps {
  sx?: SxProps;
  columns: Column[];
  data: RowData[];
  sortable?: boolean; //Sort FE, if true use sort function in useSortableTable, if false use sort from backend
  pagination?: boolean;
  filter?: FilterTable;
  onChangeFilter?: (name: string, value: any) => void;
  onClickDetail?: (id: string) => void;
  identifier?: string;
  stickyFirst?: boolean;
  checkbox?: boolean;
  selectedItem?: Array<string | number>;
  rowCount?: number;
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
}

function CustomTable(props: Props) {
  const {
    sx,
    columns,
    data = [],
    sortable = true,
    pagination = true,
    filter,
    onChangeFilter = () => {},
    onClickDetail,
    identifier,
    stickyFirst = false,
    checkbox = false,
    selectedItem = [],
    rowCount = 0,
    onSelectAll,
    onCheckboxChange,
    ...otherProps
  } = props;
  const { theme } = useThemeStore();

  const [tableData, handleSorting] = useSortableTable(data, columns);
  const [cellData, setCellData] = useState<any>([]);

  useEffect(() => {    
    if (tableData?.length > 0 && sortable) {
      setCellData(tableData);
    } else {
      setCellData(data);
    }
  }, [tableData]);

  useEffect(() => {
    setCellData(data);
  }, [data]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onChangeFilter("page", value);
  };

  const defaultColumn = columns.map((col: any) => {
    return {
      ...col,
      align: col.align || "left",
      originalValue: col.originalValue || col.field,
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: theme?.tableBg,
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <TableContainer sx={{ maxWidth: "100%", ...sx }}>
        <Table
          {...otherProps}
          sx={{
            minWidth: stickyFirst ? "120%" : "100%",
            tableLayout: "fixed",
          }}
        >
          <TableHeader
            sortable={sortable}
            columns={defaultColumn}
            handleSorting={handleSorting}
            sticky={stickyFirst}
            checkbox={checkbox}
            numSelected={selectedItem?.length}
            rowCount={rowCount}
            onSelectAll={onSelectAll}
            onChangeFilter={onChangeFilter}
            filter={filter}
          />
          <CustomTableBody
            onClickDetail={onClickDetail}
            columns={defaultColumn}
            tableData={cellData}
            identifier={identifier}
            sticky={stickyFirst}
            checkbox={checkbox}
            onCheckboxChange={onCheckboxChange}
            selectedItem={selectedItem}
          />
        </Table>
      </TableContainer>
      {pagination && (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          my={1}
          gap={20}
        >
          <Pagination
            count={filter?.total_page ?? 1}
            defaultPage={filter?.page ?? 1}
            page={filter?.page ?? 1}
            onChange={handleChangePage}
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                color: theme?.inputLabel,
                "&.Mui-selected": {
                  backgroundColor: hexToRgba(theme?.inputLabel, 0.08),
                },
              },
            }}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            spacing={2}
          >
            <CustomText variant="body1">Rows per page:</CustomText>
            <TextField
              select
              className="w-fit"
              size="small"
              id="demo-select-small"
              value={filter?.limit ?? 10}
              onChange={(e) => onChangeFilter("limit", e.target.value)}
              sx={{
                "& fieldset": { border: "none" },
                "& .MuiOutlinedInput-root": {
                  background: theme?.tableBg,
                },
                "& .MuiInputBase-root": {
                  color: theme?.inputText,
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.icon,
                },
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </TextField>
          </Stack>
        </Stack>
      )}
    </Box>
  );
}

export default CustomTable;
