import { Box, Stack } from "@mui/material";
import CustomTable from "src/components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import { FINANCE_INCENTIVE_DETAIL_PERIOD_COLUMNS } from "src/constants/finance";
import { DecimalFormatter } from "src/utils/formatter";

interface Props {
  data: any;
  totalAmount: number;
}
export default function incentiveFinishedTask({ data, totalAmount }: Props) {
  return (
    <Box>
      <CustomTable
        columns={FINANCE_INCENTIVE_DETAIL_PERIOD_COLUMNS}
        data={data}
        pagination={false}
      />
      <Stack
        mt={4}
        direction={"row"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Stack width={"30%"}>
          <Stack
            display={"flex"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <CustomText variant="h6" fontWeight={"bold"}>Total Amounts</CustomText>
            <CustomText textAlign={"right"} variant="h6" fontWeight={"bold"}>
              {`Rp ${DecimalFormatter(totalAmount.toString())}`}
            </CustomText>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
