import { Autocomplete, Box, createFilterOptions, Grid, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  deleteShipment,
  GetShipmentByCustomer,
  updateCustomerAddress,
} from "src/api/shipment";
import NotificationAlert from "src/components/main/Alert";
import CustomButton from "src/components/main/CustomButton";
import CustomCard from "src/components/main/CustomCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddressForm from "./AddressForm";
import ModalConfirm from "src/components/main/ModalConfirm";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import countries from "src/assets/countries.json";
import provinces from "src/assets/provinces.json";
import regencies from "src/assets/regencies.json";
import { capitalizeEachWord } from "src/utils/formatter";


export default function ShippingAddress() {

  const filter = createFilterOptions();
  const [editStates, setEditStates] = useState<{ [key: number]: boolean }>({});
  const [initialData, setInitialData] = useState<CustomerAddress[]>([]);
  const [shipmentData, setShipmentData] = useState<CustomerAddress[]>([]);
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const id = useLocation().pathname.split("/").pop();

  const fetchShippingAddress = async () => {
    try {
      const response = await GetShipmentByCustomer(id!);
      if (response?.data !== null) {
        setInitialData(response?.data);
        setShipmentData(response?.data);
      }
    } catch (error: any) {
      console.error("Error fetching customer shipment:", error);
      NotificationAlert({ message: error.message, status: "error" });
    }
  };

  useEffect(() => {
    fetchShippingAddress();
  }, []);

  const handleChange = (
    event: any,
    index: number
  ) => {
    const { name, value } = event.target;
    const newData = [...shipmentData];
    newData[index] = {
      ...newData[index],
      [name]: value,
    };

    setShipmentData(newData);
  };

  const handleDiscard = (index: number) => {
    setEditStates((prev) => ({ ...prev, [index]: false }));
    const newData = [...shipmentData];
    newData[index] = { ...newData[index], ...initialData[index] };
    setShipmentData(newData);
  };

  const handleEdit = (index: number) => {
    setEditStates((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleOpenModalDelete = (id: string) => {
    setOpenModalDelete(true);
    setSelectedData(id);
  };

  const handleDelete = async () => {
    await deleteShipment(selectedData);
    await fetchShippingAddress();
    setOpenModalDelete(false);
    setSelectedData("");
  };

  const handleOpenModalUpdate = (index: number) => {
    setOpenModalUpdate(true);
    setSelectedIndex(index);
  };

  const handleUpdate = async () => {
    await updateCustomerAddress(shipmentData[selectedIndex]);
    await fetchShippingAddress();
    setEditStates((prev) => ({ ...prev, [selectedIndex]: !prev[selectedIndex] }));
    setOpenModalUpdate(false);
    setSelectedIndex(0);
  };


  const buttonDelete = (id: string, index: number) => {
    return (
      index !== 0 && (
        <IconButton
          sx={{
            "&:hover": {
              backgroundColor: "rgba(255,255,255, 0.2)",
            },
          }}
          onClick={() => handleOpenModalDelete(id)}
        >
          <DeleteOutlineOutlinedIcon color="error" />
        </IconButton>
      )
    );
  };
  

  return (
    <Box>
      {shipmentData?.map?.((data, index) => (
        <CustomCard
          key={index}
          text={`Address ${index + 1}`}
          actionHeader={buttonDelete(data.id,index)}
        >
          <Box width={"100%"} display={"flex"} flexDirection={"column"}>
            <Stack mb={2}>
              <CustomText variant="body1">Recipient Name</CustomText>
              <CustomInput
                disabled={!editStates[index]}
                onChange={(e) => handleChange(e, index)}
                size="small"
                id="recipient_name"
                name="recipient_name"
                value={data?.recipient_name}
              />
            </Stack>
            <Stack mb={2}>
              <CustomText variant="body1">Phone / Whatsapp</CustomText>
              <CustomInput
                disabled={!editStates[index]}
                onChange={(e) => handleChange(e, index)}
                size="small"
                id="recipient_contact"
                name="recipient_contact"
                value={data?.recipient_contact}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
            </Stack>
            <Stack mb={2}>
              <CustomText variant="body1">Address Label</CustomText>
              <CustomInput
                disabled={!editStates[index]}
                onChange={(e) => handleChange(e, index)}
                size="small"
                id="type_shipment"
                name="type_shipment"
                value={data?.type_shipment}
              />
            </Stack>
            <Grid container columnSpacing={4}>
              <Grid item sm={6}>
                <Stack mb={2}>
                  <CustomText variant="body1">Country</CustomText>
              <Autocomplete
                  size="small"
                  id="country"
                  componentName="country"
                  value={data?.country ?? ""} // Handle the value prop
                  onChange={(event, newValue) =>
                    handleChange({
                      target: { name: "country", value: newValue ?? "" },
                    },index)
                  }
                  options={countries.map((option) => option.name)}
                  renderInput={(params) => <CustomInput {...params} required />}
                />
                </Stack>
                <Stack mb={2}>
                  <CustomText variant="body1">City</CustomText>
                  {data?.country?.toLowerCase() === "indonesia" ? (
                  <Autocomplete
                    disabled={!editStates[index]}
                    size="small"
                    id="city"
                    componentName="city"
                    clearOnBlur
                    selectOnFocus
                    value={data?.city ?? ""} // Handle the value prop
                    onChange={(event, newValue: any) => {
                      let valueToSend;

                      if (typeof newValue === "string") {
                        valueToSend = newValue;
                      } else if (newValue && newValue.inputValue) {
                        valueToSend = {
                          label: newValue?.inputValue,
                          value: newValue?.inputValue,
                        };
                      } else {
                        valueToSend = newValue ? newValue : "";
                      }

                      handleChange({
                        target: { name: "city", value: valueToSend.value ? valueToSend.value : valueToSend },
                      },index);
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          value: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    options={regencies
                      .filter(
                        (option) =>
                          option.province_id ===
                          (provinces.find(
                            (province) => province.name === data?.province
                          )?.id ?? 0)
                      )
                      .map((option) => capitalizeEachWord(option.name))}
                    getOptionLabel={(option) => {
                      // Add "Add" label to the new option
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.value;
                      }
                      return option.value;
                    }}
                    renderInput={(params) => (
                      <CustomInput {...params} name="city" id="city" required />
                    )}
                  />
                ) : (
                  <CustomInput
                    disabled={!editStates[index]}
                    required
                    size="small"
                    id="city"
                    name="city"
                    value={data?.city}
                    onChange={(e) => handleChange(e, index)}
                  />
                )}
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <Stack mb={2}>
                  <CustomText variant="body1">Province</CustomText>
                  {data?.country?.toLowerCase() === "indonesia" ? (
                  <Autocomplete
                    size="small"
                    id="province"
                    componentName="province"
                    value={data?.province ?? ""} // Handle the value prop
                    clearOnBlur
                    selectOnFocus
                    onChange={(event, newValue: any) => {
                      let valueToSend;

                      if (typeof newValue === "string") {
                        valueToSend = newValue;
                      } else if (newValue && newValue.inputValue) {
                        valueToSend = {
                          label: newValue?.inputValue,
                          value: newValue?.inputValue,
                        };
                      } else {
                        valueToSend = newValue ? newValue : "";
                      }

                      handleChange({
                        target: { name: "province", value: valueToSend.value ? valueToSend.value : valueToSend },
                      },index);
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          value: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    options={provinces.map((option) => option.name)}
                    getOptionLabel={(option) => {
                      // Add "Add" label to the new option
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.value;
                      }
                      return option.value;
                    }}
                    renderInput={(params) => (
                      <CustomInput
                        {...params}
                        name="province"
                        id="province"
                        required
                      />
                    )}
                  />
                ) : (
                  <CustomInput
                    required
                    size="small"
                    id="province"
                    name="province"
                    value={data?.province}
                    onChange={(e) => handleChange(e, index)}
                  />
                )}
                </Stack>
                <Stack mb={2}>
                  <CustomText variant="body1">Postal Code</CustomText>
                  <CustomInput
                    disabled={!editStates[index]}
                    onChange={(e) => handleChange(e, index)}
                    size="small"
                    id="postal_code"
                    name="postal_code"
                    value={data?.postal_code}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Stack mb={2}>
              <CustomText variant="body1">Full Address</CustomText>
              <CustomInput
                disabled={!editStates[index]}
                onChange={(e) => handleChange(e, index)}
                size="small"
                id="address"
                name="address"
                value={data?.address}
              />
            </Stack>
          </Box>
          <Box
            mt={4}
            display={"flex"}
            width={"100%"}
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "flex-end",
              alignItems: {
                xs: "flex-start",
                md: "flex-end",
              },
              gap: {
                xs: 1,
                md: 2,
                lg: 3,
              },
            }}
          >
            {editStates[index] ? (
              <>
                <CustomButton
                  variant="outlined"
                  customType="cancel"
                  onClick={() => handleDiscard(index)}
                >
                  Discard Changes
                </CustomButton>
                <CustomButton
                  onClick={() => handleOpenModalUpdate(index)}
                  variant="contained"
                >
                  Save Changes
                </CustomButton>
              </>
            ) : (
              <CustomButton
                variant="contained"
                onClick={() => handleEdit(index)}
              >
                Edit
              </CustomButton>
            )}
          </Box>
        </CustomCard>
      ))}
      <ModalConfirm
        text="Are you sure you want to update this address?"
        open={openModalUpdate}
        onClose={() => setOpenModalUpdate(false)}
        onSubmit={handleUpdate}
      />
      <ModalConfirm
        text="Are you sure you want to delete this address?"
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        onSubmit={handleDelete}
      />
    </Box>
  );
}
