import { useLoadingStore } from "src/store/loadingStore";
import { useUserStore } from "src/store/userStore";
import { FindMenuID } from "src/utils/utils";
import {
  sendRequestDELETE,
  sendRequestGET,
  sendRequestPOST,
} from "../sendRequest";

const { showLoading, hideLoading, showNotification } =
  useLoadingStore.getState();


export const inquiryPurchaseShipmentList = async (req: PurchaseFilter) => {
  const userStore = useUserStore.getState();
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_SHIPMENT_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION PURCHASE SHIPMENT LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE SHIPMENT LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getPurchaseShipmentDetail = async (id: string) => {
  const userStore = useUserStore.getState();
  const menu_id = FindMenuID(userStore.menu_mapping, "/user");
  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_SHIPMENT_DETAIL || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION PURCHASE SHIPMENT DETAIL", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE SHIPMENT DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const createPurchaseShipment = async (
  data: RequestAddPurchaseShipment
) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/user");  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_SHIPMENT_CREATE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION PURCHASE SHIPMENT CREATE", res);
    showNotification(res?.data?.message, "success");
    return res?.data;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE SHIPMENT CREATE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const deletePurchaseShipment = async (id: string) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/user");  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_SHIPMENT_DELETE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestDELETE(`${url}/${id}`, header);
    console.info("ACTION PURCHASE SHIPMENT DELETE", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE SHIPMENT DELETE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const inquiryPurchaseItemList = async (req: PurchaseFilter) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/user");  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_ITEM_LIST || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, req, header);
    console.info("ACTION PURCHASE ITEM LIST", res);
    return res.data;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE ITEM LIST", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const createPurchaseItem = async (data: RequestAddPurchaseItem) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/user");  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_ITEM_CREATE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestPOST(url, data, header);
    console.info("ACTION PURCHASE ITEM CREATE", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE ITEM CREATE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const getPurchaseItemDetail = async (id: string) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/user");  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_ITEM_DETAIL || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestGET(`${url}/${id}`, header);
    console.info("ACTION PURCHASE ITEM DETAIL", res);
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE ITEM DETAIL", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};

export const deletePurchaseItem = async (id: string) => {
  const userStore = useUserStore.getState();
const menu_id = FindMenuID(userStore.menu_mapping, "/user");  const url = process.env.REACT_APP_ENDPOINT_PURCHASE_ITEM_DELETE || "";
  const header = {
    ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
    ...(menu_id && { "app-menu-id": menu_id }),
  };

  try {
    showLoading();
    const res = await sendRequestDELETE(`${url}/${id}`, header);
    console.info("ACTION PURCHASE ITEM DELETE", res);
    showNotification(res?.data?.message, "success");
    return res;
  } catch (error: any) {
    console.error("ACTION ERROR PURCHASE ITEM DELETE", error);
    showNotification(error.message, "error");
  } finally {
    hideLoading();
  }
};
