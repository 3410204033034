import { Box, Snackbar, Alert, Slide, SlideProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useLoadingStore } from "src/store/loadingStore";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export default function AlertInfo() {
  const {
    isNotification,
    notificationMessage,
    notificationStatus,
    hideNotification,
  } = useLoadingStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      hideNotification();
    }, 3000);

    return () => clearTimeout(timer);
  }, [isNotification]);

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={SlideTransition}
        sx={{ position: "fixed", zIndex: 9000 }}
      >
        <Alert
          severity={notificationStatus === "success" ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
